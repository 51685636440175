import { Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LoadingButton } from "@mui/lab";

interface AddNewMaskFormatProps {
    maskFormatId?: string
    onSave?: (app: MaskFormat) => void
    onExit?: () => void
    onBackButtonClicked?: () => void
}

interface MaskFormat {
    id: string
    name: string
    maskCharacter: string
    stopCharacter: string
    charactersToIgnore: string
    numberOfCharacters?: Number
    keepCharacters: Number
    reverseOrder: Number
}

export function AddNewMaskFormat(props: AddNewMaskFormatProps) {
    const login = useLogin();
    const notification = useNotification();

    const [saving, setSaving] = useState(false);

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [maskCharacter, setMaskCharacter] = useState("");
    const [stopCharacter, setStopCharacter] = useState("");
    const [charactersToIgnore, setCharactersToIgnore] = useState("");
    const [numberOfCharacters, setNumberOfCharacters] = useState("");
    const [keepCharacters, setKeepCharacters] = useState(0);
    const [reverseOrder, setReverseOrder] = useState(false);

    const init = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (props.maskFormatId) {
            const maskFormatReq = await req.get(`/api/maskformats/${props.maskFormatId}`);

            const maskFormat = APIPayloadToMaskFormat(maskFormatReq.data);

            setId(maskFormat.id);
            setName(maskFormat.name);
            setMaskCharacter(maskFormat.maskCharacter);
            setStopCharacter(maskFormat.stopCharacter);
            setCharactersToIgnore(maskFormat.charactersToIgnore);
            setNumberOfCharacters(maskFormat.numberOfCharacters ? String(maskFormat.numberOfCharacters) : "");
            setKeepCharacters(Number(maskFormat.keepCharacters));
            setReverseOrder(Boolean(maskFormat.reverseOrder));
        }
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const validate = () => {
        if(name === "") {
            return "Name is required";
        }

        if(maskCharacter === "") {
            return "Mask Character is required";
        }

        return;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        const error = validate();
        if (error) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: error,
            });
            return;
        }

        setSaving(true);

        let payload = MaskFormatToAPIPayload({
            id,
            name,
            maskCharacter,
            charactersToIgnore,
            stopCharacter,
            keepCharacters,
            numberOfCharacters: numberOfCharacters ? Number(numberOfCharacters) : undefined,
            reverseOrder: Number(reverseOrder),
        });

        if (props.maskFormatId) {
            //update
            try {
                const resp = await req.put(`/api/maskformats/${props.maskFormatId}`, payload);

                const addedMaskFormat = APIPayloadToMaskFormat(resp.data);

                props.onSave && props.onSave(addedMaskFormat);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save mask format",
                });
                return;
            }
        } else {
            //create
            try {
                const resp = await req.post("/api/maskformats", payload);

                const addedMaskFormat = APIPayloadToMaskFormat(resp.data);

                props.onSave && props.onSave(addedMaskFormat);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save mask format",
                });
                return;
            }
        }

        notification.Display({
            type: "success",
            title: "Success",
            message: "Mask Format saved successfully",
        });
    };

    return (
        <>
            {
                <Stack id="add-mask-format-page" sx={{ flex: 1, height: "100%" }}>
                    <Box display="flex" sx={{ alignItems: "start" }}>
                        <Box>
                            {props.onBackButtonClicked ?
                                <Button
                                    id="back-button"
                                    startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                                    onClick={props.onBackButtonClicked}
                                >
                                    Back
                                </Button>
                                : undefined
                            }
                            <Typography sx={{ marginTop: "5px" }} variant="h2">{props.maskFormatId ? "Edit Mask Format" : "Add New Mask Format"}</Typography>
                        </Box>
                        <IconButton
                            id="exit-button"
                            size="small"
                            sx={{ marginLeft: "auto" }}
                            onClick={props.onExit}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField fullWidth sx={{ marginTop: "20px" }} disabled={Boolean(props.maskFormatId)} id="id-input" label="ID" variant="outlined"
                                value={id}
                                onChange={x => setId(x.target.value.toUpperCase())}
                            />
                        </Grid>
                        <Grid item sm={8}>
                            <TextField fullWidth sx={{ marginTop: "20px" }} id="name-input" label="Name" variant="outlined"
                                value={name}
                                onChange={x => setName(x.target.value)}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <TextField inputProps={{ maxLength: 1 }} fullWidth sx={{ marginTop: "20px" }} id="mask-character-input" name="maskCharacter" label="Mask Character" variant="outlined"
                                value={maskCharacter}
                                onChange={x => setMaskCharacter(x.target.value)}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <TextField inputProps={{ maxLength: 1 }} fullWidth sx={{ marginTop: "20px" }} id="stop-character-input" name="stopCharacter" label="Stop Character" variant="outlined"
                                value={stopCharacter}
                                onChange={x => setStopCharacter(x.target.value)}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <TextField fullWidth sx={{ marginTop: "20px" }} id="characters-to-ignore-input" name="charactersToIgnore" label="Characters to Ignore" variant="outlined"
                                value={charactersToIgnore}
                                onChange={x => setCharactersToIgnore(x.target.value)}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <TextField inputProps={{ pattern: "[0-9]+" }} fullWidth sx={{ marginTop: "20px" }} id="number-of-characters-input" name="numberOfCharacters" label="Number of Characters" variant="outlined"
                                value={numberOfCharacters}
                                onChange={x => {
                                    if (x.target.value === "" || Number(x.target.value)) {
                                        setNumberOfCharacters(x.target.value);
                                    } else {
                                        setNumberOfCharacters(numberOfCharacters);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <FormControl fullWidth sx={{ marginTop: "20px" }} variant="outlined">
                                <InputLabel id="keep-characters-label">Behaviour</InputLabel>
                                <Select
                                    labelId="keep-characters-label"
                                    id="keep-characters-select"
                                    label="Behaviour"
                                    value={keepCharacters}
                                    onChange={x => setKeepCharacters(Number(x.target.value))}
                                >
                                    <MenuItem key="1" value="1">To Keep</MenuItem>
                                    <MenuItem key="0" value="0">To Mask</MenuItem>
                                </Select>
                            </FormControl>



                        </Grid>
                    </Grid>
                    <FormControlLabel sx={{ marginTop: "20px" }}
                        control={
                            <Checkbox checked={reverseOrder} onChange={(e, checked) => setReverseOrder(checked)} id="reverse-order-checkbox" name="reverseOrder" />
                        }
                        label="Reverse masking order (in case you choose a # of characters or a stop character)"
                    />

                    <Divider sx={{ marginTop: "10px" }} />
                    <Box display="flex" sx={{ marginTop: "10px" }}>
                        <Button
                            id="cancel-button"
                            variant="outlined"
                            size="medium"
                            onClick={props.onExit}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            id="save-button"
                            loading={saving}
                            sx={{ marginLeft: "auto" }}
                            variant="contained"
                            size="medium"
                            onClick={save}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Stack>
            }
        </>);
}

function APIPayloadToMaskFormat(body: any) {
    const msk: MaskFormat = {
        id: body.id,
        name: body.name,
        maskCharacter: body.maskCharacter,
        stopCharacter: body.stopCharacter,
        charactersToIgnore: body.charactersToIgnore,
        numberOfCharacters: body.numberOfCharacters,
        keepCharacters: body.keepCharacters,
        reverseOrder: body.reverseOrder
    };
    return msk;
}

function MaskFormatToAPIPayload(msk: MaskFormat): any {
    return {
        id: msk.id,
        name: msk.name,
        maskCharacter: msk.maskCharacter,
        stopCharacter: msk.stopCharacter,
        charactersToIgnore: msk.charactersToIgnore,
        numberOfCharacters: msk.numberOfCharacters,
        keepCharacters: msk.keepCharacters,
        reverseOrder: msk.reverseOrder
    };
}