import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNotification } from "providers/Notification";
import { ListInput } from "components/ListInput";


interface AddNotificationProps {
    initialValue?: number
    notificationTypes: { [key: string]: string }
    defaultEmailRecipient: string
    defaultSlackChannel: string
    emailRecipients: string[]
    slackChannels: string[]
    onBackButtonClicked?: () => void
    onCancel?: () => void
    onAdded: (notificationType: number, values: string[], reset: () => void) => void
}

export default function AddNotification(props: AddNotificationProps) {
    const notification = useNotification();
    const [type, setType] = useState(0);
    const [values, setValues] = useState([] as string[]);

    const [validationError, setValidationError] = useState(false);
    const [editing, setEditing] = useState(false);


    const reset = () => {
        setType(0);
        setValues([]);

        setEditing(false);
    };


    useEffect(() => {
        if (!props.initialValue) {
            reset();
            return;
        }

        setType(props.initialValue);

        if (props.notificationTypes[props.initialValue] === "Slack") {
            setValues(props.slackChannels);
        }

        if (props.notificationTypes[props.initialValue] === "Email") {
            setValues(props.emailRecipients);
        }

        setEditing(true);
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = () => {
        if (type === 0) {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "Filter type cannot be empty"
            });
            setValidationError(true);
            return;
        }

        props.onAdded(type, values, reset);
    };


    return <Stack sx={{ paddingTop: "10px" }}>
        {!editing ? <>
            <Button
                id="add-button"
                variant="outlined"
                size="medium"
                fullWidth
                onClick={() => {
                    reset();
                    setEditing(true);
                }}
            >
                Add
            </Button>
        </> : <>
            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <InputLabel id="filter-type-label">Type</InputLabel>
                <Select
                    labelId="filter-type-label"
                    id="filter-type-select"
                    name="type"
                    label="Type"
                    value={type}
                    onChange={x => {
                        setType(Number(x.target.value));

                        if (x.target.value === "1" && props.defaultEmailRecipient) {
                            setValues([props.defaultEmailRecipient]);
                            return;
                        }

                        if (x.target.value === "2" && props.defaultSlackChannel) {
                            setValues([props.defaultSlackChannel]);
                            return;
                        }

                        setValues([]);
                    }}
                    error={validationError}
                >
                    {Object.entries(props.notificationTypes).map(([key, value]) => <MenuItem value={key}>{value}</MenuItem>)}
                </Select>
            </FormControl>

            {["Slack", "Email"].includes(props.notificationTypes[type]) && <ListInput
                id="values-select"
                label={props.notificationTypes[type] === "Slack" ? "Slack Channels" : "Email Recipients"}
                placeholder="Press ENTER to confirm"
                value={values}
                delimiters={[]}
                onChange={x => setValues(x)}
                onDelete={x => setValues(values.filter(y => y !== x))}
            />
            }

            <Box display="flex" flexGrow={1} alignItems={"end"} marginBottom="15px" marginTop="20px">
                <Button
                    id="cancel-button"
                    variant="text"
                    size="medium"
                    fullWidth
                    onClick={() => {
                        reset();
                        props.onCancel && props.onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    id="save-button"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onClick={save}
                >
                    Save
                </Button>
            </Box>
        </>}

    </Stack>;
}