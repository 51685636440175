import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddNewApplication } from "./AddApplication";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";

interface AppsListState {
    itens: AppsListStateItem[]
}

interface RuleState {
    id: string
    name: string
}

interface AppsListStateItem {
    id: string
    name: string
    createdAt: Date
    updatedAt: Date
    rules?: RuleState[]
}

type columnsType = "name" | "usedBy" | "createdat" | "updatedat";

export default function Apps() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [addApplicationDrawer, setAddAapplicationDrawer] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogAppId, setDeleteDialogAppId] = useState("");
    const [editingAppId, setEditingAppId] = useState("");

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "40%", direction: "desc", sortOrder: 1 },
        { columnId: "usedBy", width: "20%" },
        { columnId: "createdat", width: "20%", direction: "desc", sortOrder: 2 },
        { columnId: "updatedat", width: "20%" },
    ]);

    const columnsDataId = "dataGrid_ApplicationTable_columns";

    const [listState, setListState] = useState<AppsListState>({ itens: [] });

    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);
    const [dataCount, setDataCount] = useState(0);

    const req = login.GetAxios();

    const loadData = async (cState: typeof columnsState, skip: number, take: number) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            const sortBy = GetSortString(cState);

            const resp = await req.get("/api/apps", {
                params: {
                    sortBy,
                    skip,
                    take,
                }
            });

            setListState({
                itens: (resp.data.items || []).map((x: any) => {
                    const item: AppsListStateItem = {
                        id: x.id,
                        name: x.name,
                        createdAt: new Date(x.createdAt * 1000),
                        updatedAt: new Date(x.updatedAt * 1000),
                        rules: x.rules && x.rules.map((item: any) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        })
                    };
                    return item;
                })
            });

            setSkipItems(skip);
            setTakeCount(take);
            setDataCount(resp.data.count || 0);
            setColumnsState(cState);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };


    useEffect(() => {
        const cstate = RestoreColumnsState(columnsDataId, columnsState);
        loadData(cstate, skipItems, takeCount);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState(columnsDataId, columnsState);
    }, [columnsState]);

    const deleteRowDialog = (appName: string, appId: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the application "${appName}"?`);
        setDeleteDialogAppId(appId);
    };

    const deleteApp = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/apps/${deleteDialogAppId}`);
            loadData(columnsState, skipItems, takeCount);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Application deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete application",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };

    const addApp = () => {
        setEditingAppId("");
        setAddAapplicationDrawer(true);
    };

    const editApp = (appId: string) => {
        setEditingAppId(appId);
        setAddAapplicationDrawer(true);
    };

    return <>
        <Box display="flex" flexDirection="column">
            <Box display="flex">
                <Typography variant="h1">Applications</Typography>
                <Button
                    id="new-application-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={addApp}
                >
                    Add New Application
                </Button>
            </Box>

            <DataTable
                data={listState.itens}
                finishedLoading={finishedLoading}
                onSortChanged={(updatedSort) => {
                    loadData(updatedSort, skipItems, takeCount);
                }}
                onColumnOrderChanged={(x) => {
                    setColumnsState(x);
                }}
                onPaginationStartItemChanged={(x) => {
                    loadData(columnsState, x, takeCount);
                }}
                onPaginationItemCountChanged={(x) => {
                    loadData(columnsState, skipItems, x);
                }}
                paginationStartItem={skipItems}
                paginationItemCount={takeCount}
                dataCount={dataCount}
                columnsDataId={columnsDataId}
                columnsState={columnsState}
                columns={{
                    name: {
                        displayName: "Name",
                        sortable: true,
                        render: (x) => x.name,
                    },
                    usedBy: {
                        displayName: "Used By Rule(s)",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "row",
                                gap: "2px",
                            }}>
                                {(x.rules && x.rules.map(y => <Chip label={y.name.trim()} variant="outlined" size="small" color="primary" />))}
                            </Box>;
                        }
                    },
                    createdat: {
                        displayName: "Created At",
                        sortable: true,
                        render: (x) => {
                            return `${x.createdAt.toLocaleDateString()} ${x.createdAt.toLocaleTimeString()}`;
                        }
                    },
                    updatedat: {
                        displayName: "Updated At",
                        sortable: true,
                        render: (x) => {
                            return `${x.createdAt.toLocaleDateString()} ${x.createdAt.toLocaleTimeString()}`;
                        }
                    },
                }}
                rowOptionsMenu={[
                    {
                        icon: <EditIcon className="edit-button" fontSize="small" />,
                        text: "Edit",
                        onClick: (x) => {
                            editApp(x.id);
                        }
                    },
                    {
                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                        text: "Delete",
                        onClick: (x) => {
                            if (x.rules && x.rules.length > 0) {
                                return notification.Display({
                                    type: "error",
                                    title: "Validation Error",
                                    message: `Cannot delete ${x.name} because it is in use by ${x.rules.length === 1 ? "a Rule" : "some Rules"} (${x.rules.map(i => i.name).join(", ")})`,
                                });
                            }

                            deleteRowDialog(x.name, x.id);
                        }
                    },
                ]}
            />
        </Box>

        <Dialog open={deleteDialog}>
            <DialogTitle>Delete Application</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteApp}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addApplicationDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <AddNewApplication appId={editingAppId} onExit={() => setAddAapplicationDrawer(false)} onSave={() => { loadData(columnsState, skipItems, takeCount); setAddAapplicationDrawer(false); }} />
            </Box>
        </Drawer>
    </>;
}


