import { Card, Stack, Typography, LinearProgress, LinearProgressProps } from "@mui/material";
import { Box } from "@mui/system";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import CircleIcon from "@mui/icons-material/Circle";
import DataTable from "components/Table";

interface DashboardState {
    totalScanned: number
    percentScanned: number
    totalDetected: number
    percentDetected: number
    totalObfuscated: number
    percentObfuscated: number
    scannedApplications: number
    percentScannedApplications: number
    detectedApplications: number
    percentDetectedApplications: number
    obfuscatedApplications: number
    percentObfuscatedApplications: number
}

function Dashboard() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [dashboardInfo, setDashboardInfo] = useState({
        totalScanned: 0,
        percentScanned: 0,
        totalDetected: 0,
        percentDetected: 0,
        totalObfuscated: 0,
        percentObfuscated: 0,
        scannedApplications: 0,
        percentScannedApplications: 0,
        detectedApplications: 0,
        percentDetectedApplications: 0,
        obfuscatedApplications: 0,
        percentObfuscatedApplications: 0,
    } as DashboardState);

    const req = login.GetAxios();

    const loadData = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const resp = await req.get("/api/dashboard");

            setDashboardInfo({
                totalScanned: resp.data.totalScannedRequest,
                percentScanned: Number(100),
                totalDetected: resp.data.totalDetectedRequest,
                percentDetected: Math.round((resp.data.totalDetectedRequest / (resp.data.totalScannedRequest || 1)) * 100),
                totalObfuscated: resp.data.totalObfuscatedRequest,
                percentObfuscated: Math.round((resp.data.totalObfuscatedRequest / (resp.data.totalScannedRequest || 1)) * 100),
                scannedApplications: resp.data.scannedApplications,
                percentScannedApplications: Math.round((resp.data.scannedApplications / (resp.data.totalScannedRequest || 1)) * 100),
                detectedApplications: resp.data.detectedApplications,
                percentDetectedApplications: Math.round((resp.data.detectedApplications / (resp.data.scannedApplications || 1)) * 100),
                obfuscatedApplications: resp.data.obfuscatedApplications,
                percentObfuscatedApplications: Math.round((resp.data.obfuscatedApplications / (resp.data.scannedApplications || 1)) * 100),
            });

            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <Loading finished={finishedLoading}>
            <Box>
                <Typography variant="h1">Summary Statistics</Typography>

                <Box sx={{ paddingLeft: "30px" }}>
                    <Typography variant="h2" sx={{ marginTop: "30px", fontWeight: 600 }}>All Traffic</Typography>
                    <DashboardCard icon={<VisibilityOutlinedIcon />} count={dashboardInfo.totalScanned} percentage={dashboardInfo.percentScanned} text="Scanned Requests" />
                    <DashboardCard icon={<TaskOutlinedIcon />} count={dashboardInfo.totalDetected} percentage={dashboardInfo.percentDetected} text="Detected Requests" />
                    <DashboardCard icon={<LockOutlinedIcon />} count={dashboardInfo.totalObfuscated} percentage={dashboardInfo.percentObfuscated} text="Detected and Obfuscated Requests" />
                    <Typography variant="h2" sx={{ marginTop: "30px", fontWeight: 600 }}>Defined Applications</Typography>
                    <DashboardCard icon={<VisibilityOutlinedIcon />} count={dashboardInfo.scannedApplications} percentage={dashboardInfo.percentScannedApplications} text="Scanned Requests" />
                    <DashboardCard icon={<TaskOutlinedIcon />} count={dashboardInfo.detectedApplications} percentage={dashboardInfo.percentDetectedApplications} text="Detected Requests" />
                    <DashboardCard icon={<LockOutlinedIcon />} count={dashboardInfo.obfuscatedApplications} percentage={dashboardInfo.percentObfuscatedApplications} text="Detected and Obfuscated Requests" />
                    <IcapInstancesTable />
                </Box>
            </Box>
        </Loading>
    </>;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface DashboardCardProps {
    icon: React.ReactChild
    text: string
    count: number
    percentage: number
}

function DashboardCard(props: DashboardCardProps) {
    return <Card sx={{
        maxWidth: "800px",
        marginTop: "15px",
        padding: "15px",
    }} >
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ width: "40px", height: "40px", background: "#E2F2FF", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {props.icon}
            </Box>
            <Box sx={{ width: "100%", marginLeft: "20px" }}>
                <Typography variant="h3" sx={{ fontWeight: 500 }}>{props.text}</Typography>
                <Stack direction="row">
                    <Typography variant="h4" color="text.secondary" sx={{ marginRight: "10px" }}>{props.count}</Typography>
                    <LinearProgressWithLabel value={props.percentage} />
                </Stack>
            </Box>
        </Stack>
    </Card >;
}

interface InstanceInfo {
    id: string
    name: string
    version: string
    status: string
}

function IcapInstancesTable() {
    const [loading, setLoading] = useState(true);
    const [instances, setInstances] = useState<InstanceInfo[]>([]);

    const login = useLogin();

    const fetchData = async () => {
        const req = login.GetAxios();
        if (!req) {
            return;
        }

        try {
            const resp = await req.get("/api/instances");

            const rawData = resp.data.instances;

            setInstances(rawData.map((x: any): InstanceInfo => {
                return {
                    id: x.id,
                    name: x.instanceName,
                    version: x.version,
                    status: x.status,
                };
            }));
            setLoading(false);
        } catch (err) {
            console.error("could not fetch shield instances info", err);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <Typography variant="h2" sx={{ marginTop: "30px", fontWeight: 600 }}>Shield Instances</Typography>
        <DataTable
            data={instances}
            finishedLoading={!loading}
            columnsDataId="icap_instances_table"
            sx={{maxWidth: "800px"}}
            columnsState={[
                {columnId: "name", width: "25%"},
                {columnId: "id", width: "25%"},
                {columnId: "version", width: "25%"},
                {columnId: "status", width: "25%"},
            ]}
            columns={{
                name: {
                    displayName: "Name",
                    render: (x) => {
                        return x.name;
                    },
                },
                id: {
                    displayName: "Id",
                    render: (x) => {
                        return x.id;
                    },
                },
                version: {
                    displayName: "Version",
                    render: (x) => {
                        return x.version;
                    },
                },
                status: {
                    displayName: "Status",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}>
                            {
                                (x.status === "running" && <><CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Running</span></>) ||
                                (x.status === "failed" && <><CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="warning" /><span>Pending</span></>) ||
                                (x.status === "pending" && <><CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>Failed</span></>) ||
                                <><CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="warning" /><span>{x.status}</span></>
                            }
                        </Box>;
                    },
                },
            }}
        />
    </>;
}

export default Dashboard;