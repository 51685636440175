import { Alert, Button, Checkbox, Chip, Drawer, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Slide, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import { AddGroup } from "./AddGroup";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";

interface GroupsList {
    currentProvider?: string
    groups: Group[]
}

interface Group {
    id: string
    identifier: string
    provider: string
    createdAt: Date
    hidden: boolean
    usedIn?: string[]
}

type columnsType = "checkbox" | "identifier" | "usedby" | "createdat" | "hidden";

export default function Groups() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [addGroupDrawer, setAddGroupDrawer] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [selectedItens, setSelectedItens] = useState<string[]>([]);
    const [syncing, setSyncing] = useState(false);
    const [noAuthenticationSetup, setNoAuthenticationSetup] = useState(false);

    const [currentGroupList, setCurrentGroupListState] = useState<GroupsList | undefined>();

    const [tableData, setTableData] = useState<Group[]>();

    const req = login.GetAxios();

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "checkbox", width: "7%"},
        { columnId: "identifier", width: "23%"},
        { columnId: "usedby", width: "30%" },
        { columnId: "createdat", width: "23%", sortOrder: 1, direction: "desc" },
        { columnId: "hidden", width: "17%" },
    ]);

    useEffect(() => {
        const result: Group[] = [];

        for (const i of (currentGroupList?.groups || [])) {
            if (i.hidden && !showHidden) {
                continue;
            }
            if (i.identifier.toLowerCase().includes(search.toLowerCase())) {
                result.push(i);
            }
        }
        setTableData(result);
    }, [search, showHidden]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTableData(currentGroupList?.groups.filter(x => (!x.hidden) || showHidden) || []);
        setSearch("");
    }, [currentGroupList]);// eslint-disable-line react-hooks/exhaustive-deps

    const loadData = async (c: typeof columnsState) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const rules = await req.get("/api/rules");

            const rules_name_dict: { [k: string]: string } = {};

            for (let i of rules.data.items) {
                rules_name_dict[i.id] = i.name;
            }

            let sortBy = GetSortString(c);

            const resp = await req.get("/api/user/groups", {
                params: {
                    sortBy,
                }
            });

            setCurrentGroupListState({
                currentProvider: resp.data.currentProvider || undefined,
                groups: resp.data.groups.map((x: any) => {
                    return {
                        id: `${x.provider}_${x.identifier}`,
                        identifier: x.identifier,
                        provider: x.provider,
                        createdAt: new Date(x.createdAt),
                        hidden: x.hidden,
                        usedIn: x.usedIn?.map((x: string) => rules_name_dict[x] || "Unknown") || [],
                    };
                }),
            });

            const cfg = await req.get("/api/config");
            setNoAuthenticationSetup(cfg.data?.authenticationMode === "none");

            setColumnsState(c);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const setHidden = async (hide: boolean) => {
        if (!req) {
            return;
        }

        try {
            await req.put("/api/user/groups/enabled", {
                hidden: hide,
                itens: selectedItens.map(x => {
                    const item = currentGroupList?.groups.find(y => y.id === x);
                    return {
                        identifier: item?.identifier,
                        provider: item?.provider,
                    };
                })
            });

            notification.Display({
                title: "Success",
                message: `${selectedItens.length} Groups updated`,
                type: "success",
            });

            setSelectedItens([]);
            await loadData(columnsState);
        } catch (err) {
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not delete items",
                type: "error",
            });
        }
    };

    const deleteGroups = async () => {
        if (!req) {
            return;
        }

        try {
            for (const x of selectedItens) {
                const current = currentGroupList!.groups.find(y => y.id === x)!;

                if ((current.usedIn || []).length > 0) {
                    notification.Display({
                        title: "Error",
                        message: `Could not delete group "${current.identifier}" because it's in use by one or more rules.`,
                        type: "error",
                    });
                    return;
                }
            }

            await req.post("/api/user/groups/delete", {
                items: selectedItens.map(x => {
                    const item = currentGroupList?.groups.find(y => y.id === x);
                    return {
                        identifier: item?.identifier,
                        provider: item?.provider,
                    };
                })
            });

            await loadData(columnsState);
            setSelectedItens([]);

            notification.Display({
                title: "Success",
                message: `${selectedItens.length} Groups deleted`,
                type: "success",
            });
        } catch (err) {
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not delete items",
                type: "error",
            });
        }
    };


    const forceSync = async () => {
        if (!req) {
            return;
        }

        setSyncing(true);

        try {
            await req.post("/api/user/groups/sync");

            await loadData(columnsState);
            setSelectedItens([]);

            notification.Display({
                title: "Success",
                message: "Sync successful",
                type: "success",
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                console.error(body.message);
                notification.Display({
                    title: "Error",
                    message: "Sync failed: " + body.message,
                    type: "error",
                });
            } else {
                notification.Display({
                    title: "Error",
                    message: "Sync failed",
                    type: "error",
                });
            }
           

            
        } finally {
            setSyncing(false);
        }
    };

    useEffect(() => {
        loadData(RestoreColumnsState("groups",columnsState));
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState("groups", columnsState);
    }, [columnsState]);
    return <>
        <Box display="flex">
            <Box>
                <Typography variant="h1">Groups</Typography>
            </Box>


            <FormControl variant="outlined" sx={{ marginLeft: "auto" }}>
                <InputLabel htmlFor="search-input">Search</InputLabel>
                <OutlinedInput id="search-input" label="Search"
                    value={search}
                    onChange={x => setSearch(x.target.value)}
                    disabled={currentGroupList?.currentProvider === undefined}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton size="small" sx={{ marginLeft: "auto" }} onClick={() => {
                                setSearch("");
                            }}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    }

                />
            </FormControl>

            <LoadingButton
                id="new-fetch-from-provider"
                sx={{ marginLeft: "5px" }}
                variant="outlined"
                size="small"
                onClick={() => {
                    forceSync();
                }}
                loading={syncing}
                disabled={currentGroupList?.currentProvider === undefined}
            >
                Sync Groups
            </LoadingButton>
            <Button
                id="new-group-format-button"
                sx={{ marginLeft: "5px" }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => {
                    setAddGroupDrawer(true);
                }}
                disabled={currentGroupList?.currentProvider === undefined}
            >
                Add New Group
            </Button>
        </Box>
        <Box>
            <FormGroup>
                <FormControlLabel control={<Switch disabled={currentGroupList?.currentProvider === undefined} checked={showHidden} onChange={() => { setShowHidden(!showHidden); }} />} label="Show hidden groups" />
            </FormGroup>
            {noAuthenticationSetup && <Alert severity="warning" sx={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}><Typography>Group settings will have no effect without an authentication method enabled.</Typography></Alert>}
            {currentGroupList?.currentProvider === undefined && <Alert severity="error" sx={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}><Typography>A directory integration must be configured before setting up groups.</Typography></Alert>}
        </Box>

        <DataTable
            data={tableData || []}
            finishedLoading={finishedLoading}
            columnsState={columnsState}
            onSortChanged={(x) => loadData(x)}
            columnsDataId="datagrid_groupstable_columns"
            columns={{
                checkbox: {
                    displayName: "",
                    render: (x) => {
                        return <Checkbox
                            checked={selectedItens.includes(x.id)}
                            onChange={() => {
                                if (selectedItens.includes(x.id)) {
                                    setSelectedItens(selectedItens.filter(y => y !== x.id));
                                } else {
                                    setSelectedItens(selectedItens.concat(x.id));
                                }
                            }}
                        />;
                    }
                },
                identifier: {
                    displayName: "Name",
                    sortable: true,
                    render: (x) => {
                        return x.identifier;
                    },
                },
                usedby: {
                    displayName: "Used By Rule(s)",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            {(x.usedIn && x.usedIn.map(y => <Chip label={y.trim()} variant="outlined" size="small" color="primary" />))}
                        </Box>;
                    },
                },
                createdat: {
                    displayName: "Created At",
                    sortable: true,
                    render: (x) => {
                        return x.createdAt.toLocaleString();
                    },
                },
                hidden: {
                    displayName: "Show Group in Policy",
                    render: (x) => {
                        return (!x.hidden) ? <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}>
                            <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Yes</span>
                        </Box>
                            : <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>No</span>
                            </Box>;
                    },
                },
            }}
        />


        <Drawer
            anchor="right"
            open={addGroupDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <AddGroup
                    provider={currentGroupList?.currentProvider || ""}
                    onExit={() => setAddGroupDrawer(false)}
                    onSave={() => { loadData(columnsState); setAddGroupDrawer(false); }}
                />
            </Box>
        </Drawer>

        <Slide direction="up" in={selectedItens.length > 0} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    position: "fixed",
                    height: "60px",
                    width: "60vw",
                    left: "20vw",
                    bottom: "50px",
                }}

            >
                <Paper
                    elevation={3}
                    sx={{
                        display: "flex",
                        height: "100%",
                        padding: "10px",
                        alignItems: "center",
                    }}
                >
                    <Typography>{selectedItens.length} {selectedItens.length === 1 ? "Group" : "Groups"} selected</Typography>
                    <Button
                        variant="outlined"
                        sx={{ marginLeft: "auto" }}
                        onClick={deleteGroups}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginLeft: "5px" }}
                        onClick={async () => {
                            await setHidden(true);
                        }}
                    >
                        Hide Group in Policy
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginLeft: "5px" }}
                        onClick={async () => {
                            await setHidden(false);
                        }}
                    >
                        Show Group in Policy
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginLeft: "5px" }}
                        onClick={() => {
                            setSelectedItens([]);
                        }}
                    >
                        Clear selection
                    </Button>
                </Paper>
            </Box>
        </Slide>


    </>;

}
