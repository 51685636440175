
import { Box } from "@mui/material";
import React, { createRef } from "react";

interface ColumnResizerProps {
    id: string;
    disabled: boolean;
    minWidth: number;
    className?: string;
    onResize: (columnWidth: string, lastColumnWidth: string) => void
}

export default class ColumnResizer extends React.Component<ColumnResizerProps> {

    ref: React.MutableRefObject<any> | undefined;
    dragging: boolean;
    mouseX: number;
    startPos: number;
    startWidthPrev: number;
    //startWidthNext: number;

    constructor(props: ColumnResizerProps) {
        super(props);

        this.startDrag = this.startDrag.bind(this);
        this.endDrag = this.endDrag.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        this.ref = createRef();
        this.dragging = false;
        this.mouseX = 0;
        this.startPos = 0;
        this.startWidthPrev = 0;
        //this.startWidthNext = 0;
    }

    startDrag() {
        if (this.props.disabled) {
            return;
        }

        this.dragging = true;
        this.startPos = this.mouseX;

        this.startWidthPrev = 0;
        //this.startWidthNext = 0;        

        if (this.ref) {
            let ele = this.ref.current.parentNode;
            //let lastChild = this.ref.current.parentNode.parentNode.lastChild;

            if (ele) {
                this.startWidthPrev = ele.clientWidth;
            }

            let childs = this.ref.current.parentNode.parentNode.childNodes;

            for (let node of childs) {
                if (node !== ele && (!node.style.width || !node.style.width.includes("px"))) {
                    node.style.width = node.clientWidth + "px";
                }
            }

            /*if (lastChild) {
                this.startWidthNext = lastChild.clientWidth;            
            }*/
        }
    }

    endDrag() {
        if (this.props.disabled) {
            return;
        }

        this.dragging = false;

        if (this.ref) {
            const ele = this.ref.current.parentNode;
            const lastChild = ele.parentNode.lastChild;

            if (ele.style && ele.style.width && lastChild.style && lastChild.style.width) {
                const columnWidth = ele.style.width;
                const lastColumnWidth = lastChild.style.width;
                this.props.onResize(columnWidth, lastColumnWidth);
            }
        }
    }

    onMouseMove(e: any) {
        if (this.props.disabled) {
            return;
        }

        this.mouseX = e.touches ? e.touches[0].screenX : e.screenX;
        if (!this.dragging) {
            return;
        }

        const moveDiff = this.startPos - this.mouseX;
        let newPrev = this.startWidthPrev - moveDiff;
        //let newNext = this.startWidthNext + moveDiff;

        if (newPrev < this.props.minWidth) {
            //const offset = newPrev - this.props.minWidth;
            newPrev = this.props.minWidth;
            //newNext += offset;
        } /*else if (newNext < this.props.minWidth) {
            const offset = newNext - this.props.minWidth;
            newNext = this.props.minWidth;
            newPrev += offset;
        }*/

        if (this.ref) {
            const ele = this.ref.current.parentNode;
            if (ele) {
                ele.style.width = newPrev + "px";
            }


            /*if (ele.parentNode.lastChild) {
                ele.parentNode.lastChild.style.width = newNext + "px";
            }*/
        }

    }

    componentDidMount() {
        if (this.props.disabled) {
            return;
        }

        this.addEventListenersToDocument();
    }

    componentWillUnmount() {
        if (this.props.disabled) {
            return;
        }

        this.removeEventListenersFromDocument();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.disabled && !this.props.disabled) {
            this.addEventListenersToDocument();
        }

        if (!prevProps.disabled && this.props.disabled) {
            this.removeEventListenersFromDocument();
        }
    }

    addEventListenersToDocument() {
        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.endDrag);

        document.addEventListener("touchmove", this.onMouseMove);
        document.addEventListener("touchend", this.endDrag);
    }

    removeEventListenersFromDocument() {
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.endDrag);

        document.removeEventListener("touchmove", this.onMouseMove);
        document.removeEventListener("touchend", this.endDrag);
    }

    render() {
        return (
            <Box ref={this.ref}
                id={this.props.id}
                className={`column-resizer ${this.props.className}`}
                onMouseDown={this.startDrag}
                onTouchStart={this.startDrag} />
        );
    }

}

