import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { Stack } from "@mui/system";
import { useState, useEffect } from "react";
import MenuButton from "./MenuButton";

export interface CollapsableButtonProps {
    text: string
    minimal?: boolean
    icon?: React.ReactChild
    onOpen?: () => void
    open?: boolean
    children: React.ReactChild[] | React.ReactChild
}

export function CollapsableButton(props: CollapsableButtonProps) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.open) {
            setOpen(true);
        }
    }, [props.open]);

    const menuButtonClick = () => {
        if (!open) {
            props.onOpen && props.onOpen();
        }
        setOpen(!open);
    };

    return <>
        <Stack sx={{
            backgroundColor: (props.minimal && open) ? "#f6f6f6" : undefined,
            transition: "background-color 0.05s linear",
            borderRadius: "6px",
        }}>
            <MenuButton minimal={props.minimal} text={props.text} icon={props.icon} onClick={menuButtonClick}>
                {open ? <ExpandLess /> : <ExpandMore />}
            </MenuButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Stack>
                    {props.children}
                </Stack>
            </Collapse>
        </Stack>
    </>;
}

export default CollapsableButton;