import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    Link,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "providers/Notification";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

interface LicenseInfo {
    name: string
    creationDate: Date
    expirationDate: Date
}

function Configuration() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [reqModLogEnabled, setReqModLogEnabled] = useState(false);
    const [enableApi, setEnableApi] = useState(false);
    const [enableSAML, setEnableSAML] = useState(false);

    const [SAMLMetadataUrl, setSAMLMetadataUrl] = useState("");
    const [SAMLMetadata, setSAMLMetadata] = useState("");

    const [requireIcapAuth, setRequireIcapAuth] = useState(false);
    const [UserSAMLMetadataUrl, setUserSAMLMetadataUrl] = useState("");
    const [UserSAMLMetadata, setUserSAMLMetadata] = useState("");

    const [UsernameHeader, setUsernameHeader] = useState("");
    const [UsernameGroupHeader, setUsernameGroupHeader] = useState("");
    const [RuleIdHeader, setRuleIdHeader] = useState("");
    const [LogHeader, setLogHeader] = useState("");

    const [licenseInfo, setLicenseInfo] = useState<LicenseInfo | undefined>(undefined);

    const [samlDialogOpen, setSamlDialogOpen] = useState(false);
    const [userSamlDialogOpen, setUserSamlDialogOpen] = useState(false);

    const [enableActivitylogClear, setEnableActivitylogClear] = useState(false);
    const [activityMaxAge, setActivityMaxAge] = useState<number>();
    const [schedule, setActivitySchedule] = useState("");

    const [enableActivity, setEnableActivity] = useState(false);

    const [samlAvailable, setSamlAvailable] = useState(false);
    const [samlNotAvailableReason, setSamlNotAvailableReason] = useState("");

    const [originalSettings, setOriginalSettings] = useState<any>();

    const req = login.GetAxios();

    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const respConfig = await req.get("/api/config");
            const respLicense = await req.get("/api/config/license");
            const respSamlAvailability = await req.get("/api/config/samlavailability");
            
            const settings = respConfig.data;
            const license = respLicense.data;
            const samlAvailability = respSamlAvailability.data;


            setSamlAvailable(samlAvailability?.dashboard?.available || false);
            setSamlNotAvailableReason(samlAvailability?.dashboard?.reason || "");


            setLicenseInfo({
                name: license.username,
                creationDate: new Date(license.createdDate),
                expirationDate: new Date(license.expireDate)
            });

            setOriginalSettings(settings);

            setEnabled(settings.enabled);
            setEnableApi(settings.enableAPI);
            setReqModLogEnabled(settings.reqModLogEnable);

            setEnableSAML(settings.saml.enable);
            setSAMLMetadata(settings.saml.metadata);

            setUsernameHeader(settings.headers.usernameHeader);
            setUsernameGroupHeader(settings.headers.usernameGroupHeader);
            setRuleIdHeader(settings.headers.ruleIdHeader);
            setLogHeader(settings.headers.logHeader);
            setActivitySchedule(settings.activity.cleanupSchedule);
            setActivityMaxAge(Math.round(settings.activity.maxAge / 86400));
            setEnableActivitylogClear((settings.activity.maxAge && settings.activity.cleanupSchedule) ? true : false);
            setEnableActivity(settings.activity.enable);
            setUserSAMLMetadata(settings.userSAMLMetadata || "");
            setRequireIcapAuth(settings.requireLogin || false);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching configuration data",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const SaveConfiguration = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        if (enableActivitylogClear) {
            if (!activityMaxAge) {
                notification.Display({
                    title: "Error",
                    message: "Max age is required",
                    type: "error",
                });
                return;
            }
            if (!schedule) {
                notification.Display({
                    title: "Error",
                    message: "Schedule is required",
                    type: "error",
                });
                return;
            }
        }

        setSaving(true);

        try {
            await req.put("/api/config", {
                ...originalSettings,
                enabled,
                enableAPI: enableApi,
                reqModLogEnable: reqModLogEnabled,
                saml: {
                    enable: enableSAML,
                    metadataUrl: SAMLMetadataUrl,
                    metadata: SAMLMetadata,
                },
                headers: {
                    usernameHeader: UsernameHeader,
                    usernameGroupHeader: UsernameGroupHeader,
                    ruleIdHeader: RuleIdHeader,
                    logHeader: LogHeader
                },
                activity: {
                    enable: enableActivity,
                    maxAge: enableActivitylogClear ? ((activityMaxAge || 0) * 86400) : undefined,
                    cleanupSchedule: enableActivitylogClear ? schedule : undefined,
                },
                userSAMLMetadata: UserSAMLMetadata,
                userSAMLMetadataURL: UserSAMLMetadataUrl,
                requireLogin: requireIcapAuth,
            });
            notification.Display({
                type: "success",
                title: "Success",
                message: "Configuration saved successfully",
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error saving configuration",
                type: "error",
            });
        }

        setSaving(false);
        void loadData();
    };


    return <>
        <Loading finished={finishedLoading}>
            <Typography variant="h1">Configuration</Typography>

            <Box display="flex" flexDirection="column">

                <Card sx={{ marginTop: "30px", padding: "0px" }}>
                    <CardHeader component={Typography} titleTypographyProps={{ variant: "h3" }} title={"Dashboard Security"} />
                    <CardContent sx={{ padding: "10px" }}>
                        <Grid container>
                            <Grid item sm={12} >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>
                                    <ConfigurationOption
                                        id="enable-saml-check"
                                        label="Enable SAML"
                                        description="Enable login using SAML"
                                        checked={enableSAML} onChange={setEnableSAML}
                                        disabled={!samlAvailable}
                                    />
                                    <Button
                                        sx={{ marginLeft: "10px" }}
                                        id="saml-open-dialog"
                                        onClick={() => {
                                            setSamlDialogOpen(true);
                                        }}
                                        variant="outlined"
                                        disabled={!samlAvailable}
                                    >
                                        Set IDp Metadata
                                    </Button>
                                    {samlAvailable && <Link sx={{ marginLeft: "10px" }} href="/saml/metadata" download>Download SP metadata</Link>}
                                </Box>
                                {samlNotAvailableReason !== "" ?  <Typography variant="caption" color="text.secondary">{samlNotAvailableReason}</Typography> : undefined}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{ marginTop: "30px", padding: "0px" }}>
                    <CardHeader component={Typography} titleTypographyProps={{ variant: "h3" }} title={"Activity Configuration"} />
                    <CardContent sx={{ padding: "10px" }}>
                        <Grid container>
                            <Grid item sm={12} >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>
                                    <ConfigurationOption
                                        id="enable-activity"
                                        label="Enable Activity Log"
                                        description="Enable writing activity log with request data"
                                        checked={enableActivity} onChange={setEnableActivity}
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={12} >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>
                                    <ConfigurationOption
                                        id="enable-reqmod-log"
                                        label="REQMOD Scanning"
                                        description="Enable writing activity log with request data from REQMOD"
                                        checked={reqModLogEnabled} onChange={setReqModLogEnabled}
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={12} >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>
                                    <ConfigurationOption
                                        id="enable-clear-old-activity-log"
                                        label="Periodically clean activity log"
                                        description="Periodically delete old activity log entries following a specified schedule"
                                        checked={enableActivitylogClear} onChange={setEnableActivitylogClear}
                                    />
                                    <TextField
                                        id="clear-old-activity-log-age"
                                        sx={{ marginLeft: "10px" }}
                                        type="number"
                                        label="Max Age"
                                        variant="outlined"
                                        disabled={!enableActivitylogClear}
                                        InputProps={{ endAdornment: <InputAdornment position="end">Days</InputAdornment> }}
                                        helperText="Entries entries older than specified will be deleted"
                                        value={activityMaxAge}
                                        onChange={(x) => setActivityMaxAge(Number(x.target.value) || undefined)}
                                    />
                                    <TextField
                                        id="clear-old-activity-log-schedule"
                                        sx={{ marginLeft: "10px" }}
                                        label="Schedule"
                                        variant="outlined"
                                        helperText="The delete operation will follow the schedule defined by a crontab specification"
                                        placeholder="* * * *"
                                        disabled={!enableActivitylogClear}
                                        value={schedule}
                                        onChange={(x) => setActivitySchedule(x.target.value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>


                <Card sx={{ marginTop: "30px", padding: "0px" }}>
                    <CardHeader component={Typography} titleTypographyProps={{ variant: "h3" }} title={"License Information"}
                        subheaderTypographyProps={{ variant: "body2", color: "text.secondary" }} subheader={"Please make sure to contact us when your license is close to expiration"} />
                    <CardContent sx={{ padding: "10px 25px" }}>
                        <Grid container sx={{
                            marginBottom: "10px",
                            marginTop: "20px"
                        }}>
                            <Grid item sm={3}>
                                <Typography variant="h4">Licensed To</Typography>
                                <Typography color="text.secondary">{licenseInfo?.name}</Typography>
                            </Grid>
                            <Grid item sm={1} />
                            <Grid item sm={3}>
                                <Typography variant="h4">Expiring at</Typography>
                                <Typography color="text.secondary">{licenseInfo && licenseInfo.expirationDate.toLocaleDateString()}</Typography>
                            </Grid>
                            <Grid item sm={1} />
                            <Grid item sm={3}>
                                <Typography variant="h4">Last updated</Typography>
                                <Typography color="text.secondary">{licenseInfo && licenseInfo.creationDate.toLocaleDateString()}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Box>

            <Box sx={{ marginTop: "10px" }}>
                <ShieldEnabledComponent
                    enabled={enabled}
                    onChange={setEnabled}
                />
            </Box>

            <Box display="flex" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <LoadingButton
                    id="save-changes-button"
                    variant="contained"
                    sx={{ marginLeft: "auto" }}
                    onClick={SaveConfiguration}
                    loading={saving}>Save Changes</LoadingButton>
            </Box>

            <SAMLMetadataDialog
                open={samlDialogOpen}
                onClose={() => {
                    setSamlDialogOpen(false);
                }}
                value={(SAMLMetadata && { type: "xml", metadata: SAMLMetadata }) || (SAMLMetadataUrl && { type: "url", url: SAMLMetadataUrl }) || undefined}
                onSave={(v) => {
                    setSamlDialogOpen(false);
                    if (v.type === "url") {
                        setSAMLMetadata("");
                        setSAMLMetadataUrl(v.url);
                    } else if (v.type === "xml") {
                        setSAMLMetadata(v.metadata);
                        setSAMLMetadataUrl("");
                    }
                }}
            />

            <SAMLMetadataDialog
                open={userSamlDialogOpen}
                onClose={() => {
                    setUserSamlDialogOpen(false);
                }}
                value={(UserSAMLMetadata && { type: "xml", metadata: UserSAMLMetadata }) || (UserSAMLMetadataUrl && { type: "url", url: UserSAMLMetadataUrl }) || undefined}
                onSave={(v) => {
                    setUserSamlDialogOpen(false);
                    if (v.type === "url") {
                        setUserSAMLMetadata("");
                        setUserSAMLMetadataUrl(v.url);
                    } else if (v.type === "xml") {
                        setUserSAMLMetadata(v.metadata);
                        setUserSAMLMetadataUrl("");
                    }
                }}
            />
        </Loading>
    </>;
}

interface ShieldEnabledComponentProps {
    enabled: boolean
    onChange?: (v: boolean) => void
}

function ShieldEnabledComponent(props: ShieldEnabledComponentProps) {
    return <Box sx={props.enabled ? {
        background: "rgba(226, 242, 255, 0.5)",
        border: "1px solid #95CCFC",
        boxSizing: "border-box",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(58, 117, 232, 0.05)",
        borderRadius: "10px",
        padding: "10px",
        alignItems: "center",
        display: "flex"
    } : {
        background: "rgba(255, 232, 244, 0.25)",
        border: "1px solid #F77ABD",
        boxSizing: "border-box",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(58, 117, 232, 0.05)",
        borderRadius: "10px",
        padding: "10px",
        alignItems: "center",
        display: "flex"
    }}>
        {
            props.enabled ? <>
                <CheckCircleOutlineOutlinedIcon sx={{ marginRight: "5px", fill: "#2158C3" }} />
                <Typography variant="h4">Nullafi Shield is enabled</Typography>
                <InfoOutlinedIcon sx={{ marginRight: "5px", marginLeft: "auto" }} fontSize="small" />
                <span>You can use this option to temporally disable Nullafi Shield</span>
            </> : <>
                <ErrorOutlineOutlinedIcon sx={{ marginRight: "5px", fill: "#D82666" }} />
                <Typography variant="h4">Nullafi Shield is disabled</Typography>
                <InfoOutlinedIcon sx={{ marginRight: "5px", marginLeft: "auto" }} fontSize="small" />
                <span>You can use this option to enable Nullafi Shield</span>
            </>
        }

        <Switch checked={props.enabled} onChange={() => props.onChange && props.onChange(!props.enabled)} />
    </Box>;
}

interface ConfigurationOptionsProps {
    id?: string
    label: string
    description?: string
    checked?: boolean
    onChange?: (b: boolean) => void
    hideCheckbox?: boolean
    disabled?: boolean
    children?: React.ReactChild | React.ReactChild[]
}

function ConfigurationOption(props: ConfigurationOptionsProps) {
    return (
        <Box display="flex" sx={{ alignItems: "start", marginBottom: "10px" }}>
            {
                !props.hideCheckbox &&
                <Checkbox id={props.id} disabled={props.disabled} sx={{ "& .MuiSvgIcon-root": { fontSize: "36px" } }} checked={props.checked} onChange={(x) => props.onChange && props.onChange(x.target.checked)} />
            }
            <Box sx={{ marginLeft: "10px", marginTop: "6px", width: "100%" }}>
                <Typography variant="h4" color={props.disabled ? "text.disabled" : undefined}>{props.label}</Typography>
                <Typography variant="body2" color={props.disabled ? "text.disabled": "text.secondary"}>{props.description}</Typography>
                {props.children}
            </Box>
        </Box>
    );
}

interface SAMLMetadataDialogProps {
    value?: SAMLSetupUrl | SAMLSetupXml
    open: boolean
    onSave: (v: SAMLSetupUrl | SAMLSetupXml) => void
    onClose: () => void
}

interface SAMLSetupUrl {
    type: "url",
    url: string
}

interface SAMLSetupXml {
    type: "xml"
    metadata: string
}

function SAMLMetadataDialog(props: SAMLMetadataDialogProps) {
    const [mode, setMode] = useState<"url" | "xml">("url");
    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.value) {
            setMode(props.value.type);

            if (props.value.type === "url") {
                setValue(props.value.url);
            } else if (props.value.type === "xml") {
                setValue(props.value.metadata);
            }
        }
    }, [props.value, props.open]);

    return <Dialog
        open={props.open}
    >
        <DialogTitle>
            {"SAML IDp Metadata"}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "400px" }}>
            {mode === "url" && <>
                <TextField
                    id="idp-url"
                    label="URL to fetch IDp metadata"
                    value={value}
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    fullWidth
                    onChange={(x) => setValue(x.target.value)}
                />
            </>}
            {mode === "xml" && <>
                <TextField
                    sx={{
                        marginTop: "10px"
                    }}
                    inputProps={{
                        style: {
                            whiteSpace: "nowrap"
                        }
                    }}

                    id="idp-metadata"
                    label="IDp Metadata"
                    multiline
                    rows={10}
                    placeholder="IDp Metadata"
                    value={value}
                    fullWidth

                    onChange={(x) => setValue(x.target.value)}
                />
            </>}
        </DialogContent>
        <DialogActions>
            {mode === "url" && <>
                <Button id="saml-mode-metadata" onClick={() => {
                    setValue("");
                    setMode("xml");
                }} variant="outlined">
                    Paste XML
                </Button>
            </>}
            {mode === "xml" && <>
                <Button id="saml-mode-url" onClick={() => {
                    setValue("");
                    setMode("url");
                }} variant="outlined">
                    Fetch from URL
                </Button>
            </>}
            <Button id="cancel-saml-metadata-dialog" onClick={() => {
                props.onClose();
            }} variant="outlined">
                Close
            </Button>
            <Button id="save-saml-metadata-dialog" onClick={() => {
                if (mode === "url") {
                    props.onSave({
                        type: "url",
                        url: value,
                    });
                } else {
                    props.onSave({
                        type: "xml",
                        metadata: value,
                    });
                }
            }} variant="contained" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>;
}

export default Configuration;