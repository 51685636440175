import { ButtonBase, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import { LoginDetail, useLogin } from "providers/Login";
import { useNavigate } from "react-router";
export interface LogoutButtonProps {
    minimal: boolean
    user: LoginDetail
}

export function LogoutButton(props: LogoutButtonProps) {
    const login = useLogin();
    const navigate = useNavigate();

    const logout = () => {
        login.Logout();
        navigate("/login");
    };

    return <ButtonBase
        sx={{
            width: "100%",
            justifyContent: "space-between",
            background: "rgba(176, 183, 207, 0.1)",
            borderRadius: "8px",
            paddingTop: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "10px",
            marginTop: "auto",
        }}
        onClick={logout}
    >
        <Box>
            <Collapse sx={{ flex: 1 }} in={!props.minimal} orientation={"horizontal"} >
                <Typography sx={{ textAlign: "left" }} variant="h5" component="div">
                    {props.user.username}
                </Typography>
                <Typography variant="caption" color="text.secondary" component="div">
                </Typography>
            </Collapse>
        </Box>
        <LogoutIcon />
    </ButtonBase>;
}
