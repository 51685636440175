import { ANTLRInputStream, } from "antlr4ts";
import { searchExpLexer } from "../../../../querylanguage/parser/searchExpLexer";
import Editor from "react-simple-code-editor";
import "./syntaxhighlight.css";


interface QueryInputProps {
    code: string
    setCode: (v: string) => void
    onKeyUp?: (e :any) => void
}

export function QueryInput(props: QueryInputProps) {
    return <Editor
        id="texteditor"
        value={props.code}
        onValueChange={props.setCode}
        onKeyUp={(e) => props.onKeyUp && props.onKeyUp(e)}
        highlight={c => SyntaxHighlightedText({ code: c })}
        padding={10}
        style={{
            fontSize: 12,
            borderRadius: "6px",
            border: "#aaa solid 1px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
            minHeight: "32pt",
            width: "100%"
        }}
    />;
}
interface SyntaxHighlightedTextProps {
    code: string
}

type tokenType =
    "token_keyword" | "token_unknown" | "token_logical_keyword" |
    "token_parenthesis" | "token_logical_comparison" | "token_list_comma" |
    "token_varname" | "token_literal_value" | "token_known_variable"

interface tokenInfo {
    start: number
    end: number
    type: tokenType
}

const knownVariables = [
    "timestamp",
    "instanceId",
    "apps",
    "hostname",
    "contentType",
    "ip",
    "ipLocation",
    "device",
    "rules",
    "detectedDatatypes",
    "obfuscatedDatatypes",
    "scanned",
    "username",
    "userGroup",
    "detected",
    "obfuscated",
    "type",
    "originalValues",
    "originalValueStored",
    "text",
];


const tokenTypeMapping: { [n: number]: tokenType } = {
    1: "token_logical_keyword", //not
    2: "token_parenthesis", //(
    3: "token_parenthesis", //)
    4: "token_logical_keyword", //and
    5: "token_logical_keyword", //or
    6: "token_keyword", //in
    7: "token_keyword", //contains
    8: "token_logical_comparison", // ==
    9: "token_logical_comparison", // !=
    10: "token_logical_comparison", // <
    11: "token_logical_comparison", // >
    12: "token_logical_comparison", // >=
    13: "token_logical_comparison", // <=
    14: "token_list_comma", //,
    15: "token_keyword", //order by
    16: "token_keyword", //asc
    17: "token_keyword", //desc
    18: "token_keyword", //true
    19: "token_keyword", //false
    20: "token_literal_value", //'
    21: "token_literal_value", //-
    22: "token_literal_value", //minutes
    23: "token_literal_value", //hours
    24: "token_literal_value", //days
    25: "token_literal_value", //weeks
    26: "token_varname", //variable name
    27: "token_literal_value", //literal string
};

function SyntaxHighlightedText(props: SyntaxHighlightedTextProps) {
    // Create the lexer and parser
    let inputStream = new ANTLRInputStream(props.code);
    let lexer = new searchExpLexer(inputStream);



    const identifiedTokens: tokenInfo[] = [];

    while (true) {
        const a = lexer.nextToken();

        if (a.channel === 1) {
            continue;
        }
        if (a.type === -1) {
            break;
        }

        if (tokenTypeMapping[a.type] === "token_varname" && knownVariables.includes(a.text || "")) {
            identifiedTokens.push({
                start: a.startIndex,
                end: a.stopIndex,
                type: "token_known_variable",
            });
        } else {
            identifiedTokens.push({
                start: a.startIndex,
                end: a.stopIndex,
                type: tokenTypeMapping[a.type] || "token_unknown",
            });
        }
    }

    const itens: React.ReactNode[] = [];

    let previousEnd = 0;
    for (const i of identifiedTokens) {
        const txt = props.code.slice(previousEnd, i.start);
        if (txt !== "") {
            itens.push(<span className="token_unknown">{txt}</span>);
        }
        itens.push(<span className={i.type}>{props.code.slice(i.start, i.end + 1)}</span>);
        previousEnd = i.end + 1;
    }

    const txt = props.code.slice(previousEnd);
    if (txt !== "") {
        itens.push(<span className="token_unknown">{txt}</span>);
    }

    if(props.code[props.code.length-1] === "\n") {
        itens.push(<span className="token_unknown"> </span>);
    }

    return <>{itens}</>;
}