import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export type NotificationType = "error" | "warning" | "information" | "success";

interface NotificationContextProps {
    children: React.ReactChild | React.ReactChild[]
}

interface NotificationContextData {
    Display: (message: NotificationMessageInfo) => void
    GetMessages: () => NotificationMessage[]
    RemoveMessage: (id: number) => void
}

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

const MessageTimeout = 5000;

export default function NotificationProvider(props: NotificationContextProps) {
    const [messages, setMessages] = useState([] as NotificationMessage[]);
    const messagesRef = useRef([] as NotificationMessage[]);
    const nextId = useRef(0);

    //effect to clean timeouts
    useEffect(() => {
        return () => {
            messages.forEach(x => {
                clearTimeout(x.timeout);
            });
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const RemoveMessage = (id: number) => {
        messagesRef.current = messagesRef.current.filter((x) => x.id !== id);
        setMessages(messagesRef.current);
    };

    const Display = (message: NotificationMessageInfo) => {
        const value = nextId.current;
        const newMessage: NotificationMessage = {
            id: value,
            type: message.type,
            title: message.title,
            message: message.message,
            timeout: setTimeout(() => {
                RemoveMessage(value);
            }, MessageTimeout)
        };

        messagesRef.current = [...messagesRef.current, newMessage];
        setMessages(messagesRef.current);
        nextId.current = value + 1;
    };

    const GetMessages = () => {
        return messages;
    };

    return (
        <NotificationContext.Provider value={{ Display, GetMessages, RemoveMessage }}>
            {props.children}
        </NotificationContext.Provider>
    );
}

interface NotificationMessageInfo {
    type: NotificationType
    title: string
    message: string
}

interface NotificationMessage {
    id: number
    type: NotificationType
    title: string
    message: string
    timeout: NodeJS.Timeout
}

interface NotificationMessageProps {
    type: NotificationType
    title: string
    message: string
    onClose: () => void
}

function NotificationMessageComponent(props: NotificationMessageProps) {
    const boxShadowColor = {
        "error": "#EB417E",
        "warning": "#FF9900",
        "information": "#2158C3",
        "success": "#09D17D",
    };

    const icons = {
        "error": <ErrorOutlineIcon sx={{color: "#EB417E"}}/>,
        "warning": <WarningAmberIcon sx={{color: "#FF9900"}}/>,
        "information": <InfoOutlinedIcon sx={{color: "#2158C3"}}/>,
        "success": <CheckCircleOutlinedIcon sx={{color: "#09D17D"}}/>,
    };

    return (
        <Box
            sx={{
                minWidth: "400px",
                background: "#FFFFFF",
                boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), inset 4px 0px 0px ${boxShadowColor[props.type]}`,
                borderRadius: "6px",
                padding: "10px 10px 10px 20px;",
                left: "40px",
                bottom: "40px",
                marginTop: "5px",
                marginBottom: "5px",
            }}>
            <Box display="flex" sx={{alignItems: "center"}}>
                {icons[props.type]}
                <Typography sx={{marginLeft: "10px"}} variant="h5">{props.title}</Typography>
                <IconButton size="small" sx={{marginLeft: "auto"}} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            
            <Typography variant="body2" color="text.secondary">{props.message}</Typography>

        </Box>
    );
}

export function useNotification() {
    return useContext(NotificationContext);
}

interface NotificationDisplayProps { }

export function NotificationDisplay(args: NotificationDisplayProps) {
    const notification = useNotification();

    return <>
        <TransitionGroup>
            {notification.GetMessages().map(x => (
                <Collapse key={x.id}>
                    <NotificationMessageComponent type={x.type} title={x.title} message={x.message} onClose={() => notification.RemoveMessage(x.id)}/>
                </Collapse>
            ))}
        </TransitionGroup>
    </>;
}