import { Button, Chip, Divider, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import DataTable from "components/Table";
import AddCondition, { AlertCondition } from "./EditFilter";
import AddNotification from "./EditNotification";

interface AddNewAlertPolicyProps {
    alertId?: string
    onSave?: (alert: Alert) => void
    onExit?: () => void
    onBackButtonClicked?: () => void
}

enum AlertNotificationTypes {
    Empty = 0,
    Email = 1,
    Slack = 2,
    Webhook = 3
}

interface Alert {
    id?: string
    name: string
    description: string
    notificationTypes: AlertNotificationTypes[]
    emailRecipients: string[]
    slackChannels: string[]
    occurrencesCount?: number
    thresholdTime?: string
    thresholdType?: string
    conditions: AlertCondition[]
}



export function AddNewAlert(props: AddNewAlertPolicyProps) {
    const login = useLogin();
    const notification = useNotification();

    const [saving, setSaving] = useState(false);
    const [dataTypes, setDataTypes] = useState({} as { [key: string]: string });
    const [rules, setRules] = useState({} as { [key: string]: string });
    const [apps, setApps] = useState({} as { [key: string]: string });
    const [groups, setGroups] = useState({} as { [key: string]: string });

    const activityFields = {
        "apps": "Apps",
        "client_device": "Client Device",
        "client_ip": "Client IP",
        "client_ip_location": "Client IP Location",
        "content_type": "Content Type",
        "datatypes_detected": "Detected Data Types",
        "datatypes_obfuscated": "Obfuscated Data Types",
        "data_values": "Data Values",
        "rules": "Rules",
        "username": "Username",
        "usergroup": "UserGroup",
    } as { [key: string]: string };

    const alertNotificationTypesText = {
        "1": "Email",
        "2": "Slack",
        "3": "Webhook"
    } as { [key: string]: string };

    const [ready, setReady] = useState(false);
    const [name, setName] = useState("");
    const [defaultEmailRecipient, setDefaultEmailRecipient] = useState("");
    const [defaultSlackChannel, setDefaultSlackChannel] = useState("");
    const [occurrencesCount, setOccurrencesCount] = useState(undefined as undefined | number);
    const [thresholdTime, setThresholdTime] = useState("");
    const [thresholdType, setThresholdType] = useState("");
    const [description, setDescription] = useState("");
    const [notificationTypes, setNotificationTypes] = useState([] as AlertNotificationTypes[]);
    const [slackChannels, setSlackChannels] = useState([] as string[]);
    const [emailRecipients, setEmailRecipients] = useState([] as string[]);
    const [conditions, setConditions] = useState([] as AlertCondition[]);

    const [beingEdited, setBeingEdited] = useState(undefined as AlertCondition | undefined);
    const [notificationBeingEdited, setNotificationBeingEdited] = useState(undefined as undefined | number);

    const init = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            const respRules = await req.get("/api/rules", {
                params: {
                    sortBy: "name asc"
                }
            });
            const respDataTypes = await req.get("/api/datatypes", {
                params: {
                    sortBy: "name asc"
                }
            });
            const respApps = await req.get("/api/apps", {
                params: {
                    sortBy: "name asc"
                }
            });

            const respGroups = await req.get("/api/user/groups", {
                params: {
                    "skiphidden": "true"
                }
            });


            const respConfig = await req.get("/api/config");
            const settings = respConfig.data;



            if (settings.smtpNotificationSettings) {
                setDefaultEmailRecipient(settings.smtpNotificationSettings.to);
            }

            if (settings.slackNotificationSettings) {
                setDefaultSlackChannel(settings.slackNotificationSettings.channel);
            }

            const dataTypesM = {} as { [key: string]: string };
            (respDataTypes.data.items || []).forEach((x: any) => {
                dataTypesM[x.type] = x.name;
            });

            setDataTypes(dataTypesM);

            const rulesM = {} as { [key: string]: string };
            (respRules.data.items || []).forEach((x: any) => {
                rulesM[x.id] = x.name;
            });

            setRules(rulesM);

            const appsM = {} as { [key: string]: string };
            (respApps.data.items || []).forEach((x: any) => {
                appsM[x.id] = x.name;
            });

            setApps(appsM);

            const groupsM = {} as { [key: string]: string };
            (respGroups.data.groups || []).forEach((x: any) => {
                groupsM[x.identifier] = x.identifier;
            });

            setGroups(groupsM);

            //editing
            if (props.alertId) {
                const r = await req.get(`/api/alerts/${props.alertId}`);
                const alert = APIPayloadToAlert(r.data);

                setName(alert.name);
                setDescription(alert.description);
                setNotificationTypes(alert.notificationTypes || []);
                setSlackChannels(alert.slackChannels || []);
                setEmailRecipients(alert.emailRecipients || []);
                setOccurrencesCount(alert.occurrencesCount);
                setThresholdTime(alert.thresholdTime || "");
                setThresholdType(alert.thresholdType || "");
                setConditions(alert.conditions || []);
            }
            setReady(true);
        } catch (err) {
            console.error(err);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onConditionAdded = (data: AlertCondition, reset: () => void) => {
        if (data.index === undefined) {
            setConditions(conditions.concat(data));
        } else {
            setConditions(conditions.map((x, index) => {
                if (index === data.index) {
                    return data;
                } else {
                    return x;
                }
            }));
        }
        reset();
        setBeingEdited(undefined);
    };

    const deleteCondition = (index: number) => {
        const list = conditions.filter((_, i) => {
            return i !== index;
        });

        setConditions(list);
    };

    const editCondition = (index: number) => {
        const a = { ...conditions[index], index };
        setBeingEdited(a);
    };

    const validate = () => {
        if (!name || !name.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Name is required."
            });

            return false;
        }

        if (!conditions || !conditions.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 definition for an Alert."
            });

            return false;
        }

        return true;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (!validate()) {
            return;
        }

        setSaving(true);

        if (props.alertId) {
            //update
            try {
                const resp = await req.put(`/api/alerts/${props.alertId}`,
                    AlertToAPIPayload({
                        name: name,
                        description: description,
                        notificationTypes: notificationTypes,
                        slackChannels: slackChannels,
                        emailRecipients: emailRecipients,
                        occurrencesCount: occurrencesCount,
                        thresholdTime: thresholdTime,
                        thresholdType: thresholdType,
                        conditions: conditions
                    })
                );

                const addedAlert = APIPayloadToAlert(resp.data);

                props.onSave && props.onSave(addedAlert);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save alert",
                });
                return;
            }
        } else {
            //create
            try {
                const resp = await req.post("/api/alerts",
                    AlertToAPIPayload({
                        name: name,
                        description: description,
                        notificationTypes: notificationTypes,
                        slackChannels: slackChannels,
                        emailRecipients: emailRecipients,
                        occurrencesCount: occurrencesCount,
                        thresholdTime: thresholdTime,
                        thresholdType: thresholdType,
                        conditions: conditions
                    })
                );

                const addedApp = APIPayloadToAlert(resp.data);

                props.onSave && props.onSave(addedApp);
            } catch (err) {
                setSaving(false);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save alert",
                });
                return;
            }
        }

        notification.Display({
            type: "success",
            title: "Success",
            message: "Alert saved successfully",
        });
    };

    return <Loading finished={ready}>
        <>
            <Stack id="add-alert-page" sx={{ flex: 1, height: "100%" }}>
                <Box display="flex" sx={{ alignItems: "start" }}>
                    <Box>
                        {props.onBackButtonClicked ?
                            <Button
                                id="back-button"
                                startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                                onClick={props.onBackButtonClicked}
                            >
                                Back
                            </Button>
                            : undefined
                        }
                        <Typography sx={{ marginTop: "5px" }} variant="h2">{props.alertId ? "Edit Alert" : "Add New Alert"}</Typography>
                    </Box>
                    <IconButton
                        id="exit-button"
                        size="small"
                        sx={{ marginLeft: "auto" }}
                        onClick={props.onExit}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <TextField sx={{ marginTop: "20px" }} id="name-input" name="name" label="Name" variant="outlined"
                    value={name}
                    onChange={x => setName(x.target.value)}
                />

                <TextField sx={{ marginTop: "20px" }} id="occurrences-input" name="occurrences" label="Description" variant="outlined"
                    value={description}
                    onChange={x => setDescription(x.target.value)}
                />



                <Stack direction={"row"} sx={{ marginTop: "20px" }} spacing={2} alignItems={"center"}>
                    <TextField
                        fullWidth
                        name="interval"
                        label="Interval"
                        value={thresholdTime}
                        onChange={(x) => {
                            setThresholdTime(x.target.value);
                        }}
                    />
                    <Select
                        fullWidth
                        value={thresholdType}
                        onChange={x => setThresholdType(x.target.value as any)}
                    >
                        <MenuItem value={"minutes"}>minutes</MenuItem>
                        <MenuItem value={"hours"}>hours</MenuItem>
                        <MenuItem value={"days"}>days</MenuItem>
                        <MenuItem value={"cron"}>CRON</MenuItem>
                    </Select>
                </Stack>


                <Typography sx={{ marginTop: "40px" }} variant="h3">Alert me when:</Typography>
                <Box>
                    <DataTable
                        noDataText="No conditions to display"
                        data={conditions.map((v, idx) => {
                            return { ...v, id: idx.toString() };
                        })}
                        columnsDataId="dataGrid_AlertConditionTable_columns"
                        columnsState={[
                            { columnId: "type", width: "40%" },
                            { columnId: "descriptions", width: "60%" },
                        ]}
                        columns={{
                            type: {
                                displayName: "Field",
                                render: (x) => {
                                    return activityFields[x.type];
                                }
                            },
                            descriptions: {
                                displayName: "Value",
                                render: (x) => {
                                    let chips: any = [];

                                    switch (x.type) {
                                    case "rules":
                                        chips = chips.concat(x.values.map(x => <Chip label={rules[x]} variant="outlined" color="primary" size="small" />));
                                        break;
                                    case "apps":
                                        chips = chips.concat(x.values.map(x => <Chip label={apps[x]} variant="outlined" color="primary" size="small" />));
                                        break;
                                    case "datatypes_detected":
                                    case "datatypes_obfuscated":
                                        chips = chips.concat(x.values.map(x => <Chip label={dataTypes[x]} variant="outlined" color="primary" size="small" />));
                                        break;
                                    default:
                                        chips = chips.concat(x.values.map(x => <Chip label={x} variant="outlined" color="primary" size="small" />));
                                    }

                                    return <Stack direction={"row"} spacing={1}>
                                        {chips}
                                    </Stack>;
                                }
                            },
                        }}
                        rowOptionsMenu={[
                            {
                                icon: <EditIcon className="edit-button" fontSize="small" />,
                                text: "Edit",
                                onClick: (x) => {
                                    editCondition(parseInt(x.id));
                                }
                            },
                            {
                                icon: <DeleteIcon className="delete-button" fontSize="small" />,
                                text: "Delete",
                                onClick: (x) => {
                                    deleteCondition(parseInt(x.id));
                                }
                            },
                        ]}
                        finishedLoading
                    />
                </Box>

                <AddCondition
                    initialValue={beingEdited}
                    groups={groups}
                    rules={rules}
                    applications={apps}
                    dataTypes={dataTypes}
                    activityFields={activityFields}
                    onCancel={() => setBeingEdited(undefined)}
                    onFilterAdded={onConditionAdded}
                />


                {!beingEdited ?
                    <>
                        <Typography sx={{ marginTop: "40px" }} variant="h3">Send Alert to:</Typography>
                        <Box>
                            <DataTable
                                noDataText="No conditions to display"
                                data={notificationTypes.map((v, idx) => {
                                    let values: string[] = [];

                                    if (v === AlertNotificationTypes.Email) {
                                        values = emailRecipients;
                                    }

                                    if (v === AlertNotificationTypes.Slack) {
                                        values = slackChannels;
                                    }

                                    if (v === AlertNotificationTypes.Webhook) {
                                        values = ["Webhook"];
                                    }

                                    return { type: v, typeText: alertNotificationTypesText[v], values, id: idx.toString() };
                                })}
                                columnsDataId="dataGrid_AlertConditionTable_columns"
                                columnsState={[
                                    { columnId: "typeText", width: "20%" },
                                    { columnId: "values", width: "80%" },
                                ]}
                                columns={{
                                    typeText: {
                                        displayName: "Type",
                                        render: (x) => {
                                            return x.typeText;
                                        }
                                    },
                                    values: {
                                        displayName: "Value",
                                        render: (x) => {
                                            return <Stack direction={"row"} spacing={1}>
                                                {x.values.map(x => <Chip label={x} variant="outlined" color="primary" size="small" />)}
                                            </Stack>;
                                        }
                                    },
                                }}
                                rowOptionsMenu={[
                                    {
                                        icon: <EditIcon className="edit-button" fontSize="small" />,
                                        text: "Edit",
                                        onClick: (x) => {
                                            setNotificationBeingEdited(x.type);
                                        }
                                    },
                                    {
                                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                                        text: "Delete",
                                        onClick: (x) => {
                                            if (x.type === AlertNotificationTypes.Email) {
                                                setEmailRecipients([]);
                                            }

                                            if (x.type === AlertNotificationTypes.Slack) {
                                                setSlackChannels([]);
                                            }

                                            setNotificationTypes(notificationTypes.filter(y => y !== x.type));
                                        }
                                    },
                                ]}
                                finishedLoading
                            />

                        </Box>

                        <AddNotification
                            initialValue={notificationBeingEdited}
                            notificationTypes={alertNotificationTypesText}
                            defaultEmailRecipient={defaultEmailRecipient}
                            defaultSlackChannel={defaultSlackChannel}
                            emailRecipients={emailRecipients}
                            slackChannels={slackChannels}
                            onCancel={() => setNotificationBeingEdited(0)}
                            onBackButtonClicked={() => setNotificationBeingEdited(0)}
                            onAdded={(type: number, values: string[], reset: () => void) => {

                                let types = notificationTypes;

                                if (notificationBeingEdited !== type) {
                                    if (notificationBeingEdited === AlertNotificationTypes.Email) {
                                        setEmailRecipients([]);
                                    }

                                    if (notificationBeingEdited === AlertNotificationTypes.Slack) {
                                        setSlackChannels([]);
                                    }

                                    types = types.filter(y => y !== notificationBeingEdited);
                                }

                                if (types && !types.includes(type)) {
                                    types = types.concat([type]);
                                }

                                setNotificationTypes(types);

                                if (type === AlertNotificationTypes.Email) {
                                    setEmailRecipients(values);
                                }

                                if (type === AlertNotificationTypes.Slack) {
                                    setSlackChannels(values);
                                }

                                setNotificationBeingEdited(0);
                                reset();
                            }}
                        />
                    </> : undefined}



                {
                    !beingEdited && !notificationBeingEdited ? <>
                        <Divider sx={{ marginTop: "auto" }} />
                        <Box display="flex" sx={{ marginTop: "10px" }}>
                            <Button
                                id="cancel-button"
                                variant="outlined"
                                size="medium"
                                onClick={props.onExit}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                id="save-button"
                                loading={saving}
                                sx={{ marginLeft: "auto" }}
                                variant="contained"
                                size="medium"
                                onClick={save}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </> : undefined
                }
            </Stack>

        </>
    </Loading>;
}

function APIPayloadToAlert(body: any) {
    const app: Alert = {
        id: body.id,
        name: body.name,
        description: body.description,
        notificationTypes: body.notificationTypes,
        emailRecipients: body.emailRecipients,
        slackChannels: body.slackChannels,
        occurrencesCount: body.occurrencesCount || undefined,
        thresholdTime: body.thresholdTime || undefined,
        thresholdType: body.thresholdType || undefined,
        conditions: body.conditions ? body.conditions.map((x: any) => {
            const ret: AlertCondition = {
                type: x.type,
                values: x.values,
            };
            return ret;
        }) : undefined
    };
    return app;
}

function AlertToAPIPayload(alert: Alert): any {
    return {
        id: alert.id,
        name: alert.name,
        description: alert.description,
        notificationTypes: alert.notificationTypes,
        emailRecipients: alert.emailRecipients,
        slackChannels: alert.slackChannels,
        occurrencesCount: alert.occurrencesCount,
        thresholdTime: alert.thresholdTime,
        thresholdType: alert.thresholdType,
        conditions: alert.conditions
    };
}