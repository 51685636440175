import {
    Button,
    Chip,
    IconButton,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "components/Table";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { OpenInNew } from "@mui/icons-material";
import GenerateApiKeyDialog from "./_components/GenerateApiKeyDialog";
import ApiKey from "./_components/Types";
import DeleteAPIKeyDialog from "./_components/DeleteApiKeyDialog";
import { ChipList } from "components/ChipList";

export default function ApiKeys() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
    const [loadingApiKeys, setLoadingApiKeys] = useState(false);
    const [apiKeyBeingDeleted, setApiKeyBeingDeleted] = useState<undefined | ApiKey>();
    const [generatingApiKey, setGeneratingApiKey] = useState(false);

    const deleteAPIKey = async (key: ApiKey) => {
        setApiKeyBeingDeleted(key);
    };

    const req = login.GetAxios();

    const loadApiKeys = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        setLoadingApiKeys(true);
        try {
            const resp = await req.get("/api/auth/apikey");
            const apiKeysData = resp.data;

            if (apiKeysData.items) {
                setApiKeys(apiKeysData.items.map((x: any) => {
                    return {
                        id: x.id,
                        name: x.name,
                        allowPolicyDefinition: x.allowPolicyDefinition,
                        allowDataScanning: x.allowDataScanning,
                        sessionType: x.sessionType,
                        snippet: x.tokenSnippet,
                        creationDate: new Date(x.createdTimestamp * 1000),
                        expirationDate: new Date(x.expirationTimestamp * 1000),
                        expired: x.expired,
                    };
                }));
            } else {
                setApiKeys([]);
            }
            setLoadingApiKeys(false);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching api key data",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadApiKeys();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Box display="flex" flexDirection="column">
            <Box display="flex">
                <Typography variant="h1">API Keys</Typography>
                <Box display="flex" sx={{ marginLeft: "auto" }} >
                    <Button
                        id="api-docs-link-button"
                        variant="text"
                        sx={{ marginRight: "10px" }}
                        size="small"
                        href="/swagger"
                        target="_blank"
                        startIcon={<OpenInNew />}
                    >
                        API Documentation
                    </Button>
                    <Button
                        id="generate-api-key-button"
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setGeneratingApiKey(true);
                        }}
                    >
                        Generate API Key
                    </Button>
                </Box>
            </Box>
            <DataTable
                sx={{ marginTop: "5px" }}
                data={apiKeys}
                columnsDataId="dataGrid_apiKeyTable_columns_v2"
                columnsState={[
                    {
                        columnId: "name",
                        width: "35%",
                    },
                    {
                        columnId: "snippet",
                        width: "10%",
                    },
                    {
                        columnId: "allowPolicyDefinition",
                        width: "20%",
                    },
                    {
                        columnId: "expirationdate",
                        width: "10%",
                    },
                    {
                        columnId: "createdat",
                        width: "10%",
                    },
                    {
                        columnId: "status",
                        width: "10%",
                    },
                    {
                        columnId: "buttons",
                        width: "5%",
                    },
                ]}
                columns={{
                    name: {
                        displayName: "Name",
                        render: (a) => {
                            return a.name;
                        }
                    },
                    allowPolicyDefinition: {
                        displayName: "Rights",
                        render: (a) => {
                            const access = [];
                            if (a.allowPolicyDefinition) {
                                access.push("Policy Definition");
                            }

                            if (a.allowDataScanning) {
                                access.push("Data Scanning");
                            }

                            if (!a.allowDataScanning && !a.allowPolicyDefinition) {
                                access.push("Read Only");
                            }

                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "row",
                                gap: "2px"
                            }}>
                                <ChipList items={access} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="primary" />} />
                            </Box>;
                        }
                    },
                    snippet: {
                        displayName: "Snippet",
                        render: (a) => {
                            return a.snippet;
                        }
                    },
                    expirationdate: {
                        displayName: "Expiration Date",
                        render: (a) => {
                            return a.expirationDate.toLocaleDateString() + " " + a.expirationDate.toLocaleTimeString();
                        }
                    },
                    createdat: {
                        displayName: "Creation Date",
                        render: (a) => {
                            return a.creationDate.toLocaleDateString() + " " + a.creationDate.toLocaleTimeString();
                        }
                    },
                    status: {
                        displayName: "Status",
                        render: (a) => {
                            return "Active";
                        }
                    },
                    buttons: {
                        displayName: "",
                        render: (a) => {
                            if (a.sessionType !== "env_api_key") {
                                return <IconButton className="delete-button" onClick={() => deleteAPIKey(a)}>
                                    <DeleteIcon />
                                </IconButton>;
                            }

                            return "";
                        }
                    },
                }}
                finishedLoading={!loadingApiKeys}
            />

            <DeleteAPIKeyDialog
                keyBeingDeleted={apiKeyBeingDeleted}
                onClose={(deleted) => {
                    setApiKeyBeingDeleted(undefined);
                    if (deleted) {
                        loadApiKeys();
                    }
                }}
            />
            <GenerateApiKeyDialog
                open={generatingApiKey}
                onExit={() => {
                    setGeneratingApiKey(false);
                }}
                onSave={() => {
                    loadApiKeys();
                }}
            />
        </Box>
    </>;
}