import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import TestConfig from "./TestConfig";

interface SlackConfigProps {
  data: SlackConfigForm | undefined;
  onSaveChanges: (data: SlackConfigForm) => void;
}

export interface SlackConfigForm {
    WebhookURL: string;
    Channel: string;
    Username: string;
}

function SlackConfiguration(props: SlackConfigProps) {
    const [slackWebhookURL, setSlackWebhookURL] = useState("");
    const [slackChannel, setSlackChannel] = useState("");
    const [slackUsername, setSlackUsername] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (props.data) {
            setSlackWebhookURL(props.data.WebhookURL);
            setSlackChannel(props.data.Channel);
            setSlackUsername(props.data.Username);
            setLoaded(true);
        }
    }, [props.data]);

    useEffect(() => {
        setDisabled(true);
        setIsSaved(false);
    }, [slackChannel, slackUsername, slackWebhookURL]);

    useEffect(() => {
        if (isSaved || loaded) {
            setDisabled(false);
        }
    }, [isSaved, loaded]);

    const handleSave = async () => {
        setIsSaving(true);
        props.onSaveChanges({
            WebhookURL: slackWebhookURL,
            Channel: slackChannel,
            Username: slackUsername,
        });
        setIsSaved(true);
        setIsSaving(false);
    };

    return (
        <Stack>
            <Grid container sx={{ marginBottom: "10px", marginTop: "20px" }}>
                <Grid item sm={11}>
                    <TextField
                        id="slack-webhookurl-id-input"
                        label="Webhook URL"
                        fullWidth
                        variant="outlined"
                        value={slackWebhookURL}
                        onChange={(x) => setSlackWebhookURL(x.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: "10px", marginTop: "40px" }}>
                <Grid item sm={5}>
                    <TextField
                        id="slack-channel-id-input"
                        label="Default Channel"
                        fullWidth
                        variant="outlined"
                        value={slackChannel}
                        onChange={(x) => setSlackChannel(x.target.value)}
                    />
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={5}>
                    <TextField
                        id="slack-username-id-input"
                        label="Username"
                        fullWidth
                        variant="outlined"
                        value={slackUsername}
                        onChange={(x) => setSlackUsername(x.target.value)}
                    />                   
                </Grid>
                <Grid container sx={{ marginBottom: "10px", marginTop: "60px" }}>
                    <Grid item sm={8} />
                    <Grid item sm={3}>                      
                        <Box display="flex">
                            <TestConfig notifier="slack" disabled={disabled}></TestConfig>
                            <LoadingButton
                                id="save-changes-button"
                                variant="contained"
                                sx={{ marginLeft: "auto" }}
                                loading={isSaving}
                                onClick={handleSave}
                            >
                            Save Changes
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item sm={1} />
                </Grid>
            </Grid>
        </Stack>
    );
}

export default SlackConfiguration;