import { Autocomplete, Chip, TextField } from "@mui/material";
import { Box } from "@mui/system";

interface ListInputProps {
    id: string;
    size?: "small" | "medium";
    label: string;
    placeholder: string;
    delimiters: string[];
    value: string[];
    onChange: (values: string[]) => void;
    onDelete: (value: string) => void;
}

export function ListInput(props: ListInputProps) {
    return <>
        <Box sx={{ marginTop: "20px" }}>
            <Autocomplete
                multiple
                id={props.id}
                size={props.size || "medium"}
                clearIcon={false}
                onInputChange={(event: any, newInputValue) => {
                    if (props.delimiters && newInputValue.length && props.delimiters.some(x => x === newInputValue[newInputValue.length - 1])) {
                        event.target.blur();
                        event.target.focus();
                    }
                }}
                renderTags={(values, getTagProps) => null}
                options={[]}
                value={props.value}
                onChange={(e, value) => props.onChange(value)}
                freeSolo
                autoSelect
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                label={props.label}
                                placeholder={props.placeholder}
                            />
                        </>
                    );
                }}
            />
            <Box sx={{ marginTop: "10px", gap: "5px", display: "flex", flexWrap: "wrap", minHeight: "32px" }}>
                {props.value.map(x => {
                    return <Chip label={x} variant="outlined" color="primary" onDelete={() => props.onDelete(x)} />;
                })}
            </Box>
        </Box>
    </>;
}
