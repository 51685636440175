import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import { useNavigate } from "react-router";
import axios from "axios";
import { ApiErrorCode } from "helpers/ApiErrorCode";

interface TestConfigProps {
    notifier: string;
    disabled: boolean;
}

function TestConfig(props: TestConfigProps) {
    const [isSaving, setIsSaving] = useState(false);
    
    const login = useLogin();
    const notification = useNotification();
    const navigate = useNavigate();

    useEffect(() => {}, [props.disabled]);

    const testConfig = async (notifier: string) => {
        const req = login.GetAxios();
        if (!req) {
            return;
        }
        try {
            const payload = {
                notifier: notifier,
            };

            const successText = `Test message delivered via ${notifier.toUpperCase()}`;

            await req.post("/api/config/notification/test", payload);
            notification.Display({
                title: "Success",
                message: successText,
                type: "success",
            });
        }
        catch (err: any) {
            console.log(err);
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: err.response.data.message || "Configuration test failed",
                type: "error",
            });
        }
    };

    const handleTest = async () => {
        setIsSaving(true);
        await testConfig(props.notifier);        
        setIsSaving(false);
    };

    return (
        <Box>
            <LoadingButton
                variant="outlined"
                loading={isSaving}
                onClick={handleTest}
                disabled={props.disabled}
            >
                Send Test Message
            </LoadingButton>             
        </Box>
    );
};


export default TestConfig;