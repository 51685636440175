import { ListItemIcon, ListItemText, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Theme, SxProps, TableContainer, Typography, TableSortLabel, CircularProgress, IconButton, FormControl, Select, TextField } from "@mui/material";
import { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/system";
import { MoreVert } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ColumnResizer from "./ColumnResizer";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
enum DraggingElementType {
    Row,
    Column
}

export interface Column<K> {
    columnId: K
    width?: string
    sortOrder?: number
    direction?: "asc" | "desc"
}

export interface ColumnDef<T> {
    displayName: string,
    renderSubHeader?: () => React.ReactChild
    maxWidth?: string
    sortable?: boolean
    render: (item: T) => React.ReactChild | string | undefined
}

interface OptionsMenuItem<T> {
    isVisible?: (item: T) => boolean
    icon?: React.ReactChild | ((item: T) => React.ReactChild)
    text: string | ((item: T) => string)
    onClick?: (item: T) => void
}

interface DataTableItem {
    id: string
}

interface TableProps<T, K extends string> {
    columnsDataId: string
    columns: { [key in K]: ColumnDef<T> }
    columnsState: Column<K>[]
    paginationItemCount?: number
    dataCount?: number
    paginationStartItem?: number
    onPaginationStartItemChanged?: (newPage: number) => void
    onPaginationItemCountChanged?: (newLimit: number) => void
    onRowOrderChanged?: (newItemList: T[]) => void
    onColumnOrderChanged?: (newColumnList: Column<K>[]) => void
    onSortChanged?: (newColumnList: Column<K>[]) => void
    onColumnResize?: (newColumnList: Column<K>[]) => void
    data: T[]
    rowOptionsMenu?: OptionsMenuItem<T>[]
    finishedLoading: boolean
    sx?: SxProps<Theme>
    tableSx?: SxProps<Theme>
    noDataText?: string
    size?: "medium" | "small"
}


interface InternalColumnState<K> extends Column<K> {
    dragging: boolean
}

export function useTableColumnState<K>(columnsState: Column<K>[]) {
    return useState(columnsState);
}

export function SaveColumnsState<K>(key: string, columnsState: Column<K>[]) {
    localStorage.setItem(key + "_state", JSON.stringify(columnsState));
}


export function RestoreColumnsState<K>(key: string, defaultValue: Column<K>[]) {
    const pld = localStorage.getItem(key + "_state");
    if (!pld) {
        return defaultValue;
    }
    return JSON.parse(pld) as Column<K>[];
}

export function GetSortString<K>(cState: Column<K>[]) {
    return cState.filter(x => x.sortOrder !== undefined).sort((a, b) => a.sortOrder! - b.sortOrder!).map(x => {
        return `${x.columnId as string} ${x.direction}`;
    }).join(",");
}


export default function DataTable<T extends DataTableItem, K extends string>(props: TableProps<T, K>) {
    const [elementBeingDragged, setElementBeingDragged] = useState<ElementBeingDraggedState<T> | undefined>();
    const headRef = useRef<HTMLTableRowElement>();
    const bodyRef = useRef<HTMLTableElement>();
    const tableRef = useRef<HTMLDivElement>();
    const mockTableElement = useRef<HTMLTableElement>();

    const toggleSortDirection = (fieldName: K) => {
        let currentFilter =
            props.columnsState
                .filter((x) => x.sortOrder !== undefined)
                .sort((a, b) => b.sortOrder! - a.sortOrder!)
                .map(x => {
                    return {
                        columnId: x.columnId,
                        direction: x.direction
                    };
                });

        const currentField = currentFilter.find(x => x.columnId === fieldName);
        if (!currentField) {
            //column is not in the ordering set yet, add it with the "asc" direction
            currentFilter = currentFilter.concat({ columnId: fieldName, direction: "asc" }).slice(-3);
        } else {
            if (currentField.direction === "asc") {
                //current direction is "asc", change to "desc"
                currentFilter = currentFilter.map(x => {
                    if (x.columnId !== fieldName) {
                        return x;
                    }
                    return {
                        columnId: x.columnId,
                        direction: "desc"
                    };
                });
            } else {
                //current direction is "desc", remove the field from the ordering set
                currentFilter = currentFilter.filter(x => x.columnId !== fieldName);
            }
        }

        props.onSortChanged && props.onSortChanged(props.columnsState.map(x => {
            const c = currentFilter.findIndex(y => y.columnId === x.columnId);
            if (c === -1) {
                return {
                    ...x,
                    direction: undefined,
                    sortOrder: undefined,
                };
            }

            return {
                ...x,
                direction: currentFilter[c].direction,
                sortOrder: currentFilter.length - c,
            };
        }));
    };

    const computeVisibleColumns = () => {
        const newColumns: InternalColumnState<K>[] = props.columnsState.map(x => {
            return {
                ...x,
                dragging: false,
            };
        });

        if (elementBeingDragged?.type === "column") {
            newColumns[elementBeingDragged.columnIndex].dragging = true;
            moveItem(newColumns, elementBeingDragged.columnIndex, elementBeingDragged.destinationColumn);
        }

        return newColumns;
    };

    const computeVisibleRows = () => {
        const newColumns = props.data.map(x => {
            return {
                data: x,
                dragging: false,
            };
        });

        if (elementBeingDragged?.type === "row") {
            newColumns[elementBeingDragged.rowIndex].dragging = true;
            moveItem(newColumns, elementBeingDragged.rowIndex, elementBeingDragged.destinationRow);
        }

        return newColumns;
    };

    const getRowsBoundingBox = () => {
        if (!bodyRef.current?.childNodes) {
            console.error("row being dragged not found");
            return; //not found, nothing to do
        }

        const row = Array.from(bodyRef.current?.childNodes) as Element[];
        return row.map(x => x.getBoundingClientRect());
    };

    const getColumnsBoundingBox = () => {
        if (!bodyRef.current?.childNodes) {
            console.error("column being dragged not found");
            return; //not found, nothing to do
        }

        const rows = Array.from(bodyRef.current?.childNodes) as Element[];

        if (!rows.length) {
            console.error("no rows found");
            return; //not found, nothing to do
        }

        return Array.from(rows[0].childNodes).map((cell) => {
            return (cell as Element).getBoundingClientRect();
        });
    };

    useLayoutEffect(() => {
        if (!elementBeingDragged) {
            return;
        }

        if (elementBeingDragged.type === "column") {
            const f = function (e: any) {
                //update fake table position
                if (mockTableElement.current) {
                    mockTableElement.current.style.left = `${e.x - elementBeingDragged.pivot.x}px`;
                    mockTableElement.current.style.top = `${e.y - elementBeingDragged.pivot.y}px`;
                }

                const elements = getColumnsBoundingBox();
                if (elements === undefined) {
                    console.error("no elements but table is in dragging state");
                    return;
                }

                const elementOriginalIndex = elementBeingDragged.destinationColumn;
                const currentPos = elements[elementOriginalIndex];

                let currentIndex = elementOriginalIndex;

                if (e.x < currentPos.left) {
                    //going left
                    while (currentIndex > 0) {
                        const otherPos = elements[currentIndex - 1];
                        const forbiddenArea = Math.max(otherPos.width - currentPos.width, 0);
                        if (e.x > (otherPos.right - forbiddenArea)) {
                            break;
                        }
                        currentIndex--;
                    }
                } else {
                    //going right
                    while (currentIndex < (elements.length - 1)) {
                        const otherPos = elements[currentIndex + 1];
                        const forbiddenArea = Math.max(otherPos.width - currentPos.width, 0);
                        if (e.x < (otherPos.left + forbiddenArea)) {
                            break;
                        }
                        currentIndex++;
                    }
                }

                if (elementOriginalIndex !== currentIndex) {
                    setElementBeingDragged({
                        ...elementBeingDragged,
                        destinationColumn: currentIndex,
                    });
                }

            };
            document.addEventListener("mousemove", f);

            const mouseUpHandler = () => {
                const visibleColumns = computeVisibleColumns();
                props.onColumnOrderChanged && props.onColumnOrderChanged(visibleColumns);
                setElementBeingDragged(undefined);
            };
            document.addEventListener("mouseup", mouseUpHandler);
            return () => {
                document.removeEventListener("mouseup", mouseUpHandler);
                document.removeEventListener("mousemove", f);
            };
        } else if (elementBeingDragged.type === "row") {
            const f = function (e: any) {
                //update fake row position
                if (mockTableElement.current) {
                    mockTableElement.current.style.left = `${e.x - elementBeingDragged.pivot.x}px`;
                    mockTableElement.current.style.top = `${e.y - elementBeingDragged.pivot.y}px`;
                }

                const elements = getRowsBoundingBox();
                if (elements === undefined) {
                    console.error("no elements but table is in dragging state");
                    return;
                }

                const elementOriginalIndex = elementBeingDragged.destinationRow;
                const currentPos = elements[elementOriginalIndex];

                let currentIndex = elementOriginalIndex;

                if (e.y < currentPos.top) {
                    //going up
                    while (currentIndex > 0) {
                        const otherPos = elements[currentIndex - 1];
                        const forbiddenArea = Math.max(otherPos.height - currentPos.height, 0);
                        if (e.y > (otherPos.bottom - forbiddenArea)) {
                            break;
                        }
                        currentIndex--;
                    }
                } else {
                    //going down
                    while (currentIndex < (elements.length - 1)) {
                        const otherPos = elements[currentIndex + 1];
                        const forbiddenArea = Math.max(otherPos.height - currentPos.height, 0);
                        if (e.y < (otherPos.top + forbiddenArea)) {
                            break;
                        }
                        currentIndex++;
                    }
                }

                if (elementOriginalIndex !== currentIndex) {
                    setElementBeingDragged({
                        ...elementBeingDragged,
                        destinationRow: currentIndex,
                    });
                }

            };
            document.addEventListener("mousemove", f);

            const mouseUpHandler = () => {
                const visibleRows = computeVisibleRows();
                props.onRowOrderChanged && props.onRowOrderChanged(visibleRows.map(x => x.data));
                setElementBeingDragged(undefined);
            };
            document.addEventListener("mouseup", mouseUpHandler);
            return () => {
                document.removeEventListener("mouseup", mouseUpHandler);
                document.removeEventListener("mousemove", f);
            };
        }


    }, [elementBeingDragged]); //eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <TableContainer ref={tableRef as any} sx={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
            minHeight: "150px",
            marginLeft: props.onRowOrderChanged ? "-40px" : "-20px",
            paddingLeft: props.onRowOrderChanged ? "80px" : "40px",
            ...props.sx
        }}>
            <Stack alignItems="self-start">
                <Table size={props.size} sx={{ width: "100%", ...props.tableSx }} stickyHeader>
                    <TableHead sx={{
                        /*
                        position: "sticky",
                        top: "0",
                        zIndex: "2",
                        background: "white",
                        boxShadow: "inset 0 -1px 0 #dddd",
                        */
                    }}>
                        <TableRow ref={headRef as any}>
                            {computeVisibleColumns().map((col, index, arr) => {
                                const renderSubHeader: any = props.columns[col.columnId] && props.columns[col.columnId].renderSubHeader;
                                return <TableCell key={index} sx={{
                                    width: col.width || "auto",
                                }}>
                                    <TableSortLabel
                                        direction={col.direction || undefined}
                                        active={Boolean(col.direction)}
                                        hideSortIcon={!col.direction}
                                        onClick={() => {
                                            if (!props.columns[col.columnId].sortable) {
                                                return;
                                            }
                                            toggleSortDirection(col.columnId);
                                        }}
                                    >
                                        <Stack direction="row">
                                            {props.onColumnOrderChanged ?
                                                <MenuIcon
                                                    sx={{
                                                        transform: "scale(0.8)",
                                                        marginRight: "15px",
                                                        color: "#B0B7CF",
                                                        cursor: "pointer"
                                                    }}
                                                    onMouseDown={e => {
                                                        if (!(bodyRef?.current?.childNodes)) {
                                                            return;
                                                        }
                                                        if (!(headRef?.current?.childNodes[index])) {
                                                            return;
                                                        }

                                                        const headerSize = (headRef.current.childNodes[index] as Element).getBoundingClientRect();
                                                        const rowsSize = Array.from(bodyRef.current.childNodes).map(row => (row.childNodes[index] as Element).getBoundingClientRect());

                                                        setElementBeingDragged({
                                                            type: "column",
                                                            data: props.data,
                                                            columnIndex: index,
                                                            destinationColumn: index,
                                                            dimensions: {
                                                                width: headerSize.width,
                                                                height: tableRef.current!.getBoundingClientRect().height,
                                                                cells: rowsSize.map(c => {
                                                                    return ({
                                                                        width: c.width,
                                                                        height: c.height,
                                                                    });
                                                                }),
                                                            },
                                                            initialPos: {
                                                                x: headerSize.left,
                                                                y: headerSize.top,
                                                            },
                                                            pivot: {
                                                                x: e.pageX - headerSize.left,
                                                                y: e.pageY - headerSize.top,
                                                            },
                                                            //scrollPos: tableRef.current?.scrollTop,
                                                        });
                                                    }}
                                                /> : null
                                            }

                                            {props.columns[col.columnId].displayName}
                                        </Stack>
                                    </TableSortLabel>
                                    {renderSubHeader && renderSubHeader()}
                                    {col.sortOrder !== undefined ? <span style={{
                                        position: "absolute",
                                        fontSize: 10,
                                        fontWeight: 600,
                                        marginTop: -5,
                                        marginLeft: -5
                                    }}>{col.sortOrder}</span> : null}
                                    <ColumnResizer
                                        onResize={(columnWidth) => {
                                            props.onColumnOrderChanged && props.onColumnOrderChanged(props.columnsState.map(x => {
                                                if (x.columnId === col.columnId) {
                                                    return {
                                                        ...x,
                                                        width: columnWidth,
                                                    };
                                                }
                                                return x;
                                            }));
                                        }}
                                        id={`resizer-${props.columns[col.columnId].displayName}`}
                                        disabled={false}
                                        minWidth={25}
                                    />
                                </TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody ref={bodyRef as any}>
                        {props.finishedLoading && computeVisibleRows().map((row, idx) => (
                            <MemoInternalTableRow
                                key={row.data.id}
                                columns={props.columns}
                                columnsState={computeVisibleColumns()}
                                data={row.data}
                                draggable={Boolean(props.onRowOrderChanged)}
                                onStartDragging={!elementBeingDragged ? (x, mousePos) => {
                                    if (elementBeingDragged) {
                                        return;
                                    }
                                    if (!bodyRef.current?.childNodes) {
                                        console.error("table body ref has no children");
                                        return; //not found, nothing to do
                                    }

                                    const rowElement = (bodyRef.current.childNodes[idx] as Element);
                                    const rowArea = rowElement.getBoundingClientRect();
                                    const columns = Array.from(rowElement.childNodes) as Element[];

                                    setElementBeingDragged({
                                        type: "row",
                                        data: row.data,
                                        destinationRow: idx,
                                        rowIndex: idx,
                                        dimensions: {
                                            width: rowArea.width,
                                            height: rowArea.height,
                                            cells: columns.map(c => {
                                                const columnRect = c.getBoundingClientRect();
                                                return ({
                                                    width: columnRect.width,
                                                    height: columnRect.height,
                                                });
                                            }),
                                        },
                                        initialPos: {
                                            x: rowArea.left,
                                            y: rowArea.top,
                                        },
                                        pivot: {
                                            x: mousePos.x - rowArea.left,
                                            y: mousePos.y - rowArea.top,
                                        },

                                    });
                                } : undefined}
                                rowOptionsMenu={props.rowOptionsMenu}
                                dragging={elementBeingDragged?.type === "row" && elementBeingDragged.destinationRow === idx}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Stack>
            {
                (!props.finishedLoading &&
                    <Box sx={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex"
                    }}>
                        <CircularProgress />
                    </Box>) ||
                ((props.data.length === 0 && props.finishedLoading) &&
                    <Typography variant="body1" sx={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "10px"
                    }}>
                        {props.noDataText || "No data to display"}
                    </Typography>)
                || undefined
            }
            {
                elementBeingDragged !== undefined &&
                <MemoFakeTable
                    ref={mockTableElement}
                    tableRef={tableRef}
                    state={elementBeingDragged}
                    columns={props.columns}
                    columnsState={props.columnsState}
                />
            }
        </TableContainer >
        {
            props.dataCount && props.paginationItemCount && props.paginationStartItem !== undefined && props.onPaginationStartItemChanged && props.finishedLoading ?
                <Pagination
                    start={props.paginationStartItem}
                    limit={props.paginationItemCount}
                    itemCount={props.dataCount}
                    onStartChange={(x) => props.onPaginationStartItemChanged && props.onPaginationStartItemChanged(x)}
                    onLimitChange={(x) => props.onPaginationItemCountChanged && props.onPaginationItemCountChanged(x)}
                />
                /*
                <TablePagination
                    component="div"
                    count={props.dataCount}
                    rowsPerPage={props.pageCount}
                    rowsPerPageOptions={[]}
                    page={props.page}
                    onPageChange={(e, page) => props.onPageChanged && props.onPageChanged(page)}
                />
                */
                : null
        }
    </>;
}

interface PaginationProps {
    start: number
    limit: number
    itemCount: number
    onStartChange: (newStart: number) => void
    onLimitChange: (limit: number) => void
}

function Pagination(props: PaginationProps) {
    const [text, setText] = useState("");
    const [error, setError] = useState(false);

    return <Stack alignItems="center" direction="row" marginTop="5px" justifyContent="right">
        <Typography variant="body2" color="text.secondary">
            Entries per page:
        </Typography>
        <FormControl size="small" sx={{ m: 1, minWidth: 100 }}>
            <Select
                displayEmpty
                value={props.limit}
                onChange={(e) => {
                    setText("");
                    props.onLimitChange(Number(e.target.value));
                }}
            >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
            </Select>
        </FormControl>

        <Typography sx={{ marginLeft: "20px" }}>
            {props.start + 1}-{Math.min(props.start + props.limit, props.itemCount)} of {props.itemCount}
        </Typography>
        <IconButton
            sx={{
                marginLeft: "5px"
            }}
            disabled={props.start === 0}
            onClick={() => {
                setText("");
                setError(false);
                props.onStartChange(Math.max(props.start - props.limit, 0));
            }}
        >
            <NavigateBeforeIcon />
        </IconButton>
        <TextField sx={{ width: "100px" }} size="small" label="Skip To..." variant="outlined"
            error={error}
            onChange={(e) => {
                setText(e.target.value);
            }}
            onKeyUp={(x) => {
                if (x.key !== "Enter") {
                    return;
                }
                const number = parseInt(text);
                if (Number.isNaN(number) || number <= 0 || number >= props.itemCount) {
                    setError(true);
                    return;
                }

                props.onStartChange(number - 1);

                setText("");
                setError(false);

            }}
        />
        <IconButton
            sx={{
                marginLeft: "5px"
            }}
            disabled={props.start + props.limit >= props.itemCount}
            onClick={() => {
                let newPos = props.start + props.limit;
                if (newPos > props.itemCount) {
                    newPos = props.itemCount - props.limit;
                }
                props.onStartChange(newPos);
                setText("");
                setError(false);
            }}
        >
            <NavigateNextIcon />
        </IconButton>
    </Stack>;
}


export function moveItem<P>(arr: P[], i: number, finalPos: number) {
    while (i > finalPos) {
        [arr[i - 1], arr[i]] = [arr[i], arr[i - 1]];
        i--;
    }

    while (i < finalPos) {
        [arr[i + 1], arr[i]] = [arr[i], arr[i + 1]];
        i++;
    }
}

interface FakeTableProps<T, K extends string> {
    state: ElementBeingDraggedState<T>
    columnsState: Column<K>[]
    columns: { [key in K]: ColumnDef<T> }
    tableRef: React.MutableRefObject<HTMLDivElement | undefined>
}


function FakeTable<T extends DataTableItem, K extends string>(props: FakeTableProps<T, K>, ref: any) {
    const state = props.state;

    return <TableContainer ref={ref} sx={{
        position: "absolute",
        backgroundColor: "#fff",
        top: `${props.state.initialPos.y}px`,
        left: `${props.state.initialPos.x}px`,
        width: props.state.dimensions.width,
        zIndex: "999999999",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05)",
        maxHeight: `${props.tableRef.current!.clientHeight}px`,
        overflow: "hidden",
    }}>
        <Table stickyHeader>
            {state.type === "column" ?
                (() => {
                    const columnState = props.columnsState[state.columnIndex];
                    const column = props.columns[props.columnsState[state.columnIndex].columnId];
                    return <TableHead>
                        <TableRow>
                            <TableCell key="header" sx={{
                                width: state.dimensions.width || "auto",
                            }}>
                                <TableSortLabel
                                    direction={columnState.direction || undefined}
                                    active={Boolean(columnState.direction)}
                                    hideSortIcon={!columnState.direction}
                                >
                                    <MenuIcon sx={{
                                        transform: "scale(0.8)",
                                        marginRight: "15px",
                                        color: "#B0B7CF",
                                        cursor: "pointer"
                                    }}
                                    />
                                    {column.displayName}
                                </TableSortLabel>
                                {columnState.sortOrder !== undefined ? <span style={{
                                    position: "absolute",
                                    fontSize: 10,
                                    fontWeight: 600,
                                    marginTop: -5,
                                    marginLeft: -5
                                }}>{columnState.sortOrder}</span> : undefined}
                            </TableCell>
                        </TableRow>
                    </TableHead>;
                })() : undefined}
            <TableBody>
                {
                    state.type === "column" ?
                        state.data.map((data, i) => {
                            return <InternalTableRow
                                key={i}
                                columns={props.columns}
                                columnsState={props.columnsState.filter((_, i) => i === state.columnIndex).map(x => { return { ...x, dragging: false }; })}
                                data={data}
                                dimensions={{
                                    width: state.dimensions.width,
                                    height: state.dimensions.cells[i].height,
                                    cells: [state.dimensions.cells[i]],
                                }}
                                isDraggingRepresentation={true}
                                draggingElementType={DraggingElementType.Column}
                                dragging={false}
                            />;
                        }) : <InternalTableRow
                            columns={props.columns}
                            columnsState={props.columnsState.map(x => {
                                return {
                                    ...x,
                                    dragging: false,
                                };
                            })}
                            data={state.data}
                            dimensions={state.dimensions}
                            isDraggingRepresentation={true}
                            dragging={false}
                            draggingElementType={DraggingElementType.Row}
                        />
                }
            </TableBody>

        </Table>
    </TableContainer>;
}

const MemoFakeTable = forwardRef(FakeTable) as any;

interface InternalTableRowProps<T extends DataTableItem, K extends string> {
    data: T
    columnsState: InternalColumnState<K>[]
    columns: { [key in K]: ColumnDef<T> }
    draggable?: boolean
    rowOptionsMenu?: OptionsMenuItem<T>[]
    onStartDragging?: (data: T, mousePos: { x: number, y: number }) => void
    dimensions?: ElementForcedDimensions
    isDraggingRepresentation?: boolean
    draggingElementType?: DraggingElementType
    dragging: boolean
}

interface ElementForcedDimensions {
    width: number
    height: number
    cells: {
        width: number
        height: number
    }[]
}

type ElementBeingDraggedState<T> = ElementBeingDraggedStateRow<T> | ElementBeingDraggedStateColumn<T>;

interface ElementBeingDraggedStateRow<T> {
    type: "row"
    data: T
    destinationRow: number
    rowIndex: number
    initialPos: {
        x: number
        y: number
    }
    pivot: {
        x: number
        y: number
    }
    dimensions: ElementForcedDimensions
}

interface ElementBeingDraggedStateColumn<T> {
    type: "column"
    data: T[]
    destinationColumn: number
    columnIndex: number
    initialPos: {
        x: number
        y: number
    }
    pivot: {
        x: number
        y: number
    }
    dimensions: ElementForcedDimensions
}

function InternalTableRow<T extends DataTableItem, K extends string>(props: InternalTableRowProps<T, K>) {
    const rowRef = useRef<HTMLTableRowElement>();

    const draggingStarted = (mouseX: number, mouseY: number) => {
        props.onStartDragging && props.onStartDragging(props.data, { x: mouseX, y: mouseY });
    };

    const rowOptionsMenu = props.rowOptionsMenu && props.rowOptionsMenu.filter(x => x.isVisible === undefined || x.isVisible(props.data));


    return <TableRow
        ref={rowRef as any}
        key={props.data.id}
        sx={{
            position: "relative",
            "&:hover .itemmenu": {
                opacity: 1,
            },
            ...(props.dimensions && {
                "height": `${props.dimensions.height}px`,
            }),
        }}
    >
        {props.columnsState.map((column, index) => {
            return <InternalTableCell key={index} sx={{
                wordBreak: "break-word",
                position: "relative",
                maxWidth: props.columns[column.columnId].maxWidth || "none",
                ...(props.dimensions && {
                    "width": `${props.dimensions.cells[index].width}px`,
                    "height": `${props.dimensions.cells[index].height}px`,
                }),
            }}>

                {

                    (column.dragging || props.dragging) ? <Box sx={{ position: "absolute", backgroundColor: "#e4f2ff", left: "0", top: "0", width: "100%", height: "100%", zIndex: "9999999" }}>
                    </Box>
                        : undefined

                }
                {
                    (index === 0 && rowOptionsMenu && rowOptionsMenu.length && !props.dragging) ?
                        //draw menu button on the first cell
                        <>
                            <TableItemMenu
                                draggableRows={props.draggable}
                                onStartMoving={draggingStarted}
                                options={rowOptionsMenu.map(x => {
                                    return {
                                        text: x.text instanceof Function ? x.text(props.data) : x.text,
                                        icon: x.icon instanceof Function ? x.icon(props.data) : x.icon,
                                        onClick: () => {
                                            x.onClick && x.onClick(props.data);
                                        }
                                    };
                                })} />
                        </>
                        :
                        undefined
                }
                {
                    (index === 0 && props.isDraggingRepresentation && props.draggingElementType === DraggingElementType.Row) ? <TableItemMenu
                        options={[]}
                        draggableRows={true}
                        forceDisplay={true}
                    /> : null
                }
                {
                    props.columns[column.columnId].render(props.data)
                }
            </InternalTableCell>;
        })}
    </TableRow>;
}

const MemoInternalTableRow = InternalTableRow; //memo(InternalTableRow) as typeof InternalTableRow;

interface InternalTableCellProps {
    sx?: SxProps<Theme>
    dragging?: boolean
    children: React.ReactNode
}
function InternalTableCell(props: InternalTableCellProps) {
    return <TableCell sx={props.sx}>
        {props.children}
    </TableCell>;
}

interface TableEditComponentProps {
    forceDisplay?: boolean
    draggableRows?: boolean
    options: TableEditComponentOption[]
    onStartMoving?: (x: number, y: number) => void
}

interface TableEditComponentOption {
    icon?: React.ReactChild
    text: string
    onClick?: () => void
}

function TableItemMenu(props: TableEditComponentProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {props.options.map((x, index) =>
                    <MenuItem key={index} onClick={() => {
                        setAnchorEl(null);
                        x.onClick && x.onClick();
                    }}>
                        {x.icon ? <ListItemIcon>{x.icon}</ListItemIcon> : null}
                        <ListItemText>{x.text}</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <Box
                className="itemmenu"
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    transform: "translateX(-100%)",
                    opacity: (open || props.forceDisplay) ? 1 : 0,
                    "&:hover": {
                        visibility: "visible",
                        opacity: 1,
                    },
                    transition: "opacity 0.1s ease-in-out",
                    cursor: "pointer"
                }}
            >
                {props.draggableRows ? <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#B0B7CF",
                        padding: "10px"
                    }}
                    onMouseDown={x => {
                        props.onStartMoving && props.onStartMoving(x.pageX, x.pageY);
                    }}
                >
                    <MenuIcon sx={{
                        transform: "scale(0.8)"
                    }} />
                </Box> : null}

                <Box
                    className="more-options-button"
                    sx={{
                        height: "100%",
                        width: "20px",
                        background: "#2158C3",
                        borderRadius: "6px 0px 0px 6px",
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                        justifyContent: "center",
                    }}
                    onClick={handleClick}
                >
                    <MoreVert sx={{
                        transform: "scale(0.8)"
                    }} />
                </Box>

            </Box>
        </>);
}