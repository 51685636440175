import { LoadingButton } from "@mui/lab";
import { Typography, FormControl, TextField, Button } from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import { useLogin } from "providers/Login";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

interface loginSetupProps {
    samlSetup: () => void
    setError: (e: string) => void
}

function LoginSetup(props: loginSetupProps) {
    const loginCtx = useLogin();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [inProgress, setInProgress] = useState(false);

    const navigate = useNavigate();

    const init = async () => {
        if (loginCtx.LoggedIn) {
            navigate("/");
        }

        const response = await axios.get("/api/auth/options");
        if (response.data.adminUserExists === true) {
            navigate("/login");
        }
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        if (password !== passwordConfirmation) {
            props.setError("Password does not match");
            return;
        }

        setInProgress(true);

        try {
            await axios.post("/api/auth/register", {
                "username": username,
                "password": password,
            });

            await loginCtx.Login(username, password);
            navigate("/");
        } catch (e: any) {
            if (axios.isAxiosError(e) && e.response) {
                const msg = e.response.data.message as string;
                props.setError(msg.charAt(0).toUpperCase() + msg.slice(1));
            } else {
                props.setError("Could not register admin user");
            }
            setInProgress(false);
            return;
        }
    };

    return (
        <Stack>
            <Typography>Provide an username and password to be used as admin credentials</Typography>
            <FormControl sx={{ marginTop: "10px" }}>
                <TextField
                    id="username-input"
                    name="username"
                    label="Username"
                    variant="outlined"
                    value={username}
                    onChange={(x) => setUsername(x.target.value)}
                />
                <TextField
                    sx={{ marginTop: "30px" }}
                    id="password-input"
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(x) => setPassword(x.target.value)}
                    onKeyUp={(x) => (x.key === "Enter" ? onSubmit() : undefined)}
                />
                <TextField
                    sx={{ marginTop: "30px" }}
                    id="password-input"
                    name="password_confirmation"
                    label="Confirm password"
                    variant="outlined"
                    type="password"
                    value={passwordConfirmation}
                    onChange={(x) => setPasswordConfirmation(x.target.value)}
                    onKeyUp={(x) => (x.key === "Enter" ? onSubmit() : undefined)}
                />
            </FormControl>

            <Stack direction="row" sx={{ marginTop: "40px" }}>
                <Button onClick={props.samlSetup}>SAML Setup</Button>
                <LoadingButton id="submit-button" loading={inProgress} variant="contained" onClick={onSubmit} sx={{ marginLeft: "auto" }}>Submit</LoadingButton>
            </Stack>
        </Stack>
    );
}

export default LoginSetup;