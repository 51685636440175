import { Box } from "@mui/system";
import Sidebar from "./Sidebar";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import SAMLLogout from "./pages/SAMLLogout";
import { useEffect, useState } from "react";
import { LoginDetail, useLogin, VersionInfo } from "providers/Login";
import { useNavigate } from "react-router";
import Loading from "components/Loading";
import Configuration from "./pages/Configuration";
import ApiKeys from "./pages/ApiKeys";
import Integrations from "./pages/Integrations";
import { Container } from "@mui/material";
import Apps from "./pages/Apps";
import Obfuscations from "./pages/Obfuscations";
import DataTypes from "./pages/DataTypes";
import MaskFormats from "./pages/MaskFormats";
import Rules from "./pages/Rules";
import Activites from "./pages/Activity";
import IcapConfiguration from "./pages/Icap";
import Groups from "./pages/Groups";
import Alerts from "./pages/AlertPolicy";
import AlertLogs from "./pages/AlertLog";
import AlertNotification from "./pages/AlertNotification";
import ICAPSessions from "./pages/Icap/users";

function App() {
    const login = useLogin();
    const navigate = useNavigate();
    const [finished, setFinished] = useState(false);
    const [user, setUser] = useState({} as LoginDetail);
    const [versionInfo, setVersionInfo] = useState<VersionInfo | undefined>();

    const init = async () => {
        const detail = await login.GetLoginDetail();
        if (!detail) {
            await login.Logout();
            navigate("/login");
            return;
        }

        const versionInfo = await login.FetchVersion();
        

        setUser(detail);
        setVersionInfo(versionInfo);
        setFinished(true);
    };
    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Loading finished={finished}>
                <Box sx={{ display: "flex", height: "100vh"}}>
                    <Sidebar user={user} versionInfo={versionInfo} />
                    <Box sx={{ height: "100vh", overflow: "auto", flexGrow: "1" }}>
                        <Container maxWidth={false} sx={{ height: "100%", padding: "80px !important" }}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/configuration" element={<Configuration />} />
                                <Route path="/icap" element={<IcapConfiguration />} />
                                <Route path="/samlsessions" element={<ICAPSessions />} />
                                <Route path="/apikeys" element={<ApiKeys />} />
                                <Route path="/directory" element={<Integrations />} />
                                <Route path="/alertnotification" element={<AlertNotification />} />
                                <Route path="/applications" element={<Apps />} />
                                <Route path="/obfuscations" element={<Obfuscations />} />
                                <Route path="/maskformats" element={<MaskFormats />} />
                                <Route path="/activities" element={<Activites />} />
                                <Route path="/datatypes" element={<DataTypes />} />
                                <Route path="/alerts" element={<Alerts />} />
                                <Route path="/alertlogs" element={<AlertLogs />} />
                                <Route path="/rules" element={<Rules />} />
                                <Route path="/groups" element={<Groups />} />
                                <Route path="/icaplogout" element={<SAMLLogout />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Container>
                    </Box>
                </Box>
            </Loading>

        </>

    );
}

export default App;
