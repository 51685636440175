import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddNewMaskFormat } from "./AddMaskFormat";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";

interface MaskFormatListState {
    items: MaskFormatListStateItem[]
}

interface ObfuscationsState {
    id: string
    name: string
}

interface MaskFormatListStateItem {
    id: string
    name: string
    type: Number
    internal: Number
    maskCharacter: string
    stopCharacter: string
    charactersToIgnore: string
    numberOfCharacters?: Number
    keepCharacters: Number
    reverseOrder: Number
    updatedAt: Date
    createdAt: Date
    obfuscations?: ObfuscationsState[]
}

type columnsType = "id" | "internal" | "name" | "usedby" | "mask_character" | "characters_to_ignore" | "number_of_characters" | "keep_characters" | "reverse_order" |  "updated_at";

export default function MaskFormats() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [addMaskFormatlicationDrawer, setAddMaskFormatDrawer] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogMaskFormatId, setDeleteDialogMaskFormatId] = useState("");
    const [editingMaskFormatId, setEditingMaskFormatId] = useState("");

    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);
    const [dataCount, setDataCount] = useState(0);


    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "20%",  direction: "asc", sortOrder: 2},
        { columnId: "id", width: "20%" },
        { columnId: "internal", width: "10%",  direction: "asc", sortOrder: 1 },
        { columnId: "usedby", width: "10%" },
        { columnId: "mask_character", width: "10%" },
        { columnId: "characters_to_ignore", width: "5%" },
        { columnId: "number_of_characters", width: "5%" },
        { columnId: "keep_characters", width: "5%" },
        { columnId: "reverse_order", width: "5%" },
        { columnId: "updated_at", width: "10%" },
    ]);

    const [listState, setListState] = useState({ items: [] } as MaskFormatListState);

    const req = login.GetAxios();

    const loadData = async (c: typeof columnsState, skip: number, take: number) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            let sortBy = GetSortString(columnsState);

            const resp = await req.get("/api/maskformats", {
                params: {
                    sortBy,
                    skip,
                    take,
                }
            });

            setColumnsState(c);
            setListState({
                items: (resp.data.items || []).map((x: any) => {
                    const item: MaskFormatListStateItem = {
                        id: x.id,
                        name: x.name,
                        type: x.type,
                        internal: x.internal,
                        maskCharacter: x.maskCharacter,
                        stopCharacter: x.stopCharacter,
                        charactersToIgnore: x.charactersToIgnore,
                        numberOfCharacters: x.numberOfCharacters,
                        keepCharacters: x.keepCharacters,
                        reverseOrder: x.reverseOrder,
                        updatedAt: new Date(x.updatedAt * 1000),
                        createdAt: new Date(x.createdAt * 1000),
                        obfuscations: x.obfuscations && x.obfuscations.map((item: any) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        })
                    };
                    return item;
                })
            });

            setSkipItems(skip);
            setTakeCount(take);
            setDataCount(resp.data.count || 0);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const deleteRowDialog = (maskName: string, maskId: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the mask format "${maskName}"?`);
        setDeleteDialogMaskFormatId(maskId);
    };

    const deleteMaskFormat = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/maskformats/${deleteDialogMaskFormatId}`);
            loadData(columnsState, skipItems, takeCount);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Mask Format deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete Mask Format",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };

    const addMaskFormat = () => {
        setEditingMaskFormatId("");
        setAddMaskFormatDrawer(true);
    };

    const editMaskFormat = (maskFormatId: string) => {
        setEditingMaskFormatId(maskFormatId);
        setAddMaskFormatDrawer(true);
    };

    useEffect(() => {
        loadData(RestoreColumnsState("maskformats_v3",columnsState), skipItems, takeCount);
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState("maskformats_v3", columnsState);
    }, [columnsState]);

    return <>
        <Box display="flex">
            <Typography variant="h1">Mask Formats</Typography>
            <Button
                id="new-mask-format-button"
                sx={{ marginLeft: "auto" }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={addMaskFormat}
            >
                Add New Mask Format
            </Button>
        </Box>

        <DataTable
            columnsDataId={"mask_formats_grid_v3"}
            data={listState.items}
            finishedLoading={finishedLoading}
            onSortChanged={(x) => loadData(x, skipItems, takeCount)}
            onColumnOrderChanged={(x) => setColumnsState(x)}
            columnsState={columnsState}
            onPaginationStartItemChanged={(x) => {
                loadData(columnsState, x, takeCount);
            }}
            onPaginationItemCountChanged={(x) => {
                loadData(columnsState, skipItems, x);
            }}
            paginationStartItem={skipItems}
            paginationItemCount={takeCount}
            dataCount={dataCount}
            columns={{
                id: {
                    displayName: "Id",
                    sortable: true,
                    render: (x) => {
                        return x.id;
                    },
                },
                internal: {
                    displayName: "Internal",
                    sortable: true,
                    render: (x) => {
                        return x.internal ? "YES" : "NO";
                    },
                },
                name: {
                    displayName: "Name",
                    sortable: true,
                    render: (x) => {
                        return x.name;
                    },
                },
                usedby: {
                    displayName: "Used By Obfuscation(s)",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            {(x.obfuscations && x.obfuscations.map(y => <Chip label={y.name.trim()} variant="outlined" size="small" color="primary" />))}
                        </Box>;
                    },
                },
                mask_character: {
                    displayName: "Mask Char.",
                    render: (x) => {
                        return x.maskCharacter;
                    },
                },
                characters_to_ignore: {
                    displayName: "Chars. to Ignore",
                    render: (x) => {
                        return x.charactersToIgnore;
                    },
                },
                number_of_characters: {
                    displayName: "# of Chars.",
                    render: (x) => {
                        return x.numberOfCharacters ? x.numberOfCharacters.toString() : "";
                    },
                },
                keep_characters: {
                    displayName: "Keep Chars",
                    render: (x) => {
                        return x.keepCharacters ? "YES" : "NO";
                    },
                },
                reverse_order: {
                    displayName: "Reverse Order",
                    render: (x) => {
                        return x.reverseOrder ? "YES" : "NO";
                    },
                },
                updated_at: {
                    displayName: "Last Update",
                    sortable: true,
                    render: (x) => {
                        return `${x.updatedAt.toLocaleDateString()} ${x.updatedAt.toLocaleTimeString()}`;
                    },
                },

            }}
            rowOptionsMenu={[
                {
                    icon: <EditIcon className="edit-button" fontSize="small" />,
                    text: "Edit",
                    isVisible: (x) => !x.internal,
                    onClick: (x) => {
                        editMaskFormat(x.id);
                    }
                },
                {
                    icon: <DeleteIcon className="delete-button" fontSize="small" />,
                    text: "Delete",
                    isVisible: (x) => !x.internal,
                    onClick: (x) => {
                        if (x.obfuscations && x.obfuscations.length > 0) {
                            return notification.Display({
                                type: "error",
                                title: "Validation Error",
                                message: `Cannot delete ${x.name} because it is in use by ${x.obfuscations.length === 1 ? "an Obfuscation" : "some Obfuscations"} (${x.obfuscations.map(i => i.name).join(", ")})`,
                            });
                        }

                        deleteRowDialog(x.name, x.id);
                    }
                },
            ]}
        />
        <Dialog open={deleteDialog}>
            <DialogTitle>Delete Mask Format</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteMaskFormat}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addMaskFormatlicationDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <AddNewMaskFormat maskFormatId={editingMaskFormatId} onExit={() => setAddMaskFormatDrawer(false)} onSave={() => { loadData(columnsState, skipItems, takeCount); setAddMaskFormatDrawer(false); }} />
            </Box>
        </Drawer>
    </>;
}