import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import TestConfig from "./TestConfig";

interface WebhookConfigProps {
  data: WebhookConfigForm | undefined;
  onSaveChanges: (data: WebhookConfigForm) => void;
}

export interface WebhookConfigForm {
    URL: string;
}

function WebHookConfiguration(props: WebhookConfigProps) {
    const [webhookURL, setWebhookURL] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (props.data) {
            setWebhookURL(props.data.URL);
            setLoaded(true);
        }
    }, [props.data]);

    useEffect(() => {
        setDisabled(true);
        setIsSaved(false);
    }, [webhookURL]);

    useEffect(() => {
        if (isSaved || loaded) {
            setDisabled(false);
        }
    }, [isSaved, loaded]);

    const handleSave = async () => {
        setIsSaving(true);
        props.onSaveChanges({
            URL: webhookURL,
        });
        setIsSaved(true);
        setIsSaving(false);
    };

    return (
        <Stack>
            <Grid container sx={{ marginBottom: "10px", marginTop: "20px" }}>
                <Grid item sm={11}>
                    <TextField
                        id="slack-webhookurl-id-input"
                        label="URL"
                        fullWidth
                        variant="outlined"
                        value={webhookURL}
                        onChange={(x) => setWebhookURL(x.target.value)}
                    />                    
                </Grid>
                <Grid container sx={{ marginBottom: "20px", marginTop: "50px" }}>
                    <Grid item sm={8} />
                    <Grid item sm={3}>                      
                        <Box display="flex" sx={{ marginTop: "20px"}}>
                            <TestConfig notifier="webhook" disabled={disabled}></TestConfig>
                            <LoadingButton
                                id="save-changes-button"
                                variant="contained"
                                sx={{ marginLeft: "auto" }}
                                loading={isSaving}
                                onClick={handleSave}
                            >
                            Save Changes
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item sm={1} />
                </Grid>
            </Grid>
        </Stack>
    );
}

export default WebHookConfiguration;