import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable, { GetSortString, useTableColumnState } from "components/Table";
import { ArrowDropDown } from "@mui/icons-material";
import { FilterButton, FilterFreeInput } from "../Activity/filterbar";


interface ICAPSessionModel {
    users: ICAPSessionItem[]
}

interface ICAPSessionItem {
    id: string
    username: string
    ip: string
    useragent: string
    lastTimeActive: Date
    expireAt: Date
    groups: string[]
}


interface ICAPSessionsProps {

}

type columnsType = "userName" | "ip" | "userAgent" | "groups" | "lastTimeActive" | "expireAt";

export default function ICAPSessions(props: ICAPSessionsProps) {
    const navigate = useNavigate();
    const login = useLogin();
    const notification = useNotification();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogUserId, setDeleteDialogUserId] = useState("");

    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);
    const [dataCount, setDataCount] = useState(0);

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "userName", width: "20%", sortOrder: 1, direction: "asc" },
        { columnId: "ip", width: "10%" },
        { columnId: "userAgent", width: "20%" },
        { columnId: "groups", width: "20%" },
        { columnId: "lastTimeActive", width: "10%" },
        { columnId: "expireAt", width: "10%" },
    ]);

    const [listState, setListState] = useState<ICAPSessionModel>({ users: [] });

    const [showUsernameFilter, setShowUsernameFilter] = useState(false);
    const [showUserAgentFilter, setShowUserAgentFilter] = useState(false);
    const [showIPFilter, setShowIPFilter] = useState(false);
    const [showGroupsFilter, setShowGroupsFilter] = useState(false);

    const [usernamesFilter, setUsernamesFilter] = useState([] as string[]);
    const [userAgentsFilter, setUserAgentsFilter] = useState([] as string[]);
    const [ipsFilter, setIpsFilter] = useState([] as string[]);
    const [groupsFilter, setGroupsFilter] = useState([] as string[]);

    const req = login.GetAxios();

    const loadData = async (c: typeof columnsState, skipItems: number, takeCount: number) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            let sortBy = GetSortString(c);

            const resp = await req.get("/api/users", {
                params: {
                    sortBy,
                    skip: skipItems,
                    take: takeCount,
                    username: usernamesFilter,
                    useragent: userAgentsFilter,
                    ip: ipsFilter,
                    groups: groupsFilter,
                }
            });

            setSkipItems(skipItems);
            setTakeCount(takeCount);
            setColumnsState(c);
            setListState({
                users: (resp.data.users || []).map((x: any) => {
                    const item: ICAPSessionItem = {
                        id: x.id,
                        username: x.userName,
                        ip: x.ip,
                        lastTimeActive: new Date(x.lastTimeActive * 1000),
                        expireAt: new Date(x.expireAt * 1000),
                        useragent: x.userAgent,
                        groups: x.groups,
                    };
                    return item;
                })
            });
            setDataCount(resp.data.count);

            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not user fetch data",
                type: "error",
            });
        }
    };

    const deleteUser = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const resp = await req.post("/api/users/delete", {
                UserIds: [deleteDialogUserId],
            });

            const result = resp.data.Results[0];

            if (!result.Success) {
                notification.Display({
                    title: "Error",
                    message: "Could not delete user",
                    type: "error",
                });
                console.error(result.Error);
                return;
            }

            setDeleteDialogUserId("");
            loadData(columnsState, skipItems, takeCount);
        } catch (err) {
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not delete user",
                type: "error",
            });
        }
    };

    const deleteUserDialog = (user: ICAPSessionItem) => {
        setDeleteDialogUserId(user.id);
        setDeleteDialogText(`Are you sure you want to delete the session for the user ${user.username}?`);
    };

    useEffect(() => {
        loadData(columnsState, skipItems, takeCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadData(columnsState, 0, 25);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAgentsFilter, usernamesFilter, ipsFilter, groupsFilter]);

    return <>
        <Box display="flex">
            <Typography variant="h1">SAML Active Sessions</Typography>
        </Box>

        <Stack direction={"row"} gap={1} marginBottom={"10px"} marginTop={"10px"}>
            <Stack direction={"row"} gap={2}>
                <FilterButton
                    name={"Username"}
                    open={showUsernameFilter}
                    onClick={() => {
                        setShowUsernameFilter(true);
                    }}
                    onMenuClose={() => setShowUsernameFilter(false)}
                    active={usernamesFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={usernamesFilter}
                        inputPlaceholder="Search Alert Name"
                        itemAdded={(x) => {
                            setUsernamesFilter(usernamesFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setUsernamesFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setUsernamesFilter(usernamesFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"IP Address"}
                    open={showIPFilter}
                    onClick={() => {
                        setShowIPFilter(true);
                    }}
                    onMenuClose={() => setShowIPFilter(false)}
                    active={ipsFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={ipsFilter}
                        inputPlaceholder="Search Alert Name"
                        itemAdded={(x) => {
                            setIpsFilter(ipsFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setIpsFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setIpsFilter(ipsFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"User Agent"}
                    open={showUserAgentFilter}
                    onClick={() => {
                        setShowUserAgentFilter(true);
                    }}
                    onMenuClose={() => setShowUserAgentFilter(false)}
                    active={userAgentsFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={userAgentsFilter}
                        inputPlaceholder="Search User Agent"
                        itemAdded={(x) => {
                            setUserAgentsFilter(userAgentsFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setUserAgentsFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setUserAgentsFilter(userAgentsFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"Groups"}
                    open={showGroupsFilter}
                    onClick={() => {
                        setShowGroupsFilter(true);
                    }}
                    onMenuClose={() => setShowGroupsFilter(false)}
                    active={groupsFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={groupsFilter}
                        inputPlaceholder="Search Groups"
                        itemAdded={(x) => {
                            setGroupsFilter(groupsFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setGroupsFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setGroupsFilter(groupsFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
            </Stack>
            <Stack flexGrow={1} direction={"row"} justifyContent={"end"}>
                <Button variant="outlined" onClick={() => {
                    setUsernamesFilter([]);
                    setUserAgentsFilter([]);
                    setIpsFilter([]);
                    setGroupsFilter([]);
                }}>Clear Filters</Button>
            </Stack>
        </Stack>
        <DataTable
            sx={{
                maxHeight: "90%",
            }}
            size="medium"
            columnsDataId={"icapUsersTablev2"}
            data={listState.users}
            dataCount={dataCount}
            finishedLoading={finishedLoading}
            paginationStartItem={skipItems}
            paginationItemCount={takeCount}
            onPaginationStartItemChanged={(x) => {
                loadData(columnsState, x, takeCount);
            }}
            onPaginationItemCountChanged={(x) => {
                loadData(columnsState, skipItems, x);
            }}
            onSortChanged={(x) => loadData(x, skipItems, takeCount)}
            onColumnOrderChanged={(x) => setColumnsState(x)}
            columnsState={columnsState}
            columns={{
                userName: {
                    displayName: "Username",
                    sortable: true,
                    render: (x) => {
                        return x.username;
                    },
                },
                ip: {
                    displayName: "Ip Address",
                    sortable: true,
                    render: (x) => {
                        return x.ip;
                    },
                },
                userAgent: {
                    displayName: "User Agent",
                    sortable: true,
                    render: (x) => {
                        return x.useragent;
                    },
                },
                groups: {
                    displayName: "Groups",
                    sortable: false,
                    render: (x) => {
                        return x.groups.join(", ");
                    },
                },
                lastTimeActive: {
                    displayName: "Last Time Active",
                    sortable: true,
                    render: (x) => {
                        return `${x.lastTimeActive.toLocaleDateString()} ${x.lastTimeActive.toLocaleTimeString()}`;
                    },
                },
                expireAt: {
                    displayName: "Expire At",
                    sortable: true,
                    render: (x) => {
                        return `${x.expireAt.toLocaleDateString()} ${x.expireAt.toLocaleTimeString()}`;
                    },
                }
            }}
            rowOptionsMenu={[
                {
                    icon: <DeleteIcon className="delete-button" fontSize="small" />,
                    text: "Delete Session",
                    onClick: (x) => {
                        deleteUserDialog(x);
                    }
                },
            ]}
        />

        <Dialog open={deleteDialogUserId !== ""}>
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialogUserId("")}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteUser}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}