import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNotification } from "providers/Notification";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { ListSelect } from "../../../../components/ListSelect";


interface AddUrlFilterProps {
    initialValue?: AddedUrlFilter
    filterTypeList: FilterTypeList
    onBackButtonClicked?: () => void
    //onExit is called when the user wants to go back without changing anything
    onCancel?: () => void
    onFilterAdded: (filter: AddedUrlFilter, reset: () => void) => void
    onEdit(edit: boolean): void
}

export type FilterTypeList = { [key: number]: string };

type Behavior = "Match" | "Bypass"
export interface AddedUrlFilter {
    index?: number
    type: number
    text: string
    behavior: Behavior
    contentType: Mimetypes[]
    htmlOptions?: AddedUrlFilterHTMLOpts
    detectContentType: boolean
}

interface AddedUrlFilterHTMLOpts {
    jsonSubmatchRules?: AddedUrlFilterHTMLJSONOpts
}

interface AddedUrlFilterHTMLJSONOpts {
    xpath: string[]
}

enum Mimetypes {
    JSON = "application/json",
    //PDF = "application/pdf",
    OfficeDocument = "application/vnd.openxmlformats-officedocument",
    XML = "application/xml",
    CSV = "text/csv",
    HTML = "text/html",
    PlainText = "text/plain",
}

export var MimetypeName: { [key in Mimetypes]: string } = {
    "application/json": "JSON",
    //"application/pdf": "PDF",
    "application/vnd.openxmlformats-officedocument": "MS Office Document",
    "application/xml": "XML",
    "text/csv": "CSV",
    "text/html": "HTML",
    "text/plain": "Plain Text"
};

export default function AddUrlFilter(props: AddUrlFilterProps) {
    const notification = useNotification();
    const [contentTypes, setContentTypes] = useState<string[]>([]);
    const [showAdvanced, setShowAdvanced] = useState(true);
    const [filterType, setFilterType] = useState("");
    const [filterText, setFilterText] = useState("");
    const [xpath, setXpath] = useState("");
    const [behavior, setBehavior] = useState<Behavior>("Match");
    const [validationError, setValidationError] = useState(false);
    const [detectContentType, setDetectContentType] = useState(false);
    const [editing, setEditing] = useState(false);


    const reset = () => {
        setContentTypes([]);
        setFilterType("");
        setFilterText("");
        setBehavior("Match");
        setXpath("");
        setValidationError(false);
        setDetectContentType(false);
        setEditing(false);
        setShowAdvanced(true);
        props.onEdit(false);
    };

    useEffect(() => {
        if (!props.initialValue) {
            reset();
            return;
        }

        const getXpathInitialVal = () => {
            const xpaths = props.initialValue?.htmlOptions?.jsonSubmatchRules?.xpath;
            if (!xpaths) {
                return "";
            }

            return xpaths.join("\n");
        };

        setContentTypes(props.initialValue.contentType);
        setFilterType(props.initialValue.type.toString());
        setFilterText(props.initialValue.text);
        setXpath(getXpathInitialVal());
        setDetectContentType(props.initialValue.detectContentType);
        setBehavior(props.initialValue.behavior);
        setEditing(true);
        setShowAdvanced(true);
        props.onEdit(true);
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const readXpath = () => {
        const list = xpath.split("\n");
        if (list.length === 1 && list[0] === "") {
            return undefined;
        }

        return {
            jsonSubmatchRules: {
                xpath: list
            }
        };
    };

    const save = () => {
        if (filterText === "" && filterType !== "8") {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "Filter text cannot be empty"
            });
            setValidationError(true);
            return;
        }
        if (filterType === "") {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "Filter type cannot be empty"
            });
            setValidationError(true);
            return;
        }

        props.onFilterAdded({
            index: props.initialValue?.index,
            type: parseInt(filterType),
            text: filterText,
            behavior: behavior,
            contentType: contentTypes as Mimetypes[],
            htmlOptions: readXpath(),
            detectContentType: detectContentType,
        }, reset);
    };

    return <Stack sx={{ flex: 1, flexGrow: 0, paddingTop: "10px" }}>
        {!editing ? <>
            <Button
                id="add-button"
                variant="outlined"
                size="medium"
                fullWidth
                onClick={() => {
                    reset();
                    setEditing(true);
                    props.onEdit(true);
                }}
            >
                Add
            </Button>
        </> : <>
            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <InputLabel id="filter-type-label">Type</InputLabel>
                <Select
                    labelId="filter-type-label"
                    id="filter-type-select"
                    name="type"
                    label="Type"
                    value={filterType}
                    onChange={x => setFilterType(x.target.value)}
                    error={validationError}
                >
                    {
                        Object.entries(props.filterTypeList)
                            .sort((a, b) => a[1].localeCompare(b[1]))
                            .map(([key, name]) => <MenuItem value={key}>{name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <TextField sx={{ marginTop: "20px" }} id="filter-text-input" name="text" label="Value" placeholder={filterType === "9" ? "e.g. 'namespace.appname' or 'module.namespace.appname'" : "e.g. 'domain.com' or 'hostname.another-domain.com'"} variant="outlined"
                value={filterText}
                onChange={x => setFilterText(x.target.value)}
                error={validationError}
            />

            <Typography variant="h2" sx={{ marginTop: "20px", display: "flex", alignItems: "center", cursor: "pointer", ":hover": { color: "#2158c3" } }}
                onClick={() => setShowAdvanced(!showAdvanced)} >
                {showAdvanced ? <ArrowDropUp /> : <ArrowDropDown />}<Typography component={"span"} sx={{ fontWeight: "bold", fontSize: "16px" }}>Advanced Settings</Typography>
            </Typography>
            {showAdvanced ? <>
                <FormControl component="fieldset" sx={{ marginTop: "20px" }} error={validationError}>
                    <FormLabel component="legend">Behavior</FormLabel>
                    <RadioGroup id="behavior-radio-group" value={behavior} name="behaviour" onChange={x => setBehavior(x.target.value as Behavior)} row >
                        <FormControlLabel id="behavior-radio-option-match" value="Match" control={<Radio />} label="Match" />
                        <FormControlLabel id="behavior-radio-option-bypass" value="Bypass" control={<Radio />} label="Bypass" />
                    </RadioGroup>
                </FormControl>

                <ListSelect
                    id="select-content-types"
                    label="Content Type"
                    value={contentTypes}
                    onDelete={(k) => {
                        setContentTypes(contentTypes.filter(x => x !== k));
                    }}
                    onAdd={(x) => {
                        setContentTypes([...contentTypes, x]);
                    }}
                    valueList={MimetypeName}
                />

                {contentTypes.find(x => x === Mimetypes.HTML) ?
                    <TextField
                        id="xpath-input"
                        label="HTML json elements XPath"
                        helperText="XPath for html elements containing JSON. Can have multiple xpath separated by lines."
                        multiline
                        rows={2}
                        variant="outlined"
                        sx={{ marginTop: "20px" }}
                        value={xpath}
                        error={validationError}
                        onChange={x => setXpath(x.target.value)}
                    />
                    : undefined}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={detectContentType}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setDetectContentType(event.target.checked);
                            }}
                        />
                    }
                    label="Run content type detection"
                />


            </> : null}
            <Box display="flex" marginBottom="15px">
                <Button
                    id="cancel-button"
                    variant="text"
                    size="medium"
                    fullWidth
                    onClick={() => {
                        reset();
                        props.onCancel && props.onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    id="save-button"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onClick={save}
                >
                    Save
                </Button>
            </Box>
        </>}

    </Stack>;
}