// Generated from searchExp.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class searchExpLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly RELATIVE_DATE_MINUTES = 22;
	public static readonly RELATIVE_DATE_HOURS = 23;
	public static readonly RELATIVE_DATE_DAYS = 24;
	public static readonly RELATIVE_DATE_WEEKS = 25;
	public static readonly VARIABLE_NAME = 26;
	public static readonly STRING = 27;
	public static readonly WS = 28;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", "T__8", 
		"T__9", "T__10", "T__11", "T__12", "T__13", "T__14", "T__15", "T__16", 
		"T__17", "T__18", "T__19", "T__20", "RELATIVE_DATE_MINUTES", "RELATIVE_DATE_HOURS", 
		"RELATIVE_DATE_DAYS", "RELATIVE_DATE_WEEKS", "VARIABLE_NAME", "STRING", 
		"WS",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'not'", "'('", "')'", "'and'", "'or'", "'in'", "'contains'", 
		"'=='", "'!='", "'<'", "'>'", "'>='", "'<='", "','", "'order by'", "'asc'", 
		"'desc'", "'true'", "'false'", "'''", "'-'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, "RELATIVE_DATE_MINUTES", "RELATIVE_DATE_HOURS", "RELATIVE_DATE_DAYS", 
		"RELATIVE_DATE_WEEKS", "VARIABLE_NAME", "STRING", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(searchExpLexer._LITERAL_NAMES, searchExpLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return searchExpLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(searchExpLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "searchExp.g4"; }

	// @Override
	public get ruleNames(): string[] { return searchExpLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return searchExpLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return searchExpLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return searchExpLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x1E\xBB\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x03\x02\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03" +
		"\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x06\x03" +
		"\x07\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03" +
		"\b\x03\t\x03\t\x03\t\x03\n\x03\n\x03\n\x03\v\x03\v\x03\f\x03\f\x03\r\x03" +
		"\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x10" +
		"\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03\x11" +
		"\x03\x11\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x13\x03\x13\x03\x13" +
		"\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15" +
		"\x03\x15\x03\x16\x03\x16\x03\x17\x06\x17\x8B\n\x17\r\x17\x0E\x17\x8C\x03" +
		"\x17\x03\x17\x03\x18\x06\x18\x92\n\x18\r\x18\x0E\x18\x93\x03\x18\x03\x18" +
		"\x03\x19\x06\x19\x99\n\x19\r\x19\x0E\x19\x9A\x03\x19\x03\x19\x03\x1A\x06" +
		"\x1A\xA0\n\x1A\r\x1A\x0E\x1A\xA1\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x07\x1B" +
		"\xA8\n\x1B\f\x1B\x0E\x1B\xAB\v\x1B\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x07" +
		"\x1C\xB1\n\x1C\f\x1C\x0E\x1C\xB4\v\x1C\x03\x1C\x03\x1C\x03\x1D\x03\x1D" +
		"\x03\x1D\x03\x1D\x02\x02\x02\x1E\x03\x02\x03\x05\x02\x04\x07\x02\x05\t" +
		"\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17" +
		"\x02\r\x19\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13" +
		"%\x02\x14\'\x02\x15)\x02\x16+\x02\x17-\x02\x18/\x02\x191\x02\x1A3\x02" +
		"\x1B5\x02\x1C7\x02\x1D9\x02\x1E\x03\x02\v\x03\x022;\x03\x02oo\x03\x02" +
		"jj\x03\x02ff\x03\x02yy\x05\x02C\\aac|\x06\x022;C\\aac|\x03\x02$$\x05\x02" +
		"\v\f\x0F\x0F\"\"\x02\xC1\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02" +
		"\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02" +
		"\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02" +
		"\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02" +
		"\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02" +
		"\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03" +
		"\x02\x02\x02\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02" +
		"\x02\x02-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x02" +
		"3\x03\x02\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02" +
		"\x02\x02\x03;\x03\x02\x02\x02\x05?\x03\x02\x02\x02\x07A\x03\x02\x02\x02" +
		"\tC\x03\x02\x02\x02\vG\x03\x02\x02\x02\rJ\x03\x02\x02\x02\x0FM\x03\x02" +
		"\x02\x02\x11V\x03\x02\x02\x02\x13Y\x03\x02\x02\x02\x15\\\x03\x02\x02\x02" +
		"\x17^\x03\x02\x02\x02\x19`\x03\x02\x02\x02\x1Bc\x03\x02\x02\x02\x1Df\x03" +
		"\x02\x02\x02\x1Fh\x03\x02\x02\x02!q\x03\x02\x02\x02#u\x03\x02\x02\x02" +
		"%z\x03\x02\x02\x02\'\x7F\x03\x02\x02\x02)\x85\x03\x02\x02\x02+\x87\x03" +
		"\x02\x02\x02-\x8A\x03\x02\x02\x02/\x91\x03\x02\x02\x021\x98\x03\x02\x02" +
		"\x023\x9F\x03\x02\x02\x025\xA5\x03\x02\x02\x027\xAC\x03\x02\x02\x029\xB7" +
		"\x03\x02\x02\x02;<\x07p\x02\x02<=\x07q\x02\x02=>\x07v\x02\x02>\x04\x03" +
		"\x02\x02\x02?@\x07*\x02\x02@\x06\x03\x02\x02\x02AB\x07+\x02\x02B\b\x03" +
		"\x02\x02\x02CD\x07c\x02\x02DE\x07p\x02\x02EF\x07f\x02\x02F\n\x03\x02\x02" +
		"\x02GH\x07q\x02\x02HI\x07t\x02\x02I\f\x03\x02\x02\x02JK\x07k\x02\x02K" +
		"L\x07p\x02\x02L\x0E\x03\x02\x02\x02MN\x07e\x02\x02NO\x07q\x02\x02OP\x07" +
		"p\x02\x02PQ\x07v\x02\x02QR\x07c\x02\x02RS\x07k\x02\x02ST\x07p\x02\x02" +
		"TU\x07u\x02\x02U\x10\x03\x02\x02\x02VW\x07?\x02\x02WX\x07?\x02\x02X\x12" +
		"\x03\x02\x02\x02YZ\x07#\x02\x02Z[\x07?\x02\x02[\x14\x03\x02\x02\x02\\" +
		"]\x07>\x02\x02]\x16\x03\x02\x02\x02^_\x07@\x02\x02_\x18\x03\x02\x02\x02" +
		"`a\x07@\x02\x02ab\x07?\x02\x02b\x1A\x03\x02\x02\x02cd\x07>\x02\x02de\x07" +
		"?\x02\x02e\x1C\x03\x02\x02\x02fg\x07.\x02\x02g\x1E\x03\x02\x02\x02hi\x07" +
		"q\x02\x02ij\x07t\x02\x02jk\x07f\x02\x02kl\x07g\x02\x02lm\x07t\x02\x02" +
		"mn\x07\"\x02\x02no\x07d\x02\x02op\x07{\x02\x02p \x03\x02\x02\x02qr\x07" +
		"c\x02\x02rs\x07u\x02\x02st\x07e\x02\x02t\"\x03\x02\x02\x02uv\x07f\x02" +
		"\x02vw\x07g\x02\x02wx\x07u\x02\x02xy\x07e\x02\x02y$\x03\x02\x02\x02z{" +
		"\x07v\x02\x02{|\x07t\x02\x02|}\x07w\x02\x02}~\x07g\x02\x02~&\x03\x02\x02" +
		"\x02\x7F\x80\x07h\x02\x02\x80\x81\x07c\x02\x02\x81\x82\x07n\x02\x02\x82" +
		"\x83\x07u\x02\x02\x83\x84\x07g\x02\x02\x84(\x03\x02\x02\x02\x85\x86\x07" +
		")\x02\x02\x86*\x03\x02\x02\x02\x87\x88\x07/\x02\x02\x88,\x03\x02\x02\x02" +
		"\x89\x8B\t\x02\x02\x02\x8A\x89\x03\x02\x02\x02\x8B\x8C\x03\x02\x02\x02" +
		"\x8C\x8A\x03\x02\x02\x02\x8C\x8D\x03\x02\x02\x02\x8D\x8E\x03\x02\x02\x02" +
		"\x8E\x8F\t\x03\x02\x02\x8F.\x03\x02\x02\x02\x90\x92\t\x02\x02\x02\x91" +
		"\x90\x03\x02\x02\x02\x92\x93\x03\x02\x02\x02\x93\x91\x03\x02\x02\x02\x93" +
		"\x94\x03\x02\x02\x02\x94\x95\x03\x02\x02\x02\x95\x96\t\x04\x02\x02\x96" +
		"0\x03\x02\x02\x02\x97\x99\t\x02\x02\x02\x98\x97\x03\x02\x02\x02\x99\x9A" +
		"\x03\x02\x02\x02\x9A\x98\x03\x02\x02\x02\x9A\x9B\x03\x02\x02\x02\x9B\x9C" +
		"\x03\x02\x02\x02\x9C\x9D\t\x05\x02\x02\x9D2\x03\x02\x02\x02\x9E\xA0\t" +
		"\x02\x02\x02\x9F\x9E\x03\x02\x02\x02\xA0\xA1\x03\x02\x02\x02\xA1\x9F\x03" +
		"\x02\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA3\x03\x02\x02\x02\xA3\xA4\t" +
		"\x06\x02\x02\xA44\x03\x02\x02\x02\xA5\xA9\t\x07\x02\x02\xA6\xA8\t\b\x02" +
		"\x02\xA7\xA6\x03\x02\x02\x02\xA8\xAB\x03\x02\x02\x02\xA9\xA7\x03\x02\x02" +
		"\x02\xA9\xAA\x03\x02\x02\x02\xAA6\x03\x02\x02\x02\xAB\xA9\x03\x02\x02" +
		"\x02\xAC\xB2\x07$\x02\x02\xAD\xB1\n\t\x02\x02\xAE\xAF\x07^\x02\x02\xAF" +
		"\xB1\x07$\x02\x02\xB0\xAD\x03\x02\x02\x02\xB0\xAE\x03\x02\x02\x02\xB1" +
		"\xB4\x03\x02\x02\x02\xB2\xB0\x03\x02\x02\x02\xB2\xB3\x03\x02\x02\x02\xB3" +
		"\xB5\x03\x02\x02\x02\xB4\xB2\x03\x02\x02\x02\xB5\xB6\x07$\x02\x02\xB6" +
		"8\x03\x02\x02\x02\xB7\xB8\t\n\x02\x02\xB8\xB9\x03\x02\x02\x02\xB9\xBA" +
		"\b\x1D\x02\x02\xBA:\x03\x02\x02\x02\n\x02\x8C\x93\x9A\xA1\xA9\xB0\xB2" +
		"\x03\x02\x03\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!searchExpLexer.__ATN) {
			searchExpLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(searchExpLexer._serializedATN));
		}

		return searchExpLexer.__ATN;
	}

}

