import { ButtonBase, Badge, Collapse, Typography } from "@mui/material";
import { AuthContextData, useLogin } from "providers/Login";

export interface MenuButtonProps {
    icon?: React.ReactChild
    minimal?: boolean
    text: string
    selected?: boolean
    indent?: boolean
    badge?: (ctx: AuthContextData) => boolean

    onClick?: () => void
    children?: React.ReactChild[] | React.ReactChild
}

export function MenuButton(props: MenuButtonProps) {
    const l = useLogin();

    return <ButtonBase sx={{
        alignItems: "center",
        justifyContent: "start",
        padding: "5px",
        borderRadius: "6px",
        height: "50px",
        color: props.selected ? "primary.main" : "text.secondary",
        "&:hover": {
            backgroundColor: "aliceblue"
        },
        paddingLeft: (props.minimal === false) && (props.indent) ? "20px" : "5px",
        transition: "padding-left 0.05s linear",
    }} onClick={props.onClick}>
        <Badge invisible={!(props.badge && props.badge(l))} color="error" variant="dot">
            {props.icon ? props.icon : undefined}
            <Collapse sx={{ flex: 1, paddingRight: "5px"}} in={!props.minimal ? true : false} orientation={"horizontal"} >
                <Typography sx={{
                    color: props.selected ? "primary.main" : "text.secondary",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: props.selected ? "700" : "400",
                    flex: 1,
                    textAlign: "left",
                }}>
                    {props.text}
                </Typography>
            </Collapse>
        </Badge>
        {!props.minimal ? props.children : false}
        
    </ButtonBase>;
}

export default MenuButton;