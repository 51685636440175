import { Alert, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Popover, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { Box, Stack, SxProps, Theme } from "@mui/system";
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { AuthContextData, useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useLocation, useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import DataTable, { Column, moveItem, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";
import CircleIcon from "@mui/icons-material/Circle";
import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import countries from "i18n-iso-countries";
import clientDevices from "../../../../constant/clientDevices";
import contentTypes from "../../../../constant/contentTypes";
import countriesEn from "i18n-iso-countries/langs/en.json";
import "flatpickr/dist/themes/airbnb.css";
import { FilterBar, FilterFieldData, FilterItem, SimpleFilterState } from "./filterbar";
import MenuIcon from "@mui/icons-material/Menu";
import { ChipList } from "../../../../components/ChipList";

countries.registerLocale(countriesEn);

interface ActivityListState {
    count: number
    items: ActivityListStateItem[]
}

interface ActivityListStateItem {
    id: string
    url: string
    icapMode: string
    contentType: string
    timestamp: Date
    clientIPAddress: string
    clientIPLocation: string
    clientUserAgent: string
    clientDevice: string
    originalValueStored: boolean
    obfuscated: boolean
    detected: boolean
    scanned: boolean
    username: string
    userGroup: string
    instanceIdentifier: string
    apps: appInfo[]
    datatypesDetected: datatypeCount[]
    datatypesObfuscated: datatypeCount[]
    rules: ruleInfo[]
}

interface appInfo {
    id: string
    name: string
    deleted: boolean
}

interface ruleInfo {
    id: string
    name: string
    deleted: boolean
}

interface datatypeCount {
    id: string
    name: string
    count: number
    values: string[]
}

type columnsType = "timestamp" | "icapMode" | "instanceId" | "username" | "userGroup" | "apps_names" |
    "hostname" | "contentType" | "ip" | "device" | "rules_names" | "ip_location" |
    "datatypes_detected_names" | "datatypes_obfuscated_names" | "scanned" | "detected" | "obfuscated" | "originalValueStored" | "originalValues";


type SortDirection = "asc" | "desc";
type SortField = "contentType" | "device" | "scanned" | "detected" | "obfuscated" | "hostname" | "timestamp" | "instanceId" | "ip" | "username" | "userGroup";

interface FilterBarSortData {
    field: SortField
    order: SortDirection
}

const defaultSimpleQueryState: SimpleFilterState = {
    apps: [],
    clientDevices: [],
    contentTypes: [],
    originalValueStored: [],
    types: [],
    originalValues: [],
    detected: [],
    detectedDatatypes: [],
    hostnames: [],
    instancesIds: [],
    obfuscated: [],
    obfuscatedDatatypes: [],
    usernames: [],
    userGroups: [],
    rules: [],
    scanned: [],
    clientIpAddresses: [],
    location: [],
};

export default function Activites() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const location = useLocation();

    const [finishedLoading, setFinishedLoading] = useState(false);
    const [activityLogging, setActivityLogging] = useState(true);


    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);

    const [lastUpdated, setLastUpdated] = useState(new Date());

    const [filterData, setFilterData] = useState<FilterFieldData>();
    const [lastError, setLastError] = useState("");
    const [query, setQuery] = useState("");

    const [selectedOriginalValueDataType, setSelectedOriginalValueDataType] = useState("");

    const [tableDensity, setTableDensity] = useState("small" as "medium" | "small");

    const columnsDataId = "dataGrid_activityTable_columns";

    const defaultColumns: Column<columnsType>[] = [
        { columnId: "timestamp", width: "auto", direction: "desc", sortOrder: 1 },
        { columnId: "icapMode", width: "auto" },
        { columnId: "apps_names", width: "auto" },
        { columnId: "hostname", width: "auto" },
        { columnId: "rules_names", width: "auto" },
        { columnId: "username", width: "auto" },
        { columnId: "userGroup", width: "auto" },
        { columnId: "datatypes_detected_names", width: "auto" },
        { columnId: "datatypes_obfuscated_names", width: "auto" },
        { columnId: "scanned", width: "auto" },
        { columnId: "detected", width: "auto" },
        { columnId: "obfuscated", width: "auto" },
    ];

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>(defaultColumns);

    const [listState, setListState] = useState({ items: [], count: 0 } as ActivityListState);

    const [exportCsvLoading, setExportCsvLoading] = useState(false);

    const defaultFilter = {
        apps: [],
        clientDevices: [],
        clientIpAddresses: [],
        contentTypes: [],
        originalValueStored: [],
        originalValues: [],
        detected: [],
        types: [],
        detectedDatatypes: [],
        hostnames: [],
        instancesIds: [],
        obfuscated: [],
        obfuscatedDatatypes: [],
        rules: [],
        scanned: [],
        usernames: [],
        userGroups: [],
        location: [],
    };

    const [simpleQueryState, setSimpleQueryState] = useState<SimpleFilterState>(defaultFilter);
    const [queryBarLoading, setQueryBarLoading] = useState(false);

    const req = login.GetAxios();

    const loadData = async (colState: typeof columnsState, query: string, itensPerPage: number, skip: number) => {
        setFinishedLoading(false);
        setLastUpdated(new Date());
        setLastError("");

        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const config = await req.get("/api/config");
            setActivityLogging(config.data.activity.enable);

            if (config.data.activity.enable) {
                const resp = await req.get("/api/activities", {
                    params: {
                        take: itensPerPage,
                        skip: skip,
                        search: query,
                    }
                });

                setListState({
                    count: resp.data.count,
                    items: (resp.data.items || []).map((x: any) => {
                        const item: ActivityListStateItem = {
                            ...x,
                            timestamp: new Date(x.timestamp * 1000),
                        };
                        return item;
                    })
                });

                const sortBy = resp.data.orderBy as FilterBarSortData[];
                const newState = colState.map(x => {
                    const idx = sortBy.findIndex(y => y.field === x.columnId);
                    if (idx === -1) {
                        return {
                            ...x,
                            sortOrder: undefined,
                            direction: undefined,
                        };
                    }

                    return {
                        ...x,
                        sortOrder: idx + 1,
                        direction: sortBy[idx].order
                    };
                });

                //if the column we are sorting by is not present, we must add it
                sortBy.forEach((x, idx) => {
                    const found = newState.find(y => y.columnId === x.field);
                    if (!found) {
                        newState.push({
                            columnId: x.field,
                            sortOrder: idx + 1,
                            direction: x.order,
                        });
                    }
                });


                setColumnsState(newState);
            }

            setFilterData(await FetchFilterFieldData(login));

        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                } else {
                    setLastError(body.message);
                }
            }
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
            setListState({
                count: 0,
                items: [],
            });
        }
        setFinishedLoading(true);
    };


    const firstLoad = async () => {
        const col = RestoreColumnsState("activity", defaultColumns);
        const advancedQuery = (new URLSearchParams(location.search)).get("query");
        const response = advancedQuery || await getQueryFromSimple(defaultSimpleQueryState, col);
        await loadData(col, response, takeCount, skipItems);
        setQuery(response);

        if (response) {
            await setSimpleQueryFromAdvanced(response);
        }
    };

    useEffect(() => {
        firstLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        SaveColumnsState("activity", columnsState);
    }, [columnsState]);


    const downloadCSV = async () => {
        if (!req) {
            notification.Display({
                title: "Error",
                message: "not logged in",
                type: "error",
            });
            return;
        }

        try {
            setExportCsvLoading(true);
            const res = await req.get("/api/activities/csv", {
                responseType: "arraybuffer",
                params: {
                    search: query,
                    originalValueField: selectedOriginalValueDataType
                },
            });

            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([res.data]),
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "activities.zip",
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

        } catch (err) {
            console.error(err);
            notification.Display({
                title: "Error",
                message: "could not generate csv",
                type: "error",
            });
        } finally {
            setExportCsvLoading(false);
        }
    };

    const getFilterSorting = (c: typeof columnsState) => {
        return c.filter(x => x.sortOrder !== undefined).sort((a, b) => a.sortOrder! - b.sortOrder!).map(x => {
            return {
                field: x.columnId,
                order: x.direction!,
            } as FilterBarSortData;
        });
    };

    const getQueryFromSimple = async (x: SimpleFilterState, sort: typeof columnsState) => {
        const r = login.GetAxios();
        if (!r) {
            return;
        }


        const conversion = await r.post("/api/activities/convertsearch", {
            simpleToAdvanced: {
                ...x,
                orderBy: getFilterSorting(sort),
            }
        });

        return conversion.data.simpleToAdvanced || "";
    };

    const getOriginalValues = (detectedDataTypes: datatypeCount[]): string[] => {
        let originalValues: string[] = [];

        if (!selectedOriginalValueDataType) 
            return originalValues;
        
        detectedDataTypes.filter(x => x.id === selectedOriginalValueDataType).forEach(x => originalValues.push(...x.values));

        return originalValues;
    };

    const setSimpleQueryFromAdvanced = async (advancedQuery: string) => {
        if (!req) {
            return false;
        }
        try {
            setQueryBarLoading(true);
            const conversion = await req.post("/api/activities/convertsearch", {
                advancedToSimple: advancedQuery,
            });

            const newState = conversion.data as SimpleFilterState;
            setSimpleQueryState(newState);

            return true;
        } catch (err) {
            console.error(err);
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                notification.Display({
                    title: "Error",
                    message: body.message.charAt(0).toUpperCase() + body.message.slice(1),
                    type: "error",
                });
            } else {
                notification.Display({
                    title: "Error",
                    message: "Could not fetch data",
                    type: "error",
                });
            }
            setSimpleQueryState(defaultSimpleQueryState);
        } finally {
            setQueryBarLoading(false);
        }

        return false;
    };

    return <>
        <Box id="activity-page" display="flex" justifyContent="space-between">
            <Box >
                <Typography variant="h1" display="block">Data Log</Typography>
                <Typography variant="body2" color="text.secondary">
                    Last updated at {lastUpdated.toLocaleDateString()} {lastUpdated.toLocaleTimeString()} <Button size="small" variant="text" onClick={() => loadData(columnsState, query, takeCount, skipItems)}><Refresh sx={{ fontSize: "12px", marginRight: "2px" }} />refresh</Button>
                </Typography>
            </Box>
            <Box>
                <Box display="flex" alignItems="center" alignContent="right">
                    <LoadingButton
                        id="export-button"
                        loading={exportCsvLoading}
                        variant="outlined"
                        sx={{ marginLeft: "auto" }}
                        onClick={downloadCSV}
                    >
                        Export
                    </LoadingButton>
                </Box>

            </Box>

        </Box>
        <Box display="flex" marginTop="10px" justifyContent="space-between" alignItems="start">
            {filterData &&
                <FilterBar
                    query={query}
                    loading={queryBarLoading}
                    simpleFilterState={simpleQueryState}
                    data={filterData}
                    onSimpleQueryChange={async (x) => {
                        const response = await getQueryFromSimple(x, columnsState);
                        setQuery(response);
                        setSkipItems(0);
                        loadData(columnsState, response, takeCount, 0);
                        setSimpleQueryState(x);
                    }}
                    onAdvancedQueryChange={(x) => { setQuery(x); }}
                    onAdvancedQuerySubmit={() => {
                        setSkipItems(0);
                        loadData(columnsState, query, takeCount, 0);
                        setSimpleQueryFromAdvanced(query);
                    }}
                    onAdvancedToSimpleChanged={() => setSimpleQueryFromAdvanced(query)}
                    onClearFilter={() => {
                        setQuery("");
                        setSkipItems(0);
                        setSimpleQueryState(defaultFilter);
                        loadData(columnsState, "", takeCount, 0);
                    }}
                />}
            <ColumnSelector
                onSave={(x, density) => {
                    setColumnsState(x);
                    setTableDensity(density);
                }}
                onReset={() => {
                    setTableDensity("small");
                    loadData(defaultColumns, query, takeCount, skipItems);
                }}
                tableDensity={tableDensity}
                currentState={columnsState}
                columns={[
                    { columnId: "icapMode", displayName: "Type" },
                    { columnId: "timestamp", displayName: "Timestamp" },
                    { columnId: "instanceId", displayName: "Shield Instance" },
                    { columnId: "username", displayName: "Username" },
                    { columnId: "userGroup", displayName: "User Group" },
                    { columnId: "apps_names", displayName: "Apps" },
                    { columnId: "hostname", displayName: "Hostname" },
                    { columnId: "contentType", displayName: "Content Type" },
                    { columnId: "ip", displayName: "Client IP Address" },
                    { columnId: "ip_location", displayName: "Client IP Location" },
                    { columnId: "device", displayName: "Client Device" },
                    { columnId: "rules_names", displayName: "Rule" },
                    { columnId: "datatypes_detected_names", displayName: "Detected Data Types" },
                    { columnId: "datatypes_obfuscated_names", displayName: "Obfuscated Data types" },
                    { columnId: "originalValues", displayName: "Data Values" },
                    { columnId: "scanned", displayName: "Scanned" },
                    { columnId: "detected", displayName: "Detected" },
                    { columnId: "obfuscated", displayName: "Obfuscated" },
                    { columnId: "originalValueStored", displayName: "Values Stored" },
                ]}
            />


        </Box>

        {
            !activityLogging && <Alert severity="warning" sx={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}>Activity logging is disabled</Alert>
        }
        {
            lastError && <Alert severity="error" sx={{ width: "100%", marginBottom: "10px", marginTop: "10px", whiteSpace: "pre-line" }}>{lastError}</Alert>
        }


        <DataTable
            sx={{
                maxHeight: "90%",
            }}
            tableSx={{
                width: "max-content"
            }}
            size={tableDensity}
            dataCount={listState.count}
            paginationStartItem={skipItems}
            paginationItemCount={takeCount}
            onPaginationStartItemChanged={(x) => {
                setSkipItems(x);
                setTakeCount(takeCount);
                loadData(columnsState, query, takeCount, x);
            }}
            onPaginationItemCountChanged={(x) => {
                setTakeCount(Number(x));
                loadData(columnsState, query, Number(x), skipItems);
            }}
            data={listState.items}
            columnsState={columnsState}
            finishedLoading={finishedLoading}
            onSortChanged={async (c) => {
                const r = login.GetAxios();
                if (!r) {
                    return;
                }

                const conversion = await r.post("/api/activities/convertsearch", {
                    modifyAdvanced: {
                        query: query,
                        orderBy: getFilterSorting(c),
                    }
                });

                const response = conversion.data.modifyAdvanced || "";

                loadData(columnsState, response, takeCount, skipItems);
                setQuery(response);
            }}
            onColumnOrderChanged={(x) => setColumnsState(x)}
            columnsDataId={columnsDataId}
            columns={{
                icapMode: {
                    displayName: "Type",
                    sortable: true,
                    render: (x) => {
                        switch (x.icapMode) {
                        case "REQMOD":
                            return "Icap Request";
                        case "RESPMOD":
                            return "Icap Response";
                        case "API":
                            return "API";
                        }

                        return "";
                    }
                },
                timestamp: {
                    displayName: "Timestamp",
                    sortable: true,
                    render: (x) => {
                        return <Typography fontSize="small">{`${x.timestamp.toLocaleDateString()} ${x.timestamp.toLocaleTimeString()}`}</Typography>;
                    },
                },
                instanceId: {
                    displayName: "Shield Instance",
                    sortable: true,
                    render: (x) => {
                        return x.instanceIdentifier;
                    }
                },
                username: {
                    displayName: "Username",
                    sortable: true,
                    render: (x) => {
                        return x.username;
                    }
                },
                userGroup: {
                    displayName: "User Group",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            {
                                x.userGroup.split(",").filter(x => x !== "").map((x, i) => <Chip key={i} label={x.trim().replaceAll(/@.*/g, "")} variant="outlined" size="small" color="primary" />)
                            }
                        </Box>;
                    }
                },
                apps_names: {
                    displayName: "Apps",
                    maxWidth: "250px",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>

                            {x.apps.length > 0
                                ? (x.apps.map(y => <Chip key={y.id} label={y.name.trim()} variant="outlined" size="small" color={y.id.trim() === "no match" ? "error" : "primary"} sx={{ textDecoration: y.deleted ? "line-through" : undefined }} />))
                                : <Chip label="(Unknown)" variant="outlined" size="small" color="error" />
                            }

                        </Box>;
                    },
                },
                hostname: {
                    displayName: "Origin (hover for detail)",
                    sortable: true,
                    render: (x) => {
                        let data = "";
                        try {
                            data = (new URL(x.url)).hostname;
                        } catch {
                            data = x.url;
                        }

                        return <Typography fontSize="small" title={x.url} maxWidth={"360px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow="ellipsis">{data}</Typography>;
                    },
                },
                contentType: {
                    displayName: "Content Type",
                    sortable: true,
                    render: (x) => {
                        return x.contentType;
                    },
                },
                ip: {
                    displayName: "Client IP Address",
                    sortable: true,
                    render: (x) => {
                        return x.clientIPAddress;
                    },
                },
                ip_location: {
                    displayName: "Client IP Location",
                    render: (x) => {
                        if (x.clientIPLocation !== "Unknown") {
                            const a = countries.getName(x.clientIPLocation, "en");
                            return a;
                        }
                        return x.clientIPLocation;
                    },
                },
                device: {
                    displayName: "Client Device",
                    sortable: true,
                    render: (x) => {
                        return x.clientDevice;
                    },
                },
                rules_names: {
                    displayName: "Rule",
                    maxWidth: "250px",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            {
                                x.rules.length > 0
                                    ? (x.rules.map(y => <Chip key={y.id} label={y.name.trim()} variant="outlined" size="small" color={y.id === "no match" ? "error" : "primary"} sx={{ textDecoration: y.deleted ? "line-through" : undefined }} />))
                                    : <Chip label="(None)" variant="outlined" size="small" color="error" />
                            }
                        </Box>;
                    },
                },
                originalValues: {
                    displayName: "Data Values",
                    renderSubHeader: () => {
                        return <FormControl sx={{ marginTop: "10px" }} variant="outlined" fullWidth size="small">
                            <InputLabel id="data-type-label">Data Type *</InputLabel>
                            <Select
                                labelId="data-type-label"
                                id="data-type-select"
                                label="Data Type *"
                                autoWidth={true}
                                value={selectedOriginalValueDataType}
                                onChange={x => setSelectedOriginalValueDataType(x.target.value)}
                            >
                                {filterData?.detectedDataType.map(x => <MenuItem key={x.id} value={x.id}>{x.label}</MenuItem>)}
                            </Select>
                        </FormControl>;
                    },
                    maxWidth: "250px",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px"
                        }}>
                            <ChipList items={getOriginalValues(x.datatypesDetected)} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="primary" />} />
                        </Box>;
                    },
                },
                datatypes_detected_names: {
                    displayName: "Detected Data Types",
                    maxWidth: "250px",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            <ChipList items={x.datatypesDetected} renderItem={(y) => <DataTypeChip id={y.id} name={y.name} count={y.count} values={y.values} key={y.id} />} />
                        </Box>;
                    },
                },
                datatypes_obfuscated_names: {
                    displayName: "Obfuscated Data Types",
                    maxWidth: "250px",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px",
                        }}>
                            <ChipList items={x.datatypesObfuscated} renderItem={(y) => <DataTypeChip id={y.id} name={y.name} count={y.count} values={y.values} key={y.id} />} />
                        </Box>;
                    },
                },
                scanned: {
                    displayName: "Scanned",
                    sortable: true,
                    render: (x) => {
                        return x.scanned
                            ? <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Yes</span>
                            </Box>
                            : <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>No</span>
                            </Box>;
                    },
                },
                originalValueStored: {
                    displayName: "Values Stored",
                    sortable: true,
                    render: (x) => {
                        return x.originalValueStored
                            ? <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Yes</span>
                            </Box>
                            : <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>No</span>
                            </Box>;
                    },
                },
                detected: {
                    displayName: "Detected",
                    sortable: true,
                    render: (x) => {
                        return x.detected
                            ? <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Yes</span>
                            </Box>
                            : <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>No</span>
                            </Box>;
                    },
                },
                obfuscated: {
                    displayName: "Obfuscated",
                    sortable: true,
                    render: (x) => {
                        return x.obfuscated
                            ? <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="success" /><span>Yes</span>
                            </Box>
                            : <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <CircleIcon sx={{ transform: "scale(0.6)" }} fontSize="small" color="error" /><span>No</span>
                            </Box>;
                    },
                },
            }}
        />
    </>;
}

function DataTypeChip(props: datatypeCount) {
    const fontWeight = props.values && props.values.some(x => x.length > 0) ? "bold" : "normal";
    return <Chip label={`${props.name.trim()} (${props.count})`} variant="outlined" size="small" sx={{ fontWeight }} color="primary" />;
}


interface ColumnSelectorProps<K extends string> {
    tableDensity: "medium" | "small"
    columns: ColumnSelectorDef<K>[]
    currentState: Column<K>[]
    onSave: (s: Column<K>[], tableDensity: "medium" | "small") => void
    onReset: () => void
}

interface ColumnSelectorDef<K> {
    columnId: K
    displayName: string
}

interface ColumnSelectorItemState<K> extends Column<K> {
    enabled: boolean
}

interface ElementBeingDraggedState {
    displayState: string
    checked: boolean
    initialPos: { x: number, y: number, w: number, h: number }
    pivot: { x: number, y: number }
    destinationRow: number
}

function ColumnSelector<K extends string>(props: ColumnSelectorProps<K>) {
    const [open, setOpen] = useState(false);
    const [currentColumnState, setCurrentColumnState] = useState<ColumnSelectorItemState<K>[]>([]);
    const [elementBeingDragged, setElementBeingDragged] = useState<ElementBeingDraggedState>();
    const [tableDensity, setTableDensity] = useState(props.tableDensity || "medium");

    const buttonRef = useRef();
    const listbodyRef = useRef<any>();
    const dragElementRef = useRef<any>();


    const updateState = () => {
        setCurrentColumnState(
            props.currentState.map(x => {
                return {
                    ...x,
                    enabled: true,
                };
            }).concat(
                props.columns.filter(x => {
                    return props.currentState.find(y => y.columnId === x.columnId) === undefined;
                }).map(x => {
                    return {
                        ...x,
                        enabled: false,
                    };
                })
            )
        );
    };

    const clicked = () => {
        if (!open) {
            updateState(); //unecessary?
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    const save = () => {
        props.onSave(currentColumnState.filter(x => x.enabled === true), tableDensity);
        setOpen(false);
    };

    useLayoutEffect(() => {
        const f = function (e: any) {
            //update fake row position
            if (!elementBeingDragged) {
                return;
            }
            if (!dragElementRef.current) {
                return;
            }
            dragElementRef.current.style.left = `${e.x - elementBeingDragged.pivot.x}px`;
            dragElementRef.current.style.top = `${e.y - elementBeingDragged.pivot.y}px`;
            dragElementRef.current.style.width = `${elementBeingDragged.initialPos.w}px`;
            dragElementRef.current.style.height = `${elementBeingDragged.initialPos.h}px`;


            const rowBoundingBox = (Array.from(listbodyRef.current?.childNodes) as Element[]).map(x => x.getBoundingClientRect());

            const elementOriginalIndex = elementBeingDragged.destinationRow;
            const currentPos = rowBoundingBox[elementOriginalIndex];

            let currentIndex = elementOriginalIndex;

            if (e.y < currentPos.top) {
                //going up
                while (currentIndex > 0) {
                    const otherPos = rowBoundingBox[currentIndex - 1];
                    const forbiddenArea = Math.max(otherPos.height - currentPos.height, 0);
                    if (e.y > (otherPos.bottom - forbiddenArea)) {
                        break;
                    }
                    currentIndex--;
                }
            } else {
                //going down
                while (currentIndex < (rowBoundingBox.length - 1)) {
                    const otherPos = rowBoundingBox[currentIndex + 1];
                    const forbiddenArea = Math.max(otherPos.height - currentPos.height, 0);
                    if (e.y < (otherPos.top + forbiddenArea)) {
                        break;
                    }
                    currentIndex++;
                }
            }

            if (elementOriginalIndex !== currentIndex) {
                const newColumns = currentColumnState.map(x => {
                    return { ...x } as ColumnSelectorItemState<K>;
                });//do a deep copy of the state array

                moveItem(newColumns, elementOriginalIndex, currentIndex);

                setCurrentColumnState(newColumns);
                setElementBeingDragged({
                    ...elementBeingDragged,
                    destinationRow: currentIndex,
                });
            }
        };

        document.addEventListener("mousemove", f);
        const mouseUpHandler = () => {
            setElementBeingDragged(undefined);
        };
        document.addEventListener("mouseup", mouseUpHandler);
        return () => {
            document.removeEventListener("mouseup", mouseUpHandler);
            document.removeEventListener("mousemove", f);
        };

    }, [elementBeingDragged, currentColumnState]);

    return <>
        <Button sx={{ marginLeft: "auto" }} ref={buttonRef as any} variant="outlined" onClick={clicked}>View</Button>
        <Popover
            open={open}
            anchorEl={buttonRef.current}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            onClose={() => setOpen(false)}
        >
            <Box sx={{ padding: "10px" }}>
                <FormControl>
                    <FormLabel id="row-radio-buttons-group-label">Table Dentisy</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tableDensity}
                        onChange={(e) => setTableDensity(e.target.value as "medium" | "small")}
                    >
                        <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                        <FormControlLabel value="small" control={<Radio />} label="Compact" />
                    </RadioGroup>
                </FormControl>
                <Typography>Column Settings</Typography>
                <FormGroup ref={listbodyRef as any}>
                    {currentColumnState.map((x, idx) => {
                        const displayName = props.columns.find(y => y.columnId === x.columnId)?.displayName || "";

                        return <ColumnSelectorElement
                            key={idx}
                            dragging={elementBeingDragged?.destinationRow === idx}
                            label={displayName}
                            checked={x.enabled}
                            disabled={x.sortOrder !== undefined}
                            onChange={() => {
                                setCurrentColumnState(currentColumnState.map(y => {
                                    if (y.columnId === x.columnId) {
                                        return {
                                            ...y,
                                            enabled: !y.enabled,
                                        };
                                    }
                                    return y;
                                }));
                            }}
                            onDrag={(pageX, pageY) => {
                                if (elementBeingDragged) {
                                    return;
                                }

                                const rowElement = (listbodyRef.current!.childNodes[idx] as Element);
                                const rowArea = rowElement.getBoundingClientRect();
                                setElementBeingDragged({
                                    checked: x.enabled,
                                    displayState: displayName,
                                    destinationRow: idx,
                                    initialPos: {
                                        x: rowArea.left,
                                        y: rowArea.top,
                                        w: rowArea.width,
                                        h: rowArea.height,
                                    },
                                    pivot: {
                                        x: pageX - rowArea.left,
                                        y: pageY - rowArea.top,
                                    }

                                });
                            }}
                        />;
                    })}
                </FormGroup>
                <Stack sx={{ marginTop: "10px" }} direction="row">
                    <Button sx={{ marginLeft: "auto" }} variant="outlined" onClick={() => { props.onReset(); setOpen(false); }}>Reset</Button>
                    <Button sx={{ marginLeft: "5px" }} variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button sx={{ marginLeft: "5px" }} variant="contained" onClick={save}>Apply</Button>
                </Stack>
            </Box>
        </Popover>
        {elementBeingDragged &&
            <ColumnSelectorElement
                sx={{
                    left: elementBeingDragged.initialPos.x,
                    top: elementBeingDragged.initialPos.y,
                    width: elementBeingDragged.initialPos.w,
                    height: elementBeingDragged.initialPos.h,
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05)",
                    position: "absolute",
                    zIndex: 9999999999,
                    backgroundColor: "#fff"
                }}
                ref={dragElementRef}
                label={elementBeingDragged.displayState}
                checked={elementBeingDragged.checked}
                disabled={false}
            />
        }
    </>;
}

interface ColumnSelectorElementProps {
    sx?: SxProps<Theme>
    dragging?: boolean
    checked: boolean
    disabled: boolean
    onChange?: () => void
    onDrag?: (x: number, y: number) => void
    label: string
}

const ColumnSelectorElement = forwardRef((props: ColumnSelectorElementProps, ref: any) => {
    return <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        sx={{
            position: "relative",
            ...props.sx,
        }}
    >
        <Box onMouseDown={(x) => { props.onDrag && props.onDrag(x.pageX, x.pageY); }}>
            <MenuIcon
                sx={{
                    transform: "scale(0.8)",
                    marginRight: "15px",
                    color: "#B0B7CF",
                    cursor: "pointer"
                }}
            />
        </Box>
        <FormControlLabel control={
            <Checkbox
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
        } label={props.label} />
        {props.dragging && <Box sx={{ position: "absolute", backgroundColor: "#e4f2ff", left: "0", top: "0", width: "100%", height: "100%", zIndex: "9999999" }} />}
    </Stack>;
});


async function FetchFilterFieldData(login: AuthContextData): Promise<FilterFieldData> {
    const req = login.GetAxios();

    if (!req) {
        throw new Error("could not make request");
    }

    const appsResp = await req.get("/api/appsdeleted", {
        params: {
            sortBy: "name",
            sortDirection: "asc"
        }
    });


    const datatypesResp = await req.get("/api/datatypes");

    const rulesResp = await req.get("/api/rulesdeleted");

    const datatypes = ((datatypesResp.data.items || []).map((x: any) => { return { id: x.type, label: x.name }; }) as FilterItem[]).sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base" }));

    return {
        contentTypes: contentTypes.map(x => { return { id: x, label: x, deleted: false }; }),
        usernames: [],
        userGroups: [],
        clientDevice: clientDevices.map(x => { return { id: x, label: x, deleted: false }; }),
        apps: [{ id: "no match", label: "(Unknown)", deleted: false }].concat((appsResp.data || []).map((x: any) => { return { id: x.id, label: x.name, deleted: x.deleted }; }).sort((a: FilterItem, b: FilterItem) => a.label.localeCompare(b.label, undefined, { sensitivity: "base" }))),
        detectedDataType: datatypes,
        obfuscatedDataType: datatypes,
        rules: [{ id: "no match", label: "(None)", deleted: false }].concat((rulesResp.data || []).map((x: any) => { return { id: x.id, label: x.name, deleted: x.deleted }; }).sort((a: FilterItem, b: FilterItem) => a.label.localeCompare(b.label, undefined, { sensitivity: "base" }))),
        originalValueStored: [{ id: "true", label: "Yes", deleted: false }, { id: "false", label: "No", deleted: false }],
        types: [{ id: "api", label: "API", deleted: false }, { id: "reqmod", label: "Icap Request", deleted: false }, { id: "respmod", label: "Icap Response", deleted: false }],
        detected: [{ id: "true", label: "Datatypes Detected", deleted: false }, { id: "false", label: "Datatypes Not Detected", deleted: false }],
        obfuscated: [{ id: "true", label: "Datatypes Obfuscated", deleted: false }, { id: "false", label: "Datatypes Not Obfuscated", deleted: false }],
        scanned: [{ id: "true", label: "Datatypes Scanned", deleted: false }, { id: "false", label: "Datatypes Not Scanned", deleted: false }],
        countries: Object.entries(countries.getAlpha2Codes()).map(([code]) => {
            return {
                id: code,
                label: countries.getName(code, "en") || "",
                deleted: false,
            };
        })
    };
}
