import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Loading from "components/Loading";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "providers/Notification";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AddOutlined, CancelOutlined, InfoOutlined, MoreHorizOutlined, SaveOutlined } from "@mui/icons-material";

function IcapConfiguration() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [showTooltipPAC, setShowTooltipPAC] = useState(false);
    const [enableApi, setEnableApi] = useState(false);
    const [enableSAML, setEnableSAML] = useState(false);



    const [SAMLMetadataUrl, setSAMLMetadataUrl] = useState("");
    const [SAMLMetadata, setSAMLMetadata] = useState("");


    const [UserSAMLMetadataUrl, setUserSAMLMetadataUrl] = useState("");
    const [UserSAMLMetadata, setUserSAMLMetadata] = useState("");

    const [UsernameHeader, setUsernameHeader] = useState("");
    const [UsernameGroupHeader, setUsernameGroupHeader] = useState("");
    const [RuleIdHeader, setRuleIdHeader] = useState("");
    const [LogHeader, setLogHeader] = useState("");

    const [samlDialogOpen, setSamlDialogOpen] = useState(false);
    const [userSamlDialogOpen, setUserSamlDialogOpen] = useState(false);

    const [enableActivitylogClear, setEnableActivitylogClear] = useState(false);
    const [activityMaxAge, setActivityMaxAge] = useState<number>();
    const [schedule, setActivitySchedule] = useState("");

    const [enableActivity, setEnableActivity] = useState(false);

    const [userSamlAvailable, setUserSamlAvailable] = useState(false);
    const [userSamlNotAvailableReason, setUserSamlNotAvailableReason] = useState("");

    const [allowedUrlPatterns, setAllowedUrlPatterns] = useState<string[]>([]);

    const [enabledAllowlists, setEnabledAllowlists] = useState<string[]>([]);
    const [availableAllowlists, setAvailableAllowlists] = useState<PremadeAllowlist[]>([]);



    const [authenticationMethod, setAuthenticationMethod] = useState("none");
    const [authSessionExpiration, setAuthSessionExpiration] = useState(30);
    const [authSessionExpirationUnit, setAuthSessionExpirationUnit] = useState("days");
    const [authIdleSessionExpiration, setAuthIdleSessionExpiration] = useState(1);
    const [authIdleSessionExpirationUnit, setAuthIdleSessionExpirationUnit] = useState("days");

    const req = login.GetAxios();

    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const respConfig = await req.get("/api/config");
            const respSamlAvailability = await req.get("/api/config/samlavailability");
            const respAllowlists = await req.get("/api/config/allowlists");

            const settings = respConfig.data;
            const samlAvailability = respSamlAvailability.data;
            const allowLists = respAllowlists.data;

            setUserSamlAvailable(samlAvailability?.user?.available || false);
            setUserSamlNotAvailableReason(samlAvailability?.user?.reason || "");

            setEnabled(settings.enabled);
            setEnableApi(settings.enableAPI);

            setEnableSAML(settings.saml.enable);
            setSAMLMetadata(settings.saml.metadata);

            setUsernameHeader(settings.headers.usernameHeader);
            setUsernameGroupHeader(settings.headers.usernameGroupHeader);
            setRuleIdHeader(settings.headers.ruleIdHeader);
            setLogHeader(settings.headers.logHeader);
            setActivitySchedule(settings.activity.cleanupSchedule);
            setActivityMaxAge(Math.round(settings.activity.maxAge / 86400));
            setEnableActivitylogClear((settings.activity.maxAge && settings.activity.cleanupSchedule) ? true : false);
            setEnableActivity(settings.activity.enable);
            setUserSAMLMetadata(settings.userSAMLMetadata || "");
            setAuthenticationMethod(settings.authenticationMode || "none");
            setAllowedUrlPatterns(settings.allowWithoutAuthentication || []);
            setAvailableAllowlists(allowLists || []);
            setEnabledAllowlists(settings.enabledAuthenticationAllowLists || []);

            setAuthIdleSessionExpiration(settings.authIdleSessionExpiration || 1);
            setAuthIdleSessionExpirationUnit(settings.authIdleSessionExpirationUnit || "days");
            setAuthSessionExpiration(settings.authSessionExpiration || 30);
            setAuthSessionExpirationUnit(settings.authSessionExpirationUnit || "days");

            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching configuration data",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData().catch(console.log);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const SaveConfiguration = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        if (userSamlNotAvailableReason !== "" && authenticationMethod === "saml") {
            notification.Display({
                title: "Error",
                message: "To use SAML login, TLS must be enabled.",
                type: "error",
            });
            return;
        }

        if (enableActivitylogClear) {
            if (!activityMaxAge) {
                notification.Display({
                    title: "Error",
                    message: "Max age is required",
                    type: "error",
                });
                return;
            }
            if (!schedule) {
                notification.Display({
                    title: "Error",
                    message: "Schedule is required",
                    type: "error",
                });
                return;
            }
        }

        setSaving(true);

        try {
            const originalConfig = (await req.get("/api/config")).data;

            await req.put("/api/config", {
                ...originalConfig,
                enabled,
                enableAPI: enableApi,
                saml: {
                    enable: enableSAML,
                    metadataUrl: SAMLMetadataUrl,
                    metadata: SAMLMetadata,
                },
                headers: {
                    usernameHeader: UsernameHeader,
                    usernameGroupHeader: UsernameGroupHeader,
                    ruleIdHeader: RuleIdHeader,
                    logHeader: LogHeader
                },
                activity: {
                    enable: enableActivity,
                    maxAge: enableActivitylogClear ? ((activityMaxAge || 0) * 86400) : undefined,
                    cleanupSchedule: enableActivitylogClear ? schedule : undefined,
                },
                userSAMLMetadata: UserSAMLMetadata,
                userSAMLMetadataURL: UserSAMLMetadataUrl,
                authenticationMode: authenticationMethod,
                allowWithoutAuthentication: allowedUrlPatterns,
                enabledAuthenticationAllowLists: enabledAllowlists,
                authSessionExpiration,
                authSessionExpirationUnit,
                authIdleSessionExpiration,
                authIdleSessionExpirationUnit
            });
            notification.Display({
                type: "success",
                title: "Success",
                message: "ICAP Configuration saved successfully",
            });
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error saving configuration",
                type: "error",
            });
        }

        setSaving(false);
        void loadData();
    };

    const clearUserSessions = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            await req.post("/api/users/deleteall", {});
            notification.Display({
                title: "Success",
                message: "All user sessions cleared",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Error deleting user sessions",
                type: "error",
            });
        }
    };


    return <>
        <Loading finished={finishedLoading}>
            <Typography variant="h1">ICAP Configuration</Typography>

            <Box display="flex" flexDirection="column">

                <Card sx={{ marginTop: "30px", padding: "0px" }}>
                    <CardContent sx={{ padding: "10px" }}>
                        <ConfigurationOption
                            label="Headers"
                            description="The following headers are used by the ICAP as filters for rules with user and/or group filters specified"
                            hideCheckbox={true}
                        >
                            <Grid container sx={{
                                marginBottom: "10px",
                                marginTop: "20px"
                            }}>
                                <Grid item sm={3}>
                                    <TextField
                                        id="username-header-input"
                                        label="Username Header"
                                        variant="outlined"
                                        value={UsernameHeader}
                                        onChange={(x) => setUsernameHeader(x.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        id="user-group-header-input"
                                        label="Group Header"
                                        variant="outlined"
                                        value={UsernameGroupHeader}
                                        onChange={(x) => setUsernameGroupHeader(x.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        id="rule-id-header-input"
                                        label="Rule ID Header"
                                        variant="outlined"
                                        value={RuleIdHeader}
                                        onChange={(x) => setRuleIdHeader(x.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        id="activity-log-data-header-input"
                                        label="Activity Log Data Header"
                                        variant="outlined"
                                        value={LogHeader}
                                        onChange={(x) => setLogHeader(x.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </ConfigurationOption>


                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />

                        <ConfigurationOption
                            id="enabled-rule-check"
                            label="Enable Rule Id Filter"
                            description="When enabled, if the request has the Rule Id Header defined, the specified rule is applied ignoring all other rules."
                            checked={enableApi}
                            onChange={setEnableApi}
                        />

                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        <ConfigurationOption
                            label="Proxy Configuration"
                            description="You can download nullafi certificate and PAC file to use our proxy."
                            hideCheckbox={true}
                        >
                            <Stack marginTop="10px" direction="row" spacing={2}>
                                <Button variant="outlined" href="/api/proxy/download/certificate/nullafi.crt" target="_blank">Download Certificate</Button>
                                <Button variant="outlined" href="/api/proxy/download/pac/nullafi.pac" target="_blank">Download PAC File</Button>
                                <TextField
                                    id="pac-url"
                                    label="PAC File URL"
                                    value={`${window.origin}/api/proxy/download/pac/nullafi.pac`}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment:
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    open={showTooltipPAC}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                >
                                                    <IconButton
                                                        id="copy-pac-url-button"
                                                        color="primary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`${window.origin}/api/proxy/download/pac/nullafi.pac`).catch(console.log);
                                                            setShowTooltipPAC(true);
                                                            setTimeout(() => {
                                                                setShowTooltipPAC(false);
                                                            }, 2000);
                                                        }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                    }}
                                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                                    inputProps={{ style: { width: "380px" } }}

                                />
                            </Stack>
                        </ConfigurationOption>

                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        <ConfigurationOption
                            label="Security"
                            description="Users can be authenticated for better access control"
                            hideCheckbox={true}
                        >
                            <Grid container>
                                <Grid item sm={12} >
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row"
                                    }}>
                                        <FormControl sx={{ marginTop: "20px", minWidth: "300px" }}>
                                            <InputLabel id="authentication-method-label">Authentication Method</InputLabel>
                                            <Select
                                                labelId="authentication-method-label"
                                                id="authentication-method-selector"
                                                value={authenticationMethod}
                                                label="Authentication Method"
                                                onChange={(event) => {
                                                    setAuthenticationMethod(event.target.value as string);
                                                }}
                                            >
                                                <MenuItem value={"none"}>No Authentication</MenuItem>
                                                <MenuItem value={"proxy"}>Proxy</MenuItem>
                                                <MenuItem value={"saml"}>SAML</MenuItem>
                                            </Select>
                                            {userSamlNotAvailableReason !== "" && authenticationMethod === "saml" ? <Typography variant="caption" color="error.main">{userSamlNotAvailableReason}</Typography> : undefined}
                                        </FormControl>
                                        {authenticationMethod === "saml" && <>
                                            <FormControl sx={{ marginLeft: "20px", marginTop: "20px", minWidth: "300px" }}>
                                                <Box>
                                                    <TextField
                                                        label="Session Timeout"
                                                        type="number"
                                                        value={authIdleSessionExpiration}
                                                        onChange={(x) => {
                                                            setAuthIdleSessionExpiration(Number(x.target.value || 0));
                                                        }}
                                                    />
                                                    <Select
                                                        value={authIdleSessionExpirationUnit}
                                                        onChange={x => setAuthIdleSessionExpirationUnit(x.target.value as any)}
                                                    >
                                                        <MenuItem value={"minutes"}>minutes</MenuItem>
                                                        <MenuItem value={"hours"}>hours</MenuItem>
                                                        <MenuItem value={"days"}>days</MenuItem>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                            <FormControl sx={{ marginLeft: "20px", marginTop: "20px", minWidth: "300px" }}>
                                                <Box>
                                                    <TextField
                                                        label="Max. Session Duration"
                                                        type="number"
                                                        value={authSessionExpiration}
                                                        onChange={(x) => {
                                                            setAuthSessionExpiration(Number(x.target.value || 0));
                                                        }}
                                                    />
                                                    <Select
                                                        value={authSessionExpirationUnit}
                                                        onChange={x => setAuthSessionExpirationUnit(x.target.value as any)}
                                                    >
                                                        <MenuItem value={"minutes"}>minutes</MenuItem>
                                                        <MenuItem value={"hours"}>hours</MenuItem>
                                                        <MenuItem value={"days"}>days</MenuItem>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </>
                                        }
                                    </Box>
                                </Grid>
                                {authenticationMethod === "saml" && <>
                                    <Grid item sm={12}>
                                        <Stack sx={{ maxWidth: "250px" }}>
                                            <Button
                                                id="saml-user-open-dialog"
                                                sx={{ marginTop: "10px" }}
                                                onClick={() => {
                                                    setUserSamlDialogOpen(true);
                                                }}
                                                variant="outlined"
                                                disabled={!userSamlAvailable}
                                            >
                                                Set IDp Metadata
                                            </Button>
                                        </Stack>
                                        {userSamlAvailable && <Link sx={{ marginLeft: "10px" }} href="/user/saml/metadata" download>Download SP Metadata</Link>}
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Button
                                            sx={{ marginTop: "10px" }}
                                            id="clear_user_sessions"
                                            onClick={() => {
                                                clearUserSessions().catch(console.log);
                                            }}
                                            variant="outlined"
                                            disabled={!userSamlAvailable}
                                        >
                                            Clear User Sessions
                                        </Button>
                                    </Grid>
                                </>}
                            </Grid>
                        </ConfigurationOption>

                        {authenticationMethod === "saml" && userSamlNotAvailableReason === "" && <>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                            <ConfigurationOption
                                label="Allowed URLs"
                                description="Access for URLs that match patterns on this list will be allowed even if the user is not authenticated"
                                hideCheckbox={true}
                            >
                                <Grid container sx={{ maxWidth: "600px", }}>
                                    <Grid item sm={12}>
                                        <PremadeAllowlistsSelector
                                            availableAllowLists={availableAllowlists}
                                            enabledAllowLists={enabledAllowlists}
                                            toggledAllowList={(id, newstate) => {
                                                if (newstate) {
                                                    setEnabledAllowlists(enabledAllowlists.concat(id));
                                                } else {
                                                    setEnabledAllowlists(enabledAllowlists.filter(x => x !== id));
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography>Additional allowed urls:</Typography>
                                    </Grid>
                                    <AllowedUrlList
                                        allowedUrls={allowedUrlPatterns}
                                        valueChanged={(x) => setAllowedUrlPatterns(x)}
                                    />
                                </Grid>
                            </ConfigurationOption>
                        </>}
                    </CardContent>
                </Card>
            </Box >


            <Box display="flex" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <LoadingButton
                    id="save-changes-button"
                    variant="contained"
                    sx={{ marginLeft: "auto" }}
                    onClick={SaveConfiguration}
                    loading={saving}>Save Changes</LoadingButton>
            </Box>

            <SAMLMetadataDialog
                open={samlDialogOpen}
                onClose={() => {
                    setSamlDialogOpen(false);
                }}
                value={(SAMLMetadata && { type: "xml", metadata: SAMLMetadata }) || (SAMLMetadataUrl && { type: "url", url: SAMLMetadataUrl }) || undefined}
                onSave={(v) => {
                    setSamlDialogOpen(false);
                    if (v.type === "url") {
                        setSAMLMetadata("");
                        setSAMLMetadataUrl(v.url);
                    } else if (v.type === "xml") {
                        setSAMLMetadata(v.metadata);
                        setSAMLMetadataUrl("");
                    }
                }}
            />

            <SAMLMetadataDialog
                open={userSamlDialogOpen}
                onClose={() => {
                    setUserSamlDialogOpen(false);
                }}
                value={(UserSAMLMetadata && { type: "xml", metadata: UserSAMLMetadata }) || (UserSAMLMetadataUrl && { type: "url", url: UserSAMLMetadataUrl }) || undefined}
                onSave={(v) => {
                    setUserSamlDialogOpen(false);
                    if (v.type === "url") {
                        setUserSAMLMetadata("");
                        setUserSAMLMetadataUrl(v.url);
                    } else if (v.type === "xml") {
                        setUserSAMLMetadata(v.metadata);
                        setUserSAMLMetadataUrl("");
                    }
                }}
            />
        </Loading >
    </>;
}

interface ConfigurationOptionsProps {
    id?: string
    label: string
    description?: string
    checked?: boolean
    onChange?: (b: boolean) => void
    hideCheckbox?: boolean
    disabled?: boolean
    children?: React.ReactChild | React.ReactChild[]
}

function ConfigurationOption(props: ConfigurationOptionsProps) {
    return (
        <Box display="flex" sx={{ alignItems: "start", marginBottom: "10px" }}>
            {
                !props.hideCheckbox &&
                <Checkbox id={props.id} disabled={props.disabled} sx={{ "& .MuiSvgIcon-root": { fontSize: "36px" } }} checked={props.checked} onChange={(x) => props.onChange && props.onChange(x.target.checked)} />
            }
            <Box sx={{ marginLeft: "10px", marginTop: "6px", width: "100%" }}>
                <Typography variant="h4" color={props.disabled ? "text.disabled" : undefined}>{props.label}</Typography>
                <Typography variant="body2" color={props.disabled ? "text.disabled" : "text.secondary"}>{props.description}</Typography>
                {props.children}
            </Box>
        </Box>
    );
}

interface SAMLMetadataDialogProps {
    value?: SAMLSetupUrl | SAMLSetupXml
    open: boolean
    onSave: (v: SAMLSetupUrl | SAMLSetupXml) => void
    onClose: () => void
}

interface SAMLSetupUrl {
    type: "url",
    url: string
}

interface SAMLSetupXml {
    type: "xml"
    metadata: string
}

function SAMLMetadataDialog(props: SAMLMetadataDialogProps) {
    const [mode, setMode] = useState<"url" | "xml">("url");
    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.value) {
            setMode(props.value.type);

            if (props.value.type === "url") {
                setValue(props.value.url);
            } else if (props.value.type === "xml") {
                setValue(props.value.metadata);
            }
        }
    }, [props.value, props.open]);

    return <Dialog
        open={props.open}
    >
        <DialogTitle>
            {"SAML IDp Metadata"}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "400px" }}>
            {mode === "url" && <>
                <TextField
                    id="idp-url"
                    label="URL to fetch IDp metadata"
                    value={value}
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    fullWidth
                    onChange={(x) => setValue(x.target.value)}
                />
            </>}
            {mode === "xml" && <>
                <TextField
                    sx={{
                        marginTop: "10px"
                    }}
                    inputProps={{
                        style: {
                            whiteSpace: "nowrap"
                        }
                    }}

                    id="idp-metadata"
                    label="IDp Metadata"
                    multiline
                    rows={10}
                    placeholder="IDp Metadata"
                    value={value}
                    fullWidth

                    onChange={(x) => setValue(x.target.value)}
                />
            </>}
        </DialogContent>
        <DialogActions>
            {mode === "url" && <>
                <Button id="saml-mode-metadata" onClick={() => {
                    setValue("");
                    setMode("xml");
                }} variant="outlined">
                    Paste XML
                </Button>
            </>}
            {mode === "xml" && <>
                <Button id="saml-mode-url" onClick={() => {
                    setValue("");
                    setMode("url");
                }} variant="outlined">
                    Fetch from URL
                </Button>
            </>}
            <Button id="cancel-saml-metadata-dialog" onClick={() => {
                props.onClose();
            }} variant="outlined">
                Close
            </Button>
            <Button id="save-saml-metadata-dialog" onClick={() => {
                if (mode === "url") {
                    props.onSave({
                        type: "url",
                        url: value,
                    });
                } else {
                    props.onSave({
                        type: "xml",
                        metadata: value,
                    });
                }
            }} variant="contained" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>;
}


interface PremadeAllowlistsSelectorProps {
    availableAllowLists: PremadeAllowlist[]
    enabledAllowLists?: string[]
    toggledAllowList?: (id: string, state: boolean) => void
}

interface PremadeAllowlist {
    id: string
    name: string
    patterns: string[]
}

function PremadeAllowlistsSelector(props: PremadeAllowlistsSelectorProps) {
    return <>
        <List sx={{ width: "100%" }}>
            {props.availableAllowLists.map((value) => {
                return (
                    <AllowListItem
                        value={value}
                        checked={(props.enabledAllowLists && props.enabledAllowLists.includes(value.id)) || false}
                        toggledAllowList={() => {
                            props.toggledAllowList && props.toggledAllowList(value.id, !(props.enabledAllowLists?.includes(value.id) || false));
                        }}
                    />
                );
            })}
        </List>
    </>;
}

interface AllowListItemProps {
    value: PremadeAllowlist
    checked: boolean
    toggledAllowList?: () => void
}

function AllowListItem(props: AllowListItemProps) {
    const labelId = `allowlist-label-${props.value.id}`;
    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    return <ListItem
        key={`allowlist-item-${props.value.id}`}
        secondaryAction={
            <IconButton id={`allowlist-item-details-${props.value.id}`} ref={ref} edge="end" onClick={() => setOpen(true)}>
                <InfoOutlined />
            </IconButton>
        }
        disablePadding
    >
        <ListItemButton role={undefined} onClick={() => props.toggledAllowList && props.toggledAllowList()}>
            <ListItemIcon>
                <Checkbox
                    id={`allowlist-item-checkbox-${props.value.id}`}
                    edge="start"
                    checked={props.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                />
            </ListItemIcon>
            <ListItemText id={labelId} primary={props.value.name} />
        </ListItemButton>
        <Popover
            open={open}
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Box sx={{
                padding: "10px"
            }}>
                <Typography sx={{ fontWeight: 600 }}>Allowed url patterns:</Typography>
                {props.value.patterns && props.value.patterns.map(x => {
                    return <Typography>{x}</Typography>;
                })}
            </Box>
        </Popover>
    </ListItem>;
}


interface AllowedUrlListProps {
    allowedUrls: string[]
    valueChanged: (newValue: string[]) => void
}

function AllowedUrlList(props: AllowedUrlListProps) {
    const [allowedUrlInput, setAllowedUrlInput] = useState("");
    const [editingItem, setEditingItem] = useState(-1);


    useEffect(() => {
        setAllowedUrlInput("");
        setEditingItem(-1);
    }, [props.allowedUrls]);

    return <Grid item sm={12}>
        <List sx={{ width: "100%" }}>
            {props.allowedUrls.map((value, i) => {
                return <AllowedUrlListItem
                    index={`${i}`}
                    value={value}
                    onDelete={() => {
                        props.valueChanged(props.allowedUrls.filter((x, idx) => i !== idx));
                    }}
                    onEditFinished={(newvalue) => {
                        props.valueChanged(props.allowedUrls.map((original, idx) => {
                            if (idx === i) {
                                return newvalue;
                            }
                            return original;
                        }));
                    }}
                />;
            })}
            {props.allowedUrls.length === 0 ? <Typography sx={{ "padding": "10px", textAlign: "center" }}>No items to display</Typography> : undefined}
            <ListItem
                secondaryAction={
                    <IconButton id="allowed-url-list-add-button" edge="end" aria-label="comments"
                        disabled={editingItem !== -1}
                        onClick={() => {
                            if (allowedUrlInput === "") {
                                return;
                            }

                            props.valueChanged(props.allowedUrls.concat(allowedUrlInput));
                            setAllowedUrlInput("");
                        }}
                    >
                        <AddOutlined />
                    </IconButton>
                }

            >
                <TextField fullWidth id="allowed-url-list-add-input" variant="outlined"
                    disabled={editingItem !== -1}
                    value={allowedUrlInput}
                    onChange={x => {
                        setAllowedUrlInput(x.target.value);
                    }}
                />
            </ListItem>
        </List>
    </Grid>;
}

interface AllowedUrlListItemProps {
    index: string
    value: string
    onDelete: () => void
    onEditFinished: (newValue: string) => void
}

function AllowedUrlListItem(props: AllowedUrlListItemProps) {
    const [inputValue, setInputValue] = useState("");
    const [editMode, setEditMode] = useState(false);
    const ref = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);


    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    return <>
        {!editMode ?
            <ListItem
                secondaryAction={
                    <IconButton id={`allowed-url-list-menu-${props.index}`} ref={ref} edge="end"
                        onClick={() => {
                            setMenuOpen(true);
                        }}
                    >
                        <MoreHorizOutlined />
                    </IconButton>
                }

            >
                <ListItemButton>
                    <ListItemText primary={props.value} />
                </ListItemButton>
            </ListItem>
            :
            <ListItem>
                <TextField fullWidth id={`allowed-url-list-input-${props.index}`} variant="outlined"
                    value={inputValue}
                    onChange={x => {
                        setInputValue(x.target.value);
                    }}
                />
                <IconButton
                    id={`allowed-url-list-input-cancel-${props.index}`}
                    onClick={() => {
                        setInputValue(props.value);
                        setEditMode(false);
                    }}
                >
                    <CancelOutlined />
                </IconButton>
                <IconButton
                    id={`allowed-url-list-input-save-${props.index}`}
                    onClick={() => {
                        props.onEditFinished(inputValue);
                        setMenuOpen(false);
                        setEditMode(false);
                    }}
                >
                    <SaveOutlined />
                </IconButton>
            </ListItem>
        }

        <Menu
            anchorEl={ref.current}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}

        >
            <MenuItem id={`allowed-url-list-menu-edit-${props.index}`} onClick={() => { setEditMode(true); setMenuOpen(false); }}>Edit</MenuItem>
            <MenuItem id={`allowed-url-list-menu-delete-${props.index}`} onClick={props.onDelete}>Delete</MenuItem>
        </Menu>
    </>;


}

export default IcapConfiguration;