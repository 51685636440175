import { NavigateFunction, matchPath } from "react-router";
import { MenuItemRoute, MenuItemGroup } from "./MenuDefinition";
import CollapsableButton from "./CollapsableButton";
import MenuButton from "./MenuButton";

export interface RenderMenuItemOpts {
    item: MenuItemRoute | MenuItemGroup
    minimal: boolean
    path: string
    indent?: boolean
    navigate: NavigateFunction
}
export function RenderMenuItem(opts: RenderMenuItemOpts): [JSX.Element, boolean] {
    if (opts.item.type === "item") {
        const path = opts.item.path;
        const selected = matchPath(path, opts.path) ? true : false;

        return [
            <MenuButton key={opts.item.text} minimal={opts.minimal} text={opts.item.text} icon={opts.item.icon} indent={opts.indent} selected={selected} onClick={() => (opts.item as MenuItemRoute).newTab ? window.open(path, "_blank") : opts.navigate(path)} badge={opts.item.badge}/>,
            selected
        ];
    } else if (opts.item.type === "group") {
        const renderedChildren = opts.item.itens.map(x =>
            RenderMenuItem({
                item: x,
                minimal: opts.minimal,
                path: opts.path,
                indent: true,
                navigate: opts.navigate,
            })
        );
        const selected = renderedChildren.some(x => x[1]);

        const defaultPath = opts.item.defaultPath;

        return [<CollapsableButton
            key={opts.item.text}
            minimal={opts.minimal}
            text={opts.item.text}
            icon={opts.item.icon}
            open={selected}
            onOpen={defaultPath ? () => {
                opts.navigate(defaultPath);
            } : undefined}
        >
            {renderedChildren.map(x => x[0])}
        </CollapsableButton>, selected];
    }
    throw new Error("invalid menu item type");
}

export default RenderMenuItem;