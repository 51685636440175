import { LoadingButton } from "@mui/lab";
import { Typography, FormControl, TextField, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import axios from "axios";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Link from "@mui/material/Link";

interface SamlSetupProps {
    loginSetup: () => void
    setError: (e: string) => void
}

function SAMLSetup(props: SamlSetupProps) {
    const loginCtx = useLogin();
    const [inProgress, setInProgress] = useState(false);
    const [mode, setMode] = useState<"url" | "xml" | "nossl">("url");
    const [value, setValue] = useState("");
    const [finishedLoading, setFinishedLoading] = useState(false);

    const navigate = useNavigate();

    const init = async () => {
        if (loginCtx.LoggedIn) {
            navigate("/");
        }

        const response = await axios.get("/api/auth/options");
        if (response.data.adminUserExists === true) {
            navigate("/login");
        }
        if (response.data.sslConfigured === false) {
            setMode("nossl");
        }

        setFinishedLoading(true);
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        if (mode === "url" && value === "") {
            props.setError("Metadata URL cannot be empty");
            return;
        }

        if (mode === "xml" && value === "") {
            props.setError("Metadata cannot be empty");
            return;
        }

        setInProgress(true);
        props.setError("");

        try {
            await axios.post("/api/auth/samlsetup", {
                "metadataUrl": mode === "url" ? value : undefined,
                "metadata": mode === "xml" ? value : undefined
            });

            window.location.href = "/saml_login";
        } catch (e: any) {
            if (axios.isAxiosError(e) && e.response) {
                const msg = e.response.data.message as string;
                props.setError(msg.charAt(0).toUpperCase() + msg.slice(1));
            } else {
                props.setError("Internal error");
                console.error(e);
            }
            setInProgress(false);
            return;
        }
    };

    return (<Loading finished={finishedLoading} ><>
        {mode === "url" && <>
            <Stack>
                <Typography>Provide an IdP metadata url to set up SAML</Typography>
                <FormControl sx={{ marginTop: "15px" }}>
                    <TextField
                        id="idp-metadata-url"
                        label="Metadata Url"
                        variant="outlined"
                        value={value}
                        onChange={(x) => setValue(x.target.value)}
                    />
                </FormControl>

                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <Link href="/saml/metadata" download>Download SP metadata</Link>
                </Box>

                <Stack direction="row" sx={{ marginTop: "20px" }}>
                    <Button onClick={() => {
                        setValue("");
                        setMode("xml");
                    }}>
                        Paste Metadata XML
                    </Button>
                    <Button onClick={props.loginSetup} sx={{ marginLeft: "auto" }} variant="outlined">Login Setup</Button>
                    <LoadingButton id="submit-button" loading={inProgress} variant="contained" onClick={onSubmit} sx={{ marginLeft: "5px" }}>Submit</LoadingButton>
                </Stack>
            </Stack>
        </>}
        {mode === "xml" && <>
            <Stack>
                <Typography>Provide the IdP metadata XML</Typography>
                <FormControl sx={{ marginTop: "15px" }}>
                    <TextField
                        id="idp-metadata-xml"
                        label="IDp Metadata XML"
                        variant="outlined"
                        value={value}
                        multiline
                        rows={10}
                        inputProps={{
                            style: {
                                whiteSpace: "nowrap"
                            }
                        }}
                        onChange={(x) => setValue(x.target.value)}
                    />
                </FormControl>

                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <Link href="/saml/metadata" download>Download SP metadata</Link>
                </Box>

                <Stack direction="row" sx={{ marginTop: "20px" }}>
                    <Button onClick={() => {
                        setValue("");
                        setMode("url");
                    }}>
                        Fetch Metadata from URL
                    </Button>
                    <Button onClick={props.loginSetup} sx={{ marginLeft: "auto" }} variant="outlined">Login Setup</Button>
                    <LoadingButton id="submit-button" loading={inProgress} variant="contained" onClick={onSubmit} sx={{ marginLeft: "5px" }}>Submit</LoadingButton>
                </Stack>
            </Stack>
        </>}
        {mode === "nossl" && <>
            <Typography>To enable SAML, TLS must be configured first.</Typography>
            <Box margin="auto" sx={{ marginTop: "20px" }}>
                <Button onClick={props.loginSetup} variant="outlined">Login Setup</Button>
            </Box>
        </>}
    </></Loading>);
}

export default SAMLSetup;