import { InputAdornment, ListItemText, MenuItem, MenuList, OutlinedInput, Popover, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ArrowDropDown, Search } from "@mui/icons-material";

interface MenuSelectProps<T> {
    id: string;
    label: string;
    filter?: boolean;
    size: "small" | "medium" | undefined;
    popoverExtra?: (handleClose: () => void) => React.ReactNode;
    popoverOnly?: boolean;
    valueList: { value: T; text: string; selected: boolean; }[];
    onItemSelected?: (value: T) => void;
}

export function MenuSelect<T>(props: MenuSelectProps<T>) {
    const [anchor, setAnchor] = useState<Element | undefined>();
    const [width, setWidth] = useState(0);

    const [filter, setFilter] = useState("");

    useEffect(() => {
        setFilter("");
    }, [props.valueList]);

    const handleClose = () => {
        setAnchor(undefined);
    };

    const itemSelected = (value: T) => {
        props.onItemSelected && props.onItemSelected(value);
        setAnchor(undefined);
    };
    return <>
        <OutlinedInput
            size={props.size}
            id={props.id}
            fullWidth
            value={props.label}
            inputProps={{
                readOnly: true
            }}
            endAdornment={<InputAdornment position="end">
                <ArrowDropDown></ArrowDropDown>
            </InputAdornment>}
            onClick={(e) => {
                setWidth(e.currentTarget.getBoundingClientRect().width);
                setAnchor(e.currentTarget);
            }} />
        <Popover
            sx={{ overflow: "auto" }}
            PaperProps={{ sx: { width: `${width}px` } }}
            open={anchor !== undefined}
            anchorEl={anchor}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <>
                {props.filter && <TextField autoFocus fullWidth sx={{ padding: "15px" }} placeholder="Type to search" value={filter} onChange={(x) => setFilter(x.currentTarget.value)} variant="standard" InputProps={{
                    startAdornment: <Search />
                }} />}
                <MenuList className="custom-dropdown-list" sx={{ paddingBottom: "0px" }}>

                    {!props.popoverOnly && props.valueList.filter(x => !props.filter || filter.length === 0 || x.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0).map((x) => {
                        return <MenuItem onClick={() => !x.selected && itemSelected(x.value)} selected={x.selected}>
                            <ListItemText>{x.text}</ListItemText>
                        </MenuItem>;
                    })}
                    {props.popoverExtra && props.popoverExtra(handleClose)}
                </MenuList>
            </>
        </Popover>
    </>;

}
