import { Alert, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { LinkMenu } from "./_components/LinkMenu";
import { LogoutButton } from "./_components/LogoutButton";
import { Box } from "@mui/system";
import { NotificationDisplay } from "providers/Notification";
import { LoginDetail, useLogin, VersionInfo } from "providers/Login";

interface SidebarProps {
    user: LoginDetail
    versionInfo: VersionInfo | undefined
}

function Sidebar(props: SidebarProps) {
    const [minimal, setMinimal] = useState(false);
    const n = () => {
        setMinimal(!minimal);
    };

    return (
        <>
            <Stack sx={{
                position: "relative",
                padding: "20px",
                alignItems: "center",
            }}>
                <Typography variant="h3" component="div" sx={{ paddingLeft: "10px", paddingTop: "10px", fontSize: "20px", width: "100%" }}>
                    nullafi
                </Typography>
                <LinkMenu minimal={minimal} />
                <LogoutButton user={props.user} minimal={minimal} />

                <IconButton onClick={n} size={"small"} sx={{
                    position: "absolute",
                    right: "0px",
                    top: "70px",
                    width: "30px",
                    height: "30px",
                    transform: "translateX(50%)",
                    background: "#FFFFFF",
                    border: "0.5px solid #DDDDDD",
                    boxSizing: "border-box",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                        background: "white",
                    }
                }}>
                    {minimal ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </IconButton>
                <Typography variant="subtitle2" color="text.info" style={{ "marginTop": "5px" }}>{props.versionInfo?.version}</Typography>
                <Typography variant="subtitle2" color="text.info"><small>{props.versionInfo?.buildDate}</small></Typography>

                <Box sx={{
                    position: "absolute",
                    bottom: "40px",
                    right: "-40px",
                    transform: "translateX(100%)",
                    zIndex: "1000000000",
                }}>
                    <NotificationDisplay />
                    <DatabaseErrorAlert />
                </Box>
            </Stack>
            <Divider orientation="vertical" flexItem />

        </>
    );
}


function DatabaseErrorAlert() {
    const login = useLogin();

    if (!login.ConnectionOk) {
        return <ErrorComponent message="Could not connect to the server" />;
    }

    if (!login.DatabaseOk) {
        return <ErrorComponent message="Error connecting to the database" />;
    }

    if (!login.ActivityDatabaseOk) {
        return <ErrorComponent message="Error connecting to the activity database" />;
    }

    return null;
}


interface ErrorComponentProps {
    message: string
}
function ErrorComponent(props: ErrorComponentProps) {
    const [hide, setHide] = useState(false);
    return (!hide) ? <Box
        sx={{
            marginTop: "10px",
            minWidth: "400px",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05)",
        }}>
        <Alert
            severity={"error"}
            sx={{ width: "100%", marginBottom: "10px" }}
            onClose={() => setHide(true)}
        >
            {props.message}
        </Alert>
    </Box> : <></>;
}


export default Sidebar;