import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@mui/material";
import axios from "axios";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import ApiKey from "./Types";

interface DeleteAPIKeyDialogProps {
    keyBeingDeleted: ApiKey | undefined
    onClose: (deleted: boolean) => void
}

function DeleteAPIKeyDialog(props: DeleteAPIKeyDialogProps) {
    const notification = useNotification();
    const login = useLogin();
    const req = login.GetAxios();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [apiKeyName, setApiKeyName] = useState("");

    useEffect(() => {
        setLoading(false);

        if (props.keyBeingDeleted !== undefined) {
            setApiKeyName(props.keyBeingDeleted.name);
        }
    }, [props.keyBeingDeleted]);

    const deleteAPIKeyConfirm = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        setLoading(true);

        try {
            await req.delete(`/api/auth/apikey/${props.keyBeingDeleted?.id}`);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Api key deleted successfully",
            });
            props.onClose(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error deleting API Key",
                type: "error",
            });
            setLoading(false);
        }
    };

    return <Dialog
        open={props.keyBeingDeleted !== undefined}
    >
        <DialogTitle>
            {"Delete API Key"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {`Are you sure you want to delete the API Key ${apiKeyName}?`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button id="cancel-delete-button" onClick={() => props.onClose(false)}>Cancel</Button>
            <LoadingButton id="confirm-delete-button" variant="contained" loading={loading} onClick={() => deleteAPIKeyConfirm()} autoFocus>
                Delete
            </LoadingButton>
        </DialogActions>
    </Dialog>;
}

export default DeleteAPIKeyDialog;