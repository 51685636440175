import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddDataType } from "./_components/AddDataType";
import Visibility from "@mui/icons-material/Visibility";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";

interface DataTypeListState {
    items: DataTypeListStateItem[]
}

interface ObfuscationsState {
    id: string
    name: string
}

interface DataTypeListStateItem {
    id: string
    name: string
    internal: boolean
    description: string
    updatedAt: Date
    obfuscations?: ObfuscationsState[]
}

type columnsType = "internal" | "type" | "name" | "usedby" | "description" | "updatedAt";

export default function DataTypes() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [addDataTypeDrawer, setAddAdataTypelicationDrawer] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogType, setDeleteDialogType] = useState("");
    const [editingType, setEditingType] = useState("");

    const columnsDataId = "dataGrid_dataTypeTable_columns";

    const [listState, setListState] = useState({ items: [] } as DataTypeListState);

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "15%",  direction: "asc", sortOrder: 1 },
        { columnId: "type", width: "15%"},
        { columnId: "internal", width: "10%" },
        { columnId: "usedby", width: "15%" },
        { columnId: "description", width: "35%" },
        { columnId: "updatedAt", width: "35%" },
    ]);

    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);
    const [dataCount, setDataCount] = useState(0);

    const req = login.GetAxios();

    const loadData = async (c: typeof columnsState, skip: number, take: number) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            let sortBy = GetSortString(c);

            const resp = await req.get("/api/datatypes", {
                params: {
                    sortBy,
                    skip,
                    take
                }
            });

            setListState({
                items: (resp.data.items || []).map((x: any) => {
                    const item: DataTypeListStateItem = {
                        id: x.type,
                        name: x.name,
                        internal: x.internal,
                        description: x.description,
                        updatedAt: new Date(x.updatedAt * 1000),
                        obfuscations: x.obfuscations && x.obfuscations.map((item: any) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        })
                    };
                    return item;
                })
            });

            setSkipItems(skip);
            setTakeCount(take);
            setDataCount(resp.data.count || 0);
            setFinishedLoading(true);
            setColumnsState(c);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const deleteRowDialog = (name: string, type: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the Data Type "${name}"?`);
        setDeleteDialogType(type);
    };

    const deleteDataType = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/datatypes/${deleteDialogType}`);
            loadData(columnsState, skipItems, takeCount);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Data Type deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete data type",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };

    const addDataType = () => {
        setEditingType("");
        setAddAdataTypelicationDrawer(true);
    };

    const editDataType = (type: string) => {
        setEditingType(type);
        setAddAdataTypelicationDrawer(true);
    };

    useEffect(() => {
        loadData(RestoreColumnsState("datatypes",columnsState), skipItems, takeCount);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState("datatypes", columnsState);
    }, [columnsState]);

    return <>
        <Box display="flex" flexDirection="column">
            <Box display="flex">
                <Typography variant="h1">Data Types</Typography>
                <Button
                    id="new-data-type-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={addDataType}
                >
                    Add New Data Type
                </Button>
            </Box>

            <DataTable
                data={listState.items}
                finishedLoading={finishedLoading}
                columnsState={columnsState}
                onSortChanged={(c) => {
                    loadData(c, skipItems, takeCount);
                }}
                onColumnOrderChanged={(c) => {
                    setColumnsState(c);
                }}
                onPaginationStartItemChanged={(x) => {
                    loadData(columnsState, x, takeCount);
                }}
                onPaginationItemCountChanged={(x) => {
                    loadData(columnsState, skipItems, x);
                }}
                paginationStartItem={skipItems}
                paginationItemCount={takeCount}
                dataCount={dataCount}
                columnsDataId={columnsDataId}
                columns={{
                    internal: {
                        displayName: "Internal",
                        render: (x) => {
                            return x.internal ? "YES" : "NO";
                        },
                    },
                    type: {
                        displayName: "Type",
                        render: (x) => {
                            return x.id;
                        },
                    },
                    name: {
                        displayName: "Name",
                        sortable: true,
                        render: (x) => {
                            return x.name;
                        },
                    },
                    usedby: {
                        displayName: "Used By Obfuscation(s)",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "row",
                                gap: "2px",
                            }}>
                                {(x.obfuscations && x.obfuscations.map(y => <Chip label={y.name.trim()} variant="outlined" size="small" color="primary" />))}
                            </Box>;
                        },
                    },
                    description: {
                        displayName: "Description",
                        render: (x) => {
                            return x.description;
                        },
                    },
                    updatedAt: {
                        displayName: "Last Update",
                        render: (x) => {
                            return `${x.updatedAt.toLocaleDateString()} ${x.updatedAt.toLocaleTimeString()}`;
                        },
                    },

                }}
                rowOptionsMenu={[
                    {
                        isVisible: (x) => !x.internal,
                        icon: (x) => x.internal ? <Visibility fontSize="small" /> : <EditIcon className="edit-button" fontSize="small" />,
                        text: (x) => x.internal ? "View" : "Edit",
                        onClick: (x) => {
                            editDataType(x.id);
                        }
                    },
                    {
                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                        text: "Delete",
                        isVisible: (x) => !x.internal,
                        onClick: (x) => {
                            if (x.internal) {
                                return notification.Display({
                                    type: "error",
                                    title: "Validation Error",
                                    message: "Can't delete an internal Data Type",
                                });
                            }

                            if (x.obfuscations && x.obfuscations.length > 0) {
                                return notification.Display({
                                    type: "error",
                                    title: "Validation Error",
                                    message: `Cannot delete ${x.name} because it is in use by ${x.obfuscations.length === 1 ? "an Obfuscation" : "some Obfuscations"} (${x.obfuscations.map(i => i.name).join(", ")})`,
                                });
                            }

                            deleteRowDialog(x.name, x.id);
                        }
                    },
                ]}
            />
        </Box>

        <Dialog open={deleteDialog}>
            <DialogTitle>Delete DataType</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" id="cancel-delete-button" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button color="primary" id="confirm-delete-button" autoFocus variant="contained" onClick={deleteDataType}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addDataTypeDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <AddDataType type={editingType} onExit={() => setAddAdataTypelicationDrawer(false)} onSave={() => { loadData(columnsState, skipItems, takeCount); setAddAdataTypelicationDrawer(false); }} />
            </Box>
        </Drawer>
    </>;
}


