import { Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import DataTable from "components/Table";
import AddDataTypeRegex, { AddedDataTypeRegex } from "./EditRegex";

interface AddNewDataTypeProps {
    type?: string
    onSave?: (datatype: DataType) => void
    onExit?: () => void
    onBackButtonClicked?: () => void
}

interface DataType {
    type?: string
    name: string
    internal: boolean
    description: string
    regexes: AddedDataTypeRegex[]
}

export function AddDataType(props: AddNewDataTypeProps) {
    const login = useLogin();
    const notification = useNotification();

    const [saving, setSaving] = useState(false);

    const [ready, setReady] = useState(false);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [internal, setInternal] = useState(false);
    const [description, setDescription] = useState("");
    const [regexes, setRegexes] = useState([] as AddedDataTypeRegex[]);

    const [regexBeingEdited, setRegexBeingEdited] = useState(undefined as (AddedDataTypeRegex | undefined));

    const init = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            //editing
            if (props.type) {
                const r = await req.get(`/api/datatypes/${props.type}`);
                const datatype = APIPayloadToDataType(r.data);

                if (datatype.type) {
                    setType(datatype.type);
                }

                setName(datatype.name);
                setDescription(datatype.description);
                setInternal(datatype.internal);
                setRegexes(datatype.regexes || []);
            }
            setReady(true);
        } catch (err) {
            console.error(err);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onRegexAdded = (data: AddedDataTypeRegex) => {
        if (data.index === undefined) {
            setRegexes(regexes.concat(data));
        } else {
            setRegexes(regexes.map((x, index) => {
                if (index === data.index) {
                    return data;
                } else {
                    return x;
                }
            }));
        }

        setRegexBeingEdited(undefined);
    };

    const cancelRegex = () => {
        setRegexBeingEdited(undefined);
    };

    const deleteRegex = (index: number) => {
        const filtered = regexes.filter((_, i) => {
            return i !== index;
        });

        setRegexes(filtered);
    };

    const editRegex = (index: number) => {
        const a = { ...regexes[index], index };
        setRegexBeingEdited(a);
    };

    const validate = async () => {
        if (!type || !type.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Type is required."
            });

            return false;
        }

        if (!name || !name.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Name is required."
            });

            return false;
        }

        if (!regexes || !regexes.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 definition for a Data Type."
            });

            return false;
        }

        if (!props.type) {
            const req = login.GetAxios();
            if (req) {
                try {
                    const r = await req.get(`/api/datatypes/${type}`);
                    if (r.status === 200) {
                        notification.Display({
                            type: "error",
                            title: "Validation Error",
                            message: "There is already a Data Type with this Type defined."
                        });

                        return false;
                    }
                } catch {
                }
            }
        }

        return true;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (!await validate()) {
            return;
        }

        setSaving(true);

        if (props.type) {
            //update
            try {
                const resp = await req.put(`/api/datatypes/${props.type}`,
                    DataTypeToAPIPayload({
                        type: type,
                        name: name,
                        description: description,
                        internal: internal,
                        regexes: regexes
                    })
                );

                const addedDataType = APIPayloadToDataType(resp.data);

                props.onSave && props.onSave(addedDataType);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save data type",
                });
                return;
            }
        } else {
            //create
            try {
                const resp = await req.post("/api/datatypes",
                    DataTypeToAPIPayload({
                        type: type,
                        name: name,
                        description: description,
                        internal: internal,
                        regexes: regexes
                    })
                );

                const addedDataType = APIPayloadToDataType(resp.data);

                props.onSave && props.onSave(addedDataType);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save data type",
                });
                return;
            }
        }

        notification.Display({
            type: "success",
            title: "Success",
            message: "Data Type saved successfully",
        });
    };

    return <Loading finished={ready}>
        <>
            {
                <Stack sx={{ flex: 1, height: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
                    <Box display="flex" sx={{ alignItems: "start" }}>
                        <Box>
                            {props.onBackButtonClicked ?
                                <Button
                                    startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                                    onClick={props.onBackButtonClicked}
                                >
                                    Back
                                </Button>
                                : undefined
                            }
                            <Typography sx={{ marginTop: "5px" }} variant="h2">{props.type ? (internal ? "View Data Type" : "Edit Data Type") : "Add New Data Type"}</Typography>
                        </Box>
                        <IconButton
                            size="small"
                            sx={{ marginLeft: "auto" }}
                            onClick={props.onExit}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <TextField sx={{ marginTop: "20px" }} disabled={Boolean(props.type)} id="type-input" label="Type" variant="outlined"
                        value={type}
                        onChange={x => setType(x.target.value.toUpperCase())}
                    />

                    <TextField sx={{ marginTop: "20px" }} disabled={internal} id="name-input" label="Name" variant="outlined"
                        value={name}
                        onChange={x => setName(x.target.value)}
                    />

                    <TextField sx={{ marginTop: "20px" }} disabled={internal} id="description-input" label="Description" variant="outlined"
                        value={description}
                        onChange={x => setDescription(x.target.value)}
                    />

                    {!internal ? <AddDataTypeRegex
                        initialValue={regexBeingEdited}
                        onCancelClicked={cancelRegex}
                        onRegexAdded={onRegexAdded}
                    /> : null}


                    <Box sx={{ display: "flex", flex: 1 }}>
                        <DataTable
                            data={regexes.map((v, idx) => {
                                return { ...v, id: idx.toString() };
                            })}
                            columnsDataId="dataGrid_dataTypeDefinitionTable_columns"
                            columns={{
                                regex: {
                                    displayName: "Regex",
                                    render: (x) => {
                                        return x.regex;
                                    }
                                },
                                json: {
                                    displayName: "JSON",
                                    render: (x) => {
                                        return x.json;
                                    }
                                },
                                html: {
                                    displayName: "HTML",
                                    render: (x) => {
                                        return x.html;
                                    }
                                }
                            }}
                            columnsState={[
                                { columnId: "regex", width: "33%" },
                                { columnId: "json", width: "33%" },
                                { columnId: "html", width: "34%" },
                            ]}
                            rowOptionsMenu={internal ? undefined : [
                                {
                                    icon: <EditIcon className="edit-button" fontSize="small" />,
                                    text: "Edit",
                                    onClick: (x) => {
                                        if (!internal) {
                                            editRegex(parseInt(x.id));
                                        }
                                    }
                                },
                                {
                                    icon: <DeleteIcon className="delete-button" fontSize="small" />,
                                    text: "Delete",
                                    onClick: (x) => {
                                        if (!internal) {
                                            deleteRegex(parseInt(x.id));
                                        }
                                    }
                                },
                            ]}
                            finishedLoading
                        />
                    </Box>



                    <Divider sx={{ marginTop: "10px" }} />
                    <Box display="flex" sx={{ marginTop: "10px" }}>
                        <Button
                            variant="outlined"
                            size="medium"
                            id="cancel-button"
                            onClick={props.onExit}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={saving}
                            disabled={internal}
                            sx={{ marginLeft: "auto" }}
                            variant="contained"
                            size="medium"
                            id="save-button"
                            onClick={save}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Stack>

            }
        </>
    </Loading>;
}

function APIPayloadToDataType(body: any) {
    const datatype: DataType = {
        type: body.type,
        name: body.name,
        description: body.description,
        internal: body.internal,
        regexes: (body.regexes || []).map((x: any) => {
            const ret: AddedDataTypeRegex = {
                regex: x.regex,
                valueGroupIndex: x.valueGroupIndex,
                json: x.json,
                html: x.html
            };
            return ret;
        })
    };
    return datatype;
}

function DataTypeToAPIPayload(datatype: DataType): any {
    return {
        type: datatype.type,
        name: datatype.name,
        description: datatype.description,
        regexes: datatype.regexes.map(x => {
            return {
                regex: x.regex,
                valueGroupIndex: x.valueGroupIndex,
                json: x.json,
                html: x.html,
            };
        })
    };
}