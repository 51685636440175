import { Button, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

interface AddGroupProps {
    provider: string
    onSave?: (app: Group) => void
    onExit?: () => void
}

interface Group {
    identifier: string
    provider: string
}

export function AddGroup(props: AddGroupProps) {
    const login = useLogin();
    const notification = useNotification();

    const [saving, setSaving] = useState(false);

    const [identifier, setIdentifier] = useState("");


    const validate = () => {
        if (identifier === "") {
            return "Identifier is required";
        }

        return;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        const error = validate();
        if (error) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: error,
            });
            return;
        }

        setSaving(true);

        try {
            await req.post("/api/user/groups", {
                identifier,
                provider: props.provider,
            });

            props.onSave && props.onSave({
                identifier,
                provider: props.provider,
            });
        } catch (err) {
            setSaving(false);
            console.error(err);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not save group",
            });
            return;
        }


        notification.Display({
            type: "success",
            title: "Success",
            message: "Group saved successfully",
        });
    };

    return (
        <>
            {
                <Stack id="add-mask-format-page" sx={{ flex: 1, height: "100%" }}>
                    <Box display="flex" sx={{ alignItems: "start" }}>
                        <Box>
                            <Typography sx={{ marginTop: "5px" }} variant="h2">Add New Group</Typography>
                        </Box>
                        <IconButton
                            id="exit-button"
                            size="small"
                            sx={{ marginLeft: "auto" }}
                            onClick={props.onExit}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField fullWidth sx={{ marginTop: "20px" }} id="identifier-input" label="Name" variant="outlined"
                                value={identifier}
                                onChange={x => setIdentifier(x.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginTop: "auto" }} />
                    <Box display="flex" sx={{ marginTop: "10px" }}>
                        <Button
                            id="cancel-button"
                            variant="outlined"
                            size="medium"
                            onClick={props.onExit}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            id="save-button"
                            loading={saving}
                            sx={{ marginLeft: "auto" }}
                            variant="contained"
                            size="medium"
                            onClick={save}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Stack>
            }
        </>);
}
