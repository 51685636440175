import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import { MenuSelect } from "./MenuSelect";

interface ListSelectProps {
    id: string;
    size?: "small" | "medium";
    filter?: boolean
    showUnknownValue?: boolean;
    label: string;
    valueList: { [key: string]: string; };
    value: string[];
    onAdd?: (key: string) => void;
    onDelete: (key: string) => void;
    popoverExtra?: (handleClose: () => void) => React.ReactNode;
    popoverOnly?: boolean;
}

export function ListSelect(props: ListSelectProps) {
    return <>
        <Box sx={{ marginTop: "20px" }}>
            <MenuSelect
                id={props.id}
                filter={props.filter}
                size={props.size || "medium"}
                label={props.label}
                valueList={Object.entries(props.valueList).map(([key, value]) => {
                    return {
                        text: value,
                        value: key,
                        selected: props.value.includes(key),
                    };
                })}
                onItemSelected={x => props.onAdd && props.onAdd(x)}
                popoverExtra={(handleClose) => props.popoverExtra && props.popoverExtra(handleClose)}
                popoverOnly={props.popoverOnly} />
            <Box sx={{ marginTop: "10px", gap: "5px", display: "flex", flexWrap: "wrap", minHeight: "32px" }}>
                {props.value.map(x => {
                    let value = props.valueList[x];
                    if (value === undefined && props.showUnknownValue) {
                        value = x;
                    }

                    return <Chip label={value} variant="outlined" color="primary" onDelete={() => props.onDelete(x)} />;
                })}
            </Box>
        </Box>
    </>;
}
