import {
    Alert,
    Card,
    Container,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import abstractShape from "./abstract-shape.svg";
import LoginSetup from "./_components/LoginSetup";
import { useState } from "react";
import SAMLSetup from "./_components/SAMLSetup";

enum SetupScreen {
    LoginSetup = "login",
    SAMLSetup = "saml"
}

function Register() {
    const [setupScreen, setSetupScreen] = useState<SetupScreen>(SetupScreen.LoginSetup);
    const [error, setError] = useState("");

    const changeScreen = (s: SetupScreen) => {
        setSetupScreen(s);
        setError("");
    };

    return <Container id="login-page" sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${abstractShape})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    }}>
        <Typography sx={{ marginBottom: "44px", fontSize: "40px", lineHeight: "50px" }} variant="h1">nullafi</Typography>

        <Box sx={{ width: "480px" }}>
            <Alert
                severity={"error"}
                sx={{
                    marginBottom: "10px",
                    visibility: error === "" ? "hidden" : "visible",
                }}
            >
                {error}
            </Alert>
            <Card>
                {
                    (setupScreen === SetupScreen.LoginSetup && <LoginSetup setError={setError} samlSetup={() => changeScreen(SetupScreen.SAMLSetup)} />) ||
                    (setupScreen === SetupScreen.SAMLSetup && <SAMLSetup setError={setError} loginSetup={() => changeScreen(SetupScreen.LoginSetup)} />)
                }
            </Card>
            <Typography
                sx={{ marginTop: "50px" }}
                variant="body2"
                color="text.secondary"
                align="center"
            >
                {process.env.REACT_APP_VERSION}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
            >
                Copyright © Nullafi 2024
            </Typography>
        </Box>
    </Container>;
}

export default Register;
