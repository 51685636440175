import {
    Button,
    Card,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import DataTable from "components/Table";
import { ArrowDropDown, ArrowDropUp, Circle } from "@mui/icons-material";
import { AddDataType } from "../DataTypes/_components/AddDataType";
import { AddNewMaskFormat } from "../MaskFormats/AddMaskFormat";
import Flatpickr from "react-flatpickr";
import { Event } from "@mui/icons-material";

enum DateFilter {
  RANGE = 1,
  LAST = 2,
  BEFORE = 3,
}
interface Obfuscation {
  id?: string;
  name: string;
  description: string;
  datatypes: DatatypeObfuscation[];
  filters: ObfuscationFilter[];
}

function ObfuscationToAPIPayload(o: Obfuscation) {
    return {
        name: o.name,
        description: o.description,
        datatypes: o.datatypes.map((x) => {
            return {
                type: x.type,
                maskFormatId: x.maskFormatId,
                storeOriginalValue: x.storeOriginalValue,
                whitelist: x.whitelist,
            };
        }),
        filters: o.filters.map((x) => {
            return {
                type: x.type,
                filterType: x.filterType,
                isWhitelist: x.isWhitelist,
                filterParentLevel: x.filterParentLevel,
                condition: x.condition,
            };
        }),
    };
}

function APIPayloadToObfuscation(i: any): Obfuscation {
    return {
        name: i.name,
        description: i.description,
        datatypes:
      (i.datatypes &&
        i.datatypes.map((x: any) => {
            return {
                type: x.type,
                maskFormatId: x.maskFormatId,
                storeOriginalValue: x.storeOriginalValue,
                whitelist: x.whitelist,
            };
        })) ||
      [],
        filters:
      (i.filters &&
        i.filters.map((x: any) => {
            return {
                type: x.type,
                filterType: x.filterType,
                isWhitelist: x.isWhitelist,
                filterParentLevel: x.filterParentLevel,
                condition: x.condition,
            };
        })) ||
      [],
    };
}

interface DatatypeObfuscation {
  type: string;
  maskFormatId: string;
  storeOriginalValue: boolean;
  whitelist: string[];
}

interface DatatypeObfuscationWithId extends DatatypeObfuscation {
  id: string;
}

interface ObfuscationFilter {
  type: string;
  filterType: number;
  filterParentLevel: number;
  condition: ObfuscationFilterCondition;
  isWhitelist: boolean;
}

interface ObfuscationFilterCondition {
  dateCondition: DateCondition;
  stringCondition: StringCondition;
  numberCondition: numberCondition;
}

interface DateCondition {
  lowerThanOrEqual?: number;
  greaterThanOrEqual?: number;
  olderThan?: number;
  olderThanUnit: string;
  newerThan?: number;
  newerThanUnit: string;
  parseFormat: string;
}

interface StringCondition {
  equals?: string;
  regex?: string;
}

interface numberCondition {
  equals?: number;
  lowerThanOrEqual?: number;
  greaterThanOrEqual?: number;
}

interface ObfuscationFilterWithId extends ObfuscationFilter {
  id: string;
}

interface EditObfuscationProps {
  obfuscationId?: string;
  onSave?: (app: Obfuscation) => void;
  onExit?: () => void;
  onBackButtonClicked?: () => void;
}

type DataTypeList = { [key: string]: string };
type MaskFormatList = { [key: string]: string };

export default function EditObfuscation(props: EditObfuscationProps) {
    const login = useLogin();
    const notification = useNotification();

    const [startDateInput, setStartDateInput] = useState<any>(null);
    const [endDateInput, setEndDateInput] = useState<any>(null);

    const [currentScreen, setCurrentScreen] = useState("AddObfuscation");
    const [ready, setReady] = useState(false);
    const [saving, setSaving] = useState(false);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [datatypes, setDatatypes] = useState<DatatypeObfuscationWithId[]>([]);
    const [filters, setFilters] = useState<ObfuscationFilterWithId[]>([]);

    const [datatypeList, setDatatypeList] = useState({} as DataTypeList);
    const [maskFormatList, setMaskFormatList] = useState({} as MaskFormatList);

    const [datatypeBeingEdited, setDatatypeBeingEdited] = useState<
    DatatypeObfuscation | DatatypeObfuscationWithId | undefined
  >();

    const [currentMaskFormat, setCurrentMaskFormat] = useState("");
    const [currentObfuscationType, setCurrentObfuscationType] = useState("");
    const [currentStoreOriginalValue, setCurrentStoreOriginalValue] =
    useState(false);
    const [obfuscationAddItemError, setObfuscationAddItemError] = useState(false);

    const [filterAddItemError, setFilterAddItemError] = useState(false);
    const [currentFilterDataType, setCurrentFilterDataType] = useState("");
    const [currentFilterType, setCurrentFilterType] = useState(1);
    const [currentFilterParentLevel, setCurrentFilterParentLevel] = useState(
    1 as undefined | number
    );
    const [currentFilterParseFormat, setCurrentFilterParseFormat] =
    useState("Unix");
    const [currentFilterEquals, setCurrentFilterEquals] = useState(
    undefined as undefined | string
    );
    const [currentFilterRegex, setCurrentFilterRegex] = useState(
    undefined as undefined | string
    );
    const [currentFilterEqualsNumber, setCurrentFilterEqualsNumber] = useState(
    undefined as undefined | number
    );
    const [currentFilterGte, setCurrentFilterGte] = useState(
    undefined as undefined | number
    );
    const [currentFilterLte, setCurrentFilterLte] = useState(
    undefined as undefined | number
    );
    const [currentFilterOt, setCurrentFilterOt] = useState(
    undefined as undefined | number
    );
    const [currentFilterOtUnit, setCurrentFilterOtUnit] = useState("");
    const [currentFilterNt, setCurrentFilterNt] = useState(
    undefined as undefined | number
    );
    const [currentFilterNtUnit, setCurrentFilterNtUnit] = useState("");
    const [currentFilterIsWhitelist, setCurrentFilterIsWhitelist] =
    useState(false);
    const [currentFilterIndex, setCurrentFilterIndex] = useState(
    undefined as undefined | number
    );
    const [currentDateFilter, setCurrentDateFilter] = useState(DateFilter.RANGE);

    const changeCurrentDateFilter = (type: DateFilter) => {
        setCurrentDateFilter(type);
        setCurrentFilterOt(undefined);
        setCurrentFilterOtUnit("");
        setCurrentFilterNt(undefined);
        setCurrentFilterNtUnit("");
    };

    const changeCurrentFilterType = (type: number) => {
        setCurrentFilterType(type);
        setCurrentFilterEquals(undefined);
        setCurrentFilterRegex(undefined);
        setCurrentFilterEqualsNumber(undefined);
        setCurrentFilterGte(undefined);
        setCurrentFilterLte(undefined);
        setCurrentFilterOt(undefined);
        setCurrentFilterOtUnit("");
        setCurrentFilterNt(undefined);
        setCurrentFilterNtUnit("");
    };

    const [selectedTab, setSelectedTab] = useState("definitions");

    const today = new Date();

    const loadDataTypes = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            const datatypesResp = await req.get("/api/datatypes", {
                params: {
                    sortBy: "name",
                },
            });
            const datatypesTmp: DataTypeList = {};
            datatypesResp.data.items.forEach((x: any) => {
                datatypesTmp[x.type] = x.name;
            });
            setDatatypeList(datatypesTmp);
        } catch (err) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not load data type",
            });
            return;
        }
    };

    const loadMaskFormat = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            const maskFormatResp = await req.get("/api/maskformats", {
                params: {
                    sortBy: "name",
                },
            });
            const maskFormatsTmp: DataTypeList = {};
            maskFormatResp.data.items.forEach((x: any) => {
                maskFormatsTmp[x.id] = x.name;
            });
            setMaskFormatList(maskFormatsTmp);
        } catch (err) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not load mask format",
            });
            return;
        }
    };

    const loadData = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            await loadDataTypes();
            await loadMaskFormat();

            if (props.obfuscationId) {
                const obfuscationReq = await req.get(
                    `/api/obfuscations/${props.obfuscationId}`
                );

                const obfuscation = APIPayloadToObfuscation(obfuscationReq.data);

                setName(obfuscation.name);
                setDescription(obfuscation.description);
                setDatatypes(
                    obfuscation.datatypes.map((x, idx) => {
                        return { ...x, id: idx.toString() };
                    })
                );
                setFilters(
                    obfuscation.filters &&
            obfuscation.filters.map((x, idx) => {
                return { ...x, id: idx.toString() };
            })
                );
            }
        } catch (err) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        setReady(true);
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const validate = () => {
        if (!name || !name.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Name is required.",
            });

            return false;
        }

        if (!datatypes || !datatypes.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 definition for an Obfuscation.",
            });

            return false;
        }

        return true;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (!validate()) {
            return;
        }

        setSaving(true);

        try {
            if (!props.obfuscationId) {
                //create
                const resp = await req.post(
                    "/api/obfuscations",
                    ObfuscationToAPIPayload({
                        name: name,
                        description: description,
                        datatypes: datatypes,
                        filters: filters,
                    })
                );

                const addedApp = APIPayloadToObfuscation(resp.data);

                props.onSave && props.onSave(addedApp);

                notification.Display({
                    type: "success",
                    title: "Success",
                    message: "Obfuscation saved successfully",
                });
            } else {
                //update
                const resp = await req.put(
                    `/api/obfuscations/${props.obfuscationId}`,
                    ObfuscationToAPIPayload({
                        name: name,
                        description: description,
                        datatypes: datatypes,
                        filters: filters,
                    })
                );

                const addedApp = APIPayloadToObfuscation(resp.data);

                props.onSave && props.onSave(addedApp);

                notification.Display({
                    type: "success",
                    title: "Success",
                    message: "Obfuscation saved successfully",
                });
            }
        } catch (err) {
            setSaving(false);
            console.error(err);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not save app",
            });
            return;
        }
    };

    const dataTypeAdded = (
        datatype: DatatypeObfuscation | DatatypeObfuscationWithId
    ) => {
        setCurrentScreen("AddObfuscation");

        if (!("id" in datatype)) {
            const newDatatypes = datatypes.concat({
                ...datatype,
                id: datatypes.length.toString(),
            });
            setDatatypes(newDatatypes);
        } else {
            const newDatatypes = datatypes.map((x, idx) => {
                if (idx.toString() === datatype.id) {
                    return datatype;
                }
                return x;
            });
            setDatatypes(newDatatypes);
        }
    };

    const exit = () => {
        props.onExit && props.onExit();
    };

    const addDataType = () => {
        setObfuscationAddItemError(false);
        if (!currentObfuscationType) {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Data Type cannot be empty",
            });
            setObfuscationAddItemError(true);
            return;
        }
        if (!currentMaskFormat) {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Mask Format cannot be empty",
            });
            setObfuscationAddItemError(true);
            return;
        }

        setDatatypes(
            datatypes.concat({
                id: datatypes.length.toString(),
                maskFormatId: currentMaskFormat,
                type: currentObfuscationType,
                storeOriginalValue: currentStoreOriginalValue,
                whitelist: [],
            })
        );
        setCurrentMaskFormat("");
        setCurrentObfuscationType("");
        setCurrentStoreOriginalValue(false);
    };

    const saveFilter = () => {
        setFilterAddItemError(false);
        if (!currentFilterDataType) {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Data Type cannot be empty",
            });
            setFilterAddItemError(true);
            return;
        }

        let filter = {
            id: currentFilterIndex || filters.length.toString(),
            type: currentFilterDataType,
            filterType: currentFilterType,
            filterParentLevel: currentFilterParentLevel,
            isWhitelist: currentFilterIsWhitelist,
            condition: {} as ObfuscationFilterCondition,
        } as ObfuscationFilterWithId;

        switch (currentFilterType) {
        case 1:
            filter.condition.dateCondition = {
                olderThanUnit: "",
                newerThanUnit: "",
                parseFormat: currentFilterParseFormat || "Unix",
            };

            if (currentDateFilter === DateFilter.LAST) {
                filter.condition.dateCondition.newerThan = currentFilterNt;
                filter.condition.dateCondition.newerThanUnit = currentFilterNtUnit;
            }

            if (currentDateFilter === DateFilter.BEFORE) {
                filter.condition.dateCondition.olderThan = currentFilterOt;
                filter.condition.dateCondition.olderThanUnit = currentFilterOtUnit;
            }

            if (currentDateFilter === DateFilter.RANGE) {
                filter.condition.dateCondition.greaterThanOrEqual = currentFilterGte;
                filter.condition.dateCondition.lowerThanOrEqual = currentFilterLte;
            }
            break;
        case 2:
            filter.condition.stringCondition = {
                equals: currentFilterEquals,
                regex: currentFilterRegex,
            };
            break;
        case 3:
            filter.condition.numberCondition = {
                equals: currentFilterEqualsNumber,
                greaterThanOrEqual: currentFilterGte,
                lowerThanOrEqual: currentFilterLte,
            };
            break;
        }

        if (currentFilterIndex === undefined) {
            setFilters(filters.concat(filter));
        } else {
            let newFilters = filters.map((x, index) =>
                Number(index) === Number(currentFilterIndex) ? filter : x
            );
            console.log(newFilters);
            setFilters(newFilters);
        }

        clearFilter();
    };

    const clearFilter = () => {
        setCurrentFilterDataType("");
        setCurrentFilterEquals(undefined);
        setCurrentFilterEqualsNumber(undefined);
        setCurrentFilterGte(undefined);
        setCurrentFilterLte(undefined);
        setCurrentFilterNt(undefined);
        setCurrentFilterNtUnit("");
        setCurrentFilterOt(undefined);
        setCurrentFilterOtUnit("");
        setCurrentFilterParentLevel(1);
        setCurrentFilterParseFormat("Unix");
        setCurrentFilterRegex("");
        setCurrentFilterType(1);
        setCurrentFilterIsWhitelist(false);
        setCurrentFilterIndex(undefined);
        setCurrentDateFilter(DateFilter.RANGE);
    };

    const editFilter = (filter: ObfuscationFilter, index: number) => {
        setCurrentFilterDataType(filter.type);
        setCurrentFilterType(filter.filterType);
        setCurrentFilterParentLevel(filter.filterParentLevel);
        setCurrentFilterIsWhitelist(filter.isWhitelist);
        setCurrentFilterIndex(index);

        if (filter.condition.stringCondition) {
            setCurrentFilterEquals(filter.condition.stringCondition.equals);
            setCurrentFilterRegex(filter.condition.stringCondition.regex);
        }

        if (filter.condition.dateCondition) {
            setCurrentFilterGte(filter.condition.dateCondition.greaterThanOrEqual);
            setCurrentFilterLte(filter.condition.dateCondition.lowerThanOrEqual);
            setCurrentFilterNt(filter.condition.dateCondition.newerThan);
            setCurrentFilterNtUnit(filter.condition.dateCondition.newerThanUnit);
            setCurrentFilterOt(filter.condition.dateCondition.olderThan);
            setCurrentFilterOtUnit(filter.condition.dateCondition.olderThanUnit);
            setCurrentFilterParseFormat(filter.condition.dateCondition.parseFormat);

            if (filter.condition.dateCondition.newerThan)
                setCurrentDateFilter(DateFilter.LAST);

            if (filter.condition.dateCondition.olderThan)
                setCurrentDateFilter(DateFilter.BEFORE);

            if (
                filter.condition.dateCondition.greaterThanOrEqual ||
        filter.condition.dateCondition.lowerThanOrEqual
            )
                setCurrentDateFilter(DateFilter.RANGE);
        }

        if (filter.condition.numberCondition) {
            setCurrentFilterEqualsNumber(filter.condition.numberCondition.equals);
            setCurrentFilterGte(filter.condition.numberCondition.greaterThanOrEqual);
            setCurrentFilterLte(filter.condition.numberCondition.lowerThanOrEqual);
        }
    };

    const filterTypeList = {
        "1": "Date",
        "2": "String",
        "3": "Number",
    } as { [key: string]: string };

    const rowOptionsMenu = useMemo(
        () => [
            {
                icon: <EditIcon className="edit-button" fontSize="small" />,
                text: "Edit",
                onClick: (x: any) => {
                    setDatatypeBeingEdited(datatypes[x.id]);
                    setCurrentScreen("AddDatatype");
                },
            },
            {
                icon: <DeleteIcon className="delete-button" fontSize="small" />,
                text: "Delete",
                onClick: (x: any) => {
                    const newDatatypes = datatypes
                        .filter((i) => i.id !== x.id)
                        .map((y, idx) => {
                            return { ...y, id: idx.toString() };
                        });
                    setDatatypes(newDatatypes);
                },
            },
        ],
        [datatypes]
    );

    const filtersRowOptionsMenu = useMemo(
        () => [
            {
                icon: <EditIcon className="edit-button" fontSize="small" />,
                text: "Edit",
                onClick: (x: any) => {
                    editFilter(filters[x.id], x.id);
                },
            },
            {
                icon: <DeleteIcon className="delete-button" fontSize="small" />,
                text: "Delete",
                onClick: (x: any) => {
                    const newFilters = filters
                        .filter((i) => i.id !== x.id)
                        .map((y, idx) => {
                            return { ...y, id: idx.toString() };
                        });
                    setFilters(newFilters);
                },
            },
        ],
        [filters]
    );

    return (
        <Loading finished={ready}>
            <>
                {(currentScreen === "AddObfuscation" && (
                    <Stack
                        id="add-obfuscation-page"
                        sx={{
                            flex: 1,
                            height: "100%",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                        }}
                    >
                        <Box display="flex" sx={{ alignItems: "start" }}>
                            <Box>
                                {props.onBackButtonClicked ? (
                                    <Button
                                        id="back-button"
                                        startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                                        onClick={props.onBackButtonClicked}
                                    >
                    Back
                                    </Button>
                                ) : undefined}
                                <Typography sx={{ marginTop: "5px" }} variant="h2">
                                    {props.obfuscationId
                                        ? "Edit Obfuscation"
                                        : "Add New Obfuscation"}
                                </Typography>
                            </Box>
                            <IconButton
                                id="exit-button"
                                size="small"
                                sx={{ marginLeft: "auto", transform: "translate(20px, -10px)" }}
                                onClick={exit}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <TextField
                            sx={{ marginTop: "20px" }}
                            id="name-input"
                            label="Name"
                            variant="outlined"
                            value={name}
                            onChange={(x) => setName(x.target.value)}
                        />

                        <TextField
                            sx={{ marginTop: "20px" }}
                            id="description-input"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(x) => setDescription(x.target.value)}
                        />

                        <TabContext value={selectedTab}>
                            <Box
                                sx={{
                                    marginTop: "40px",
                                    borderBottom: 1,
                                    borderColor: "divider",
                                }}
                            >
                                <Tabs
                                    value={selectedTab}
                                    onChange={(e, value) => setSelectedTab(value)}
                                >
                                    <Tab
                                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                                        label="Definitions"
                                        id="definition-tab-item"
                                        value="definitions"
                                    />
                                    <Tab
                                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                                        label="Conditional Matching"
                                        id="filter-tab-item"
                                        value="filters"
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel
                                sx={{ height: "100%", padding: "10px" }}
                                value="definitions"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl
                                            sx={{ marginTop: "20px" }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel id="data-type-label">Data Type</InputLabel>
                                            <Select
                                                labelId="data-type-label"
                                                id="data-type-select"
                                                label="Data Type"
                                                autoWidth={true}
                                                value={currentObfuscationType}
                                                onChange={(x) =>
                                                    setCurrentObfuscationType(x.target.value)
                                                }
                                                error={obfuscationAddItemError}
                                            >
                                                {Object.entries(datatypeList)
                                                    .sort((a, b) => a[1].localeCompare(b[1]))
                                                    .map(([key, n]) => (
                                                        <MenuItem key={key} value={key}>
                                                            {n}
                                                        </MenuItem>
                                                    ))}
                                                <Box
                                                    sx={{
                                                        height: "40px",
                                                        marginBottom: "-8px",
                                                        backgroundColor: "#e2f2ff",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography>Want to add a Data Type?</Typography>
                                                    <Link
                                                        id="new-data-type-button"
                                                        component="button"
                                                        onClick={() => setCurrentScreen("NewDatatype")}
                                                    >
                            Click here
                                                    </Link>
                                                </Box>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            sx={{ marginTop: "20px" }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel id="mask-format-label">
                        Mask Format
                                            </InputLabel>
                                            <Select
                                                labelId="mask-format-label"
                                                id="mask-format-select"
                                                label="Mask Format"
                                                inputProps={{
                                                    sx: {
                                                        minWidth: "275px",
                                                    },
                                                }}
                                                value={currentMaskFormat}
                                                onChange={(x) => setCurrentMaskFormat(x.target.value)}
                                                error={obfuscationAddItemError}
                                            >
                                                {Object.entries(maskFormatList).map(([key, n]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {n}
                                                    </MenuItem>
                                                ))}
                                                <Box
                                                    sx={{
                                                        height: "40px",
                                                        marginBottom: "-8px",
                                                        backgroundColor: "#e2f2ff",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "5px",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <Typography>Want to add a Mask Format?</Typography>
                                                    <Link
                                                        id="new-mask-format-button"
                                                        component="button"
                                                        onClick={() => setCurrentScreen("NewMaskformat")}
                                                    >
                            Click here
                                                    </Link>
                                                </Box>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={currentStoreOriginalValue}
                                                    onChange={(e) =>
                                                        setCurrentStoreOriginalValue(e.target.checked)
                                                    }
                                                />
                                            }
                                            label="Store Data Values"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            id="add-data-type-button"
                                            sx={{ height: "40px" }}
                                            variant="outlined"
                                            fullWidth
                                            onClick={addDataType}
                                        >
                      Add
                                        </Button>
                                    </Grid>
                                </Grid>

                                <DataTable
                                    data={datatypes}
                                    columnsDataId="dataGrid_obfuscationDataTypeTable_columns"
                                    columns={{
                                        datatype: {
                                            displayName: "Data Type",
                                            render: (x) => {
                                                return datatypeList[x.type];
                                            },
                                        },
                                        maskFormat: {
                                            displayName: "Mask Format",
                                            render: (x) => {
                                                return maskFormatList[x.maskFormatId];
                                            },
                                        },
                                        storeOriginalValue: {
                                            displayName: "Values Stored",
                                            sortable: true,
                                            render: (x) => {
                                                return x.storeOriginalValue ? (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <Circle
                                                            sx={{ transform: "scale(0.6)" }}
                                                            fontSize="small"
                                                            color="success"
                                                        />
                                                        <span>Yes</span>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <Circle
                                                            sx={{ transform: "scale(0.6)" }}
                                                            fontSize="small"
                                                            color="error"
                                                        />
                                                        <span>No</span>
                                                    </Box>
                                                );
                                            },
                                        },
                                    }}
                                    columnsState={[
                                        { columnId: "datatype", width: "35%" },
                                        { columnId: "maskFormat", width: "35%" },
                                        { columnId: "storeOriginalValue", width: "30%" },
                                    ]}
                                    rowOptionsMenu={rowOptionsMenu}
                                    finishedLoading
                                />
                            </TabPanel>

                            <TabPanel
                                sx={{ height: "100%", padding: "10px" }}
                                value="filters"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl
                                            sx={{ marginTop: "20px" }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel id="filter-data-type-label">
                        Data Type
                                            </InputLabel>
                                            <Select
                                                labelId="filter-data-type-label"
                                                id="filter-data-type-select"
                                                label="Type"
                                                autoWidth={true}
                                                value={currentFilterDataType}
                                                onChange={(x) =>
                                                    setCurrentFilterDataType(x.target.value)
                                                }
                                                error={filterAddItemError}
                                            >
                                                {Object.entries(datatypeList)
                                                    .sort((a, b) => a[1].localeCompare(b[1]))
                                                    .map(([key, n]) => (
                                                        <MenuItem key={key} value={key}>
                                                            {n}
                                                        </MenuItem>
                                                    ))}
                                                <Box
                                                    sx={{
                                                        height: "40px",
                                                        marginBottom: "-8px",
                                                        backgroundColor: "#e2f2ff",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography>Want to add a Data Type?</Typography>
                                                    <Link
                                                        id="new-data-type-button"
                                                        component="button"
                                                        onClick={() => setCurrentScreen("NewDatatype")}
                                                    >
                            Click here
                                                    </Link>
                                                </Box>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl
                                            sx={{ marginTop: "20px" }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel id="filter-type-label">
                        Filter Type
                                            </InputLabel>
                                            <Select
                                                labelId="filter-type-label"
                                                id="filter-type-select"
                                                label="Filter Type"
                                                value={currentFilterType}
                                                onChange={(x) =>
                                                    changeCurrentFilterType(Number(x.target.value))
                                                }
                                                error={filterAddItemError}
                                            >
                                                {["Date", "String", "Number"].map((n, key) => (
                                                    <MenuItem key={key + 1} value={key + 1}>
                                                        {n}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl
                                            sx={{ marginTop: "20px" }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <TextField
                                                id="parent-level-select"
                                                label="Parent Level"
                                                type="number"
                                                value={currentFilterParentLevel}
                                                onChange={(x) =>
                                                    setCurrentFilterParentLevel(
                                                        Number(x.target.value) || undefined
                                                    )
                                                }
                                                error={filterAddItemError}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {currentFilterType === 1 ? (
                                        <>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    sx={{ marginTop: "20px" }}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <InputLabel id="filter-type-label">
                            Date Filter
                                                    </InputLabel>
                                                    <Select
                                                        labelId="date-filter-type-label"
                                                        id="date-filter-type-select"
                                                        label="Date Filter"
                                                        value={currentDateFilter}
                                                        onChange={(x) =>
                                                            changeCurrentDateFilter(
                                Number(x.target.value) as DateFilter
                                                            )
                                                        }
                                                        error={filterAddItemError}
                                                    >
                                                        {["Range", "Within the last", "More than"].map(
                                                            (n, key) => (
                                                                <MenuItem key={key + 1} value={key + 1}>
                                                                    {n}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {currentDateFilter === DateFilter.BEFORE && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <Stack direction={"row"} sx={{ marginTop: "20px" }}>
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                value={currentFilterOt}
                                                                onChange={(x) => {
                                                                    setCurrentFilterOt(Number(x.target.value));
                                                                }}
                                                            />
                                                            <Select
                                                                fullWidth
                                                                value={currentFilterOtUnit}
                                                                onChange={(x) =>
                                                                    setCurrentFilterOtUnit(x.target.value as any)
                                                                }
                                                            >
                                                                <MenuItem value={"minutes"}>minutes</MenuItem>
                                                                <MenuItem value={"hours"}>hours</MenuItem>
                                                                <MenuItem value={"days"}>days</MenuItem>
                                                            </Select>
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}
                                            {currentDateFilter === DateFilter.LAST && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <Stack direction={"row"} sx={{ marginTop: "20px" }}>
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                value={currentFilterNt}
                                                                onChange={(x) => {
                                                                    setCurrentFilterNt(Number(x.target.value));
                                                                }}
                                                            />
                                                            <Select
                                                                fullWidth
                                                                value={currentFilterNtUnit}
                                                                onChange={(x) =>
                                                                    setCurrentFilterNtUnit(x.target.value as any)
                                                                }
                                                            >
                                                                <MenuItem value={"minutes"}>minutes</MenuItem>
                                                                <MenuItem value={"hours"}>hours</MenuItem>
                                                                <MenuItem value={"days"}>days</MenuItem>
                                                            </Select>
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}
                                            {currentDateFilter === DateFilter.RANGE && (
                                                <>
                                                    <Grid item xs={6} />
                                                    <Grid item xs={6}>
                                                        <InputLabel id="gte-label">Greater Than</InputLabel>
                                                        <Flatpickr
                                                            ref={(el: any) => {
                                                                if (el) {
                                                                    el.node.id = "start-date-input";
                                                                }
                                                                setStartDateInput(el);
                                                            }}
                                                            data-enable-time
                                                            value={currentFilterGte}
                                                            options={{
                                                                appendTo: document.body,
                                                                position: "below",
                                                                dateFormat: "m/d/Y",
                                                                minDate: new Date(2020, 1, 1),
                                                                maxDate: currentFilterGte || today,
                                                            }}
                                                            render={(
                                                                { defaultValue, value, ...props },
                                                                ref
                                                            ) => {
                                                                return (
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        defaultValue={defaultValue}
                                                                        inputRef={ref}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        startDateInput &&
                                              startDateInput.flatpickr.open();
                                                                                    }}
                                                                                >
                                                                                    <Event />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                        sx={{
                                                                            maxWidth: "170px",
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                            onChange={([date]) => {
                                                                const clone = new Date(date);
                                                                clone.setHours(0, 0, 0);
                                                                setCurrentFilterGte(Number(clone));
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <InputLabel id="lte-label">Lower Than</InputLabel>
                                                        <Flatpickr
                                                            ref={(el: any) => {
                                                                if (el) {
                                                                    el.node.id = "end-date-input";
                                                                }
                                                                setEndDateInput(el);
                                                            }}
                                                            data-enable-time
                                                            value={currentFilterLte}
                                                            options={{
                                                                appendTo: document.body,
                                                                position: "below",
                                                                dateFormat: "m/d/Y",
                                                                minDate: currentFilterGte,
                                                            }}
                                                            render={(
                                                                { defaultValue, value, ...props },
                                                                ref
                                                            ) => {
                                                                return (
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        defaultValue={defaultValue}
                                                                        inputRef={ref}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        endDateInput &&
                                              endDateInput.flatpickr.open();
                                                                                    }}
                                                                                >
                                                                                    <Event />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                        sx={{
                                                                            maxWidth: "170px",
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                            onChange={([date]) => {
                                                                const clone = new Date(date);
                                                                clone.setHours(0, 0, 0);
                                                                setCurrentFilterLte(Number(clone));
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    ) : undefined}
                                    {currentFilterType === 2 ? (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    sx={{ marginTop: "20px" }}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="regex-string-input"
                                                        label="Regex"
                                                        value={currentFilterRegex}
                                                        onChange={(x) =>
                                                            setCurrentFilterRegex(x.target.value)
                                                        }
                                                        error={filterAddItemError}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ) : undefined}
                                    {currentFilterType === 3 ? (
                                        <>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    sx={{ marginTop: "20px" }}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="gte-number-input"
                                                        label="Greater or Eq Than"
                                                        type="number"
                                                        value={currentFilterGte}
                                                        onChange={(x) =>
                                                            setCurrentFilterGte(
                                                                Number(x.target.value) || undefined
                                                            )
                                                        }
                                                        error={filterAddItemError}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    sx={{ marginTop: "20px" }}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <TextField
                                                        id="lte-number-input"
                                                        label="Lower or Eq Than"
                                                        type="number"
                                                        value={currentFilterLte}
                                                        onChange={(x) =>
                                                            setCurrentFilterLte(
                                                                Number(x.target.value) || undefined
                                                            )
                                                        }
                                                        error={filterAddItemError}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ) : undefined}
                                    <Grid item xs={12}>
                                        <FormControl
                                            component="fieldset"
                                            error={filterAddItemError}
                                        >
                                            <FormLabel component="legend">Behavior</FormLabel>
                                            <RadioGroup
                                                id="behavior-radio-group"
                                                value={currentFilterIsWhitelist ? "true" : "false"}
                                                name="behaviour"
                                                onChange={(x) =>
                                                    setCurrentFilterIsWhitelist(x.target.value === "true")
                                                }
                                                row
                                            >
                                                <FormControlLabel
                                                    id="behavior-radio-option-match"
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Match"
                                                />
                                                <FormControlLabel
                                                    id="behavior-radio-option-bypass"
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Bypass"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {currentFilterIndex !== undefined ? (
                                            <Box display="flex" marginBottom="15px">
                                                <Button
                                                    id="cancel-button"
                                                    variant="text"
                                                    size="medium"
                                                    fullWidth
                                                    onClick={() => {
                                                        clearFilter();
                                                    }}
                                                >
                          Cancel
                                                </Button>
                                                <Button
                                                    id="save-button"
                                                    variant="outlined"
                                                    size="medium"
                                                    fullWidth
                                                    onClick={saveFilter}
                                                >
                          Save
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Button
                                                id="add-filter-button"
                                                sx={{ height: "40px" }}
                                                variant="outlined"
                                                fullWidth
                                                onClick={saveFilter}
                                            >
                        Add
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                                <DataTable
                                    data={filters}
                                    columnsDataId="dataGrid_obfuscationFilterTable_columns"
                                    columns={{
                                        datatype: {
                                            displayName: "Data Type",
                                            render: (x: any) => {
                                                return datatypeList[x.type];
                                            },
                                        },
                                        filterType: {
                                            displayName: "Filter Type",
                                            render: (x: any) => {
                                                return filterTypeList[x.filterType.toString()];
                                            },
                                        },
                                        behavior: {
                                            displayName: "Behaviour",
                                            render: (x: any) => {
                                                return x.isWhitelist ? "Bypass" : "Match";
                                            },
                                        },
                                    }}
                                    columnsState={[
                                        { columnId: "datatype", width: "34%" },
                                        { columnId: "filterType", width: "33%" },
                                        { columnId: "behavior", width: "33%" },
                                    ]}
                                    rowOptionsMenu={filtersRowOptionsMenu}
                                    finishedLoading
                                />
                            </TabPanel>
                        </TabContext>

                        <Divider sx={{ marginTop: "10px" }} />
                        <Box display="flex" sx={{ marginTop: "10px" }}>
                            <Button
                                id="cancel-button"
                                variant="text"
                                size="medium"
                                onClick={exit}
                            >
                Cancel
                            </Button>
                            <LoadingButton
                                id="save-button"
                                loading={saving}
                                sx={{ marginLeft: "auto" }}
                                variant="contained"
                                size="medium"
                                onClick={save}
                            >
                Save
                            </LoadingButton>
                        </Box>
                    </Stack>
                )) ||
          (currentScreen === "AddDatatype" && (
              <AddObfuscationDataType
                  initialValue={datatypeBeingEdited}
                  maskFormats={maskFormatList}
                  dataTypes={datatypeList}
                  onDatatypeAdded={dataTypeAdded}
                  onBackButtonClicked={() => {
                      setCurrentScreen("AddObfuscation");
                  }}
                  onExit={exit}
              />
          )) ||
          (currentScreen === "NewDatatype" && (
              <AddDataType
                  onSave={() => {
                      setCurrentScreen("AddObfuscation");
                      loadDataTypes();
                  }}
                  onBackButtonClicked={() => {
                      setCurrentScreen("AddObfuscation");
                  }}
                  onExit={exit}
              />
          )) ||
          (currentScreen === "NewMaskformat" && (
              <AddNewMaskFormat
                  onSave={() => {
                      setCurrentScreen("AddObfuscation");
                      loadMaskFormat();
                  }}
                  onBackButtonClicked={() => {
                      setCurrentScreen("AddObfuscation");
                  }}
                  onExit={exit}
              />
          ))}
            </>
        </Loading>
    );
}

interface AddObfuscationDataTypeProps {
  initialValue?: DatatypeObfuscation | DatatypeObfuscationWithId;
  maskFormats: MaskFormatList;
  dataTypes: DataTypeList;
  onBackButtonClicked?: () => void;
  onExit?: () => void;
  onDatatypeAdded: (
    datatype: DatatypeObfuscation | DatatypeObfuscationWithId
  ) => void;
}

function AddObfuscationDataType(props: AddObfuscationDataTypeProps) {
    const notification = useNotification();

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const [type, setType] = useState(props.initialValue?.type || "");
    const [maskFormat, setMaskFormat] = useState(
        props.initialValue?.maskFormatId || ""
    );
    const [whitelist, setWhitelist] = useState<string[]>(
        props.initialValue?.whitelist || []
    );
    const [storeOriginalValue, setStoreOriginalValue] = useState<boolean>(
        props.initialValue?.storeOriginalValue || false
    );

    const [whitelistBeingAdded, setWhitelistBeingAdded] = useState("");

    const save = () => {
        setValidationError(false);

        if (type === "") {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Must select a type",
            });
            setValidationError(true);
            return;
        }
        if (maskFormat === "") {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Must select a mask format",
            });
            setValidationError(true);
            return;
        }

        props.onDatatypeAdded({
            ...(props.initialValue &&
        "id" in props.initialValue && { id: props.initialValue?.id }),
            type: type,
            maskFormatId: maskFormat,
            whitelist: whitelist,
            storeOriginalValue: storeOriginalValue,
        });
    };

    const addWhitelistItem = () => {
        if (whitelistBeingAdded.trim() === "") {
            notification.Display({
                type: "error",
                title: "Error",
                message: "Whitelist element cannot be blank",
            });
            return;
        }
        const newWhitelist = whitelist.concat(whitelistBeingAdded);
        setWhitelist(newWhitelist);
        setWhitelistBeingAdded("");
    };

    const deleteWhitelistItem = (index: number) => {
        const newWhitelist = whitelist.filter((_, idx) => {
            return idx !== index;
        });
        setWhitelist(newWhitelist);
    };

    return (
        <Stack id="edit-obfuscation-data-type" sx={{ flex: 1 }}>
            <Box display="flex" marginBottom="20px" alignItems={"flex-start"}>
                <Box>
                    {props.onBackButtonClicked ? (
                        <Button
                            id="back-button"
                            startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                            onClick={props.onBackButtonClicked}
                        >
              Back
                        </Button>
                    ) : undefined}
                    <Typography variant="h2">
                        {props.initialValue ? "Edit Definition" : "Add Definition"}
                    </Typography>
                </Box>

                <IconButton
                    id="exit-button"
                    size="small"
                    sx={{ marginLeft: "auto", transform: "translate(20px, -10px)" }}
                    onClick={props.onExit}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <InputLabel id="data-type-label">Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    id="data-type-select"
                    label="Type"
                    value={type}
                    onChange={(x) => setType(x.target.value)}
                    error={validationError}
                >
                    {Object.entries(props.dataTypes)
                        .sort((a, b) => a[1].localeCompare(b[1]))
                        .map(([key, name]) => (
                            <MenuItem key={key} value={key}>
                                {name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>

            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <InputLabel id="mask-format-label">Mask Format</InputLabel>
                <Select
                    labelId="mask-format-label"
                    id="mask-format-select"
                    label="Mask Format"
                    value={maskFormat}
                    onChange={(x) => setMaskFormat(x.target.value)}
                    error={validationError}
                >
                    {Object.entries(props.maskFormats).map(([key, name]) => (
                        <MenuItem key={key} value={key}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={storeOriginalValue}
                            onChange={(e) => setStoreOriginalValue(e.target.checked)}
                        />
                    }
                    label="Store Data Values"
                />
            </FormControl>

            <Typography
                id="advanced-settings-toggle"
                variant="h2"
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": { color: "#2158c3" },
                }}
                onClick={() => setShowAdvanced(!showAdvanced)}
            >
                {showAdvanced ? <ArrowDropUp /> : <ArrowDropDown />}
                <Typography
                    component={"span"}
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
          Advanced Settings
                </Typography>
            </Typography>
            {showAdvanced ? (
                <Card
                    sx={{
                        padding: "10px",
                        marginTop: "20px",
                    }}
                >
                    <Box display={"flex"}>
                        <TextField
                            id="whitelist-input"
                            label="Whitelist"
                            variant="outlined"
                            sx={{
                                flexGrow: 1,
                            }}
                            value={whitelistBeingAdded}
                            onChange={(x) => setWhitelistBeingAdded(x.target.value)}
                            error={validationError}
                            onKeyUp={(x) =>
                                x.key === "Enter" ? addWhitelistItem() : undefined
                            }
                        />
                        <Button
                            variant="outlined"
                            onClick={addWhitelistItem}
                            sx={{
                                marginLeft: "5px",
                            }}
                        >
              Add
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            marginTop: "10px",
                        }}
                    >
                        {whitelist.map((x, idx) => {
                            return (
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    key={idx}
                                    label={x}
                                    onDelete={() => deleteWhitelistItem(idx)}
                                />
                            );
                        })}
                    </Box>
                </Card>
            ) : null}

            <Divider sx={{ marginTop: "auto" }} />
            <Box display="flex" sx={{ marginTop: "10px" }}>
                <Button
                    id="back-button"
                    variant="text"
                    size="medium"
                    onClick={props.onExit}
                >
          Cancel
                </Button>
                <Button
                    id="save-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="medium"
                    onClick={save}
                >
                    {!props.initialValue ? "Add Definition" : "Save Definition"}
                </Button>
            </Box>
        </Stack>
    );
}
