import { LoadingButton } from "@mui/lab";
import { Typography, Grid, TextField, Switch, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import TestConfig from "./TestConfig";

interface SMTPConfigProps {
  data: SMTPConfigForm | undefined;
  onSaveChanges: (data: SMTPConfigForm) => void;
}

export interface SMTPConfigForm {
    SmartHost: string;
    From: string;
    To: string;
    Username: string;
    Password: string;
    Identity: string
    RequireTLS: boolean;
}

function SMTPConfiguration(props: SMTPConfigProps) {
    const [smtpSmarthost, setSmtpSmarthost] = useState("");
    const [smtpFromAddress, setSmtpFromAddress] = useState("");
    const [smtpToAddress, setSmtpToAddress] = useState("");
    const [smtpUsername, setSmtpUsername] = useState("");
    const [smtpUserPassword, setSmtpUserPassword] = useState("");
    const [smtpUserIdentity, setSmtpUserIdentity] = useState("");
    const [smtpRequireTLS, setSmtpRequireTLS] = useState(false);
    const [tlsToggle, setTlsToggle] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (props.data) {
            setSmtpSmarthost(props.data.SmartHost);
            setSmtpFromAddress(props.data.From);
            setSmtpToAddress(props.data.To);
            setSmtpUsername(props.data.Username);
            setSmtpUserPassword(props.data.Password);
            setSmtpUserIdentity(props.data.Identity);
            setSmtpRequireTLS(props.data.RequireTLS);
            setTlsToggle(props.data.RequireTLS);
            setLoaded(true);
        }
    }, [props.data]);

    useEffect(() => {
        setDisabled(true);
        setIsSaved(false);
    }, [isSaved, smtpFromAddress, smtpSmarthost, smtpUserPassword, smtpUsername, smtpToAddress]);

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTlsToggle(event.target.checked);
        setSmtpRequireTLS(event.target.checked);
    };

    useEffect(() => {
        if (isSaved || loaded) {
            setDisabled(false);
        }
    }, [isSaved, loaded]);

    const handleSave = async () => {
        setIsSaving(true);
        props.onSaveChanges({
            SmartHost: smtpSmarthost,
            From: smtpFromAddress,
            To: smtpToAddress,
            Username: smtpUsername,
            Password: smtpUserPassword,
            Identity: smtpUserIdentity,
            RequireTLS: smtpRequireTLS,
        });
        setIsSaved(true);
        setIsSaving(false);
    };

    return (
        <Stack sx={{ mt: 2}}>
            <Grid container spacing={3} >
                <Grid item >
                    <TextField
                        id="smtp-smarthost-id-input"
                        label="Smarthost"
                        fullWidth
                        variant="outlined"
                        value={smtpSmarthost}
                        onChange={(x) => setSmtpSmarthost(x.target.value)}
                    />
                </Grid>               
                <Grid item>
                    <TextField
                        id="smtp-from-id-input"
                        label="From Address"
                        fullWidth
                        variant="outlined"
                        value={smtpFromAddress}
                        onChange={(x) => setSmtpFromAddress(x.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="smtp-to-id-input"
                        label="Default Recipient Address"
                        fullWidth
                        variant="outlined"
                        value={smtpToAddress}
                        onChange={(x) => setSmtpToAddress(x.target.value)}
                    />
                </Grid>                
                <Grid item>
                    <TextField
                        id="smtp-username-id-input"
                        label="Username"
                        fullWidth
                        variant="outlined"
                        value={smtpUsername}
                        onChange={(x) => setSmtpUsername(x.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="smtp-password-secret-input"
                        label="Password"
                        fullWidth
                        variant="outlined"
                        type="password"
                        value={smtpUserPassword}
                        onChange={(x) => setSmtpUserPassword(x.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="smtp-user-identity-input"
                        label="User Identity"
                        fullWidth
                        variant="outlined"
                        value={smtpUserIdentity}
                        onChange={(x) => setSmtpUserIdentity(x.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            marginTop: "10px",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Switch checked={tlsToggle} onChange={handleToggle} />
                        <Typography>Require TLS</Typography>
                    </Box>
                </Grid>
            </Grid>           
                       
            <Grid container spacing={1} sx={{ mt: 2}}>
                <Grid item xs={8} />
                <Grid item xs={2}>
                    <TestConfig notifier="smtp" disabled={disabled}></TestConfig>
                </Grid>                 
                <Grid item xs={2}>            
                    <LoadingButton
                        id="save-changes-button"
                        variant="contained"
                        sx={{ marginLeft: "auto" }}
                        loading={isSaving}
                        onClick={handleSave}
                    >
                    Save Changes
                    </LoadingButton>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default SMTPConfiguration;