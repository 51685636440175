import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import EditRule from "./EditRule";
import DataTable, { RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";

interface RulesListState {
    items: RulesListStateItem[]
}

type columnsType = "name" | "apps" | "obfuscations" | "users" | "groups" | "status" | "createdat" | "updatedat";

interface RulesListStateItem {
    id: string
    name: string
    active: boolean
    createdAt: Date
    updatedAt: Date
    apps: string[]
    obfuscations: string[]
    userFilters: string[]
    groupFilters: string[]
}

export default function Rules() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();

    const [finishedLoading, setFinishedLoading] = useState(false);

    const [listState, setListState] = useState({ items: [] } as RulesListState);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogRuleId, setDeleteDialogRuleId] = useState("");

    const [addRuleDrawer, setAddRuleDrawer] = useState(false);
    const [editingRuleId, setEditingRuleId] = useState("");

    const req = login.GetAxios();

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "20%" },
        { columnId: "apps", width: "16%"},
        { columnId: "obfuscations", width: "16%" },
        { columnId: "users", width: "16%" },
        { columnId: "groups", width: "16%" },
        { columnId: "status", width: "12%" },
        { columnId: "createdat", width: "18%" },
        { columnId: "updatedat", width: "18%" },
    ]);

    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const resp = await req.get("/api/rules");

            setListState({
                items: (resp.data.items || []).map((x: any) => {
                    const item: RulesListStateItem = {
                        id: x.id,
                        name: x.name,
                        active: x.enable,
                        createdAt: new Date(x.createdAt * 1000),
                        updatedAt: new Date(x.updatedAt * 1000),
                        apps: x.apps.map((x: any) => x.name),
                        obfuscations: x.obfuscations.map((x: any) => x.name),
                        userFilters: x.userFilters,
                        groupFilters: x.groupFilters,
                    };
                    return item;
                })
            });

            setFinishedLoading(true);
        } catch (err) {
            console.error(err);
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const addOrEditRule = (id?: string) => {
        setEditingRuleId(id || "");
        setAddRuleDrawer(true);
    };

    const deleteRuleDialog = (name: string, id: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the rule "${name}"?`);
        setDeleteDialogRuleId(id);
    };

    const deleteRule = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/rules/${deleteDialogRuleId}`);
            loadData();
            notification.Display({
                type: "success",
                title: "Success",
                message: "Rule deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete rule",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };

    const setRuleEnabled = async (id: string, state: boolean) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.put(`/api/rules/${id}/enable`, {
                enable: state,
            });
            loadData();
            notification.Display({
                type: "success",
                title: "Success",
                message: "Rule status updated successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not update rule status",
                type: "error",
            });
        }
    };

    const onRowOrderChanged = async (newList: RulesListStateItem[]) => {
        const ids = newList.map(x => x.id);

        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            setListState({items: newList});
            await req.post("/api/rules/ruleorder", ids);
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not change rule order",
                type: "error",
            });
            //reload the old values
            setListState({items: listState.items.map(x => x)});
        }
    };

    const getHeaderFilterText = (filter: any) => {
        const conditionText = {
            "equal": "equals",
            "not_equal": "does not equal",
            "contains": "contains",
            "not_contains": "does not contain",
        } as any;

        return `${conditionText[filter.condition]} "${filter.filter}"`;
    };


    useEffect(() => {
        setColumnsState(RestoreColumnsState("rules",columnsState));
        loadData();
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState("rules", columnsState);
    }, [columnsState]);

    return <>
        <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
            <Box display="flex">
                <Typography variant="h1">Rules</Typography>
                <Button
                    id="new-rule-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => addOrEditRule()}
                >
                    Add New Rule
                </Button>
            </Box>
            <DataTable
                onColumnOrderChanged={x => setColumnsState(x)}
                onRowOrderChanged={onRowOrderChanged}
                data={listState.items}
                finishedLoading={finishedLoading}
                columnsDataId="dataGrid_ruleTable_columns"
                columnsState={columnsState}
                columns={{
                    name: {
                        displayName: "Name",
                        render: (x) => {
                            return x.name;
                        },
                    },
                    apps: {
                        displayName: "Apps",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "column",
                                gap: "2px",
                            }}>
                                {x.apps.map(y => <Chip label={y} variant="outlined" color="primary" size="small" />)}
                            </Box>;
                        },
                    },
                    obfuscations: {
                        displayName: "Obfuscations",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "column",
                                gap: "2px",
                            }}>
                                {x.obfuscations.map(y => <Chip label={y} variant="outlined" color="primary" size="small" />)}
                            </Box>;
                        },
                    },
                    users: {
                        displayName: "User Filters",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "column",
                                gap: "2px",
                            }}>
                                {x.userFilters.map(y => <Chip label={getHeaderFilterText(y)} variant="outlined" color="primary" size="small" />)}
                            </Box>;
                        },
                    },
                    groups: {
                        displayName: "Group Filters",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "column",
                                gap: "2px",
                            }}>
                                {x.groupFilters.map(y => <Chip label={getHeaderFilterText(y)} variant="outlined" color="primary" size="small" />)}
                            </Box>;
                        },
                    },
                    status: {
                        displayName: "Status",
                        render: (x) => {
                            return x.active
                                ? <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <CircleIcon sx={{transform: "scale(0.6)"}} fontSize="small" color="success" /><span>Active</span>
                                </Box>
                                : <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <CircleIcon sx={{transform: "scale(0.6)"}} fontSize="small" color="error" /><span>Inactive</span>
                                </Box>;
                        }
                    },
                    createdat: {
                        displayName: "Created At",
                        render: (x) => {
                            return `${x.createdAt.toLocaleDateString()} ${x.createdAt.toLocaleTimeString()}`;
                        },
                    },
                    updatedat: {
                        displayName: "Updated At",
                        render: (x) => {
                            return `${x.updatedAt.toLocaleDateString()} ${x.updatedAt.toLocaleTimeString()}`;
                        },
                    },

                }}
                rowOptionsMenu={[
                    {
                        icon: <EditIcon className="edit-button" fontSize="small" />,
                        text: "Edit Rule Details",
                        onClick: (x) => {
                            addOrEditRule(x.id);
                        }
                    },
                    {
                        icon: (x) => {
                            if(x.active) {
                                return <ToggleOffOutlinedIcon/>;
                            }
                            return <ToggleOnOutlinedIcon/>;
                        },
                        text: (x) => {
                            if(x.active) {
                                return "Turn Off";
                            } 
                            return "Turn On";
                        },
                        onClick: (x) => {
                            setRuleEnabled(x.id, !x.active);
                        }
                    },
                    {
                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                        text: "Delete",
                        onClick: (x) => {
                            deleteRuleDialog(x.name, x.id);
                        }
                    },
                ]}
            />
        </Box>

        <Dialog open={deleteDialog}>
            <DialogTitle>Delete Rule</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteRule}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addRuleDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <EditRule 
                    onExit={() => setAddRuleDrawer(false)} 
                    onSave={() => {loadData(); setAddRuleDrawer(false); }}
                    ruleId={editingRuleId}
                />
            </Box>
        </Drawer>
    </>;
}