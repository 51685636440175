import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditObfuscation from "./EditObfuscation";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";

interface ObfuscationsListState {
    items: ObfuscationsListStateItem[]
}

interface RuleState {
    id: string
    name: string
}

interface ObfuscationsListStateItem {
    id: string
    name: string
    createdAt: Date
    updatedAt: Date
    rules?: RuleState[]
}

type columnsType = "name" | "usedby" | "createdat" | "updatedat";

export default function Obfuscations() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();

    const [finishedLoading, setFinishedLoading] = useState(false);

    const [listState, setListState] = useState({ items: [] } as ObfuscationsListState);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogObfuscationId, setDeleteDialogObfuscationId] = useState("");

    const [addObfuscationDrawer, setAddObfuscationDrawer] = useState(false);
    const [editingObfuscationId, setEditingObfuscationId] = useState("");

    const columnsDataId = "dataGrid_obfuscationTable_columns";

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "40%" },
        { columnId: "usedby", width: "20%"},
        { columnId: "createdat", width: "20%", direction: "desc", sortOrder: 1 },
        { columnId: "updatedat", width: "20%" },
    ]);

    const [skipItems, setSkipItems] = useState(0);
    const [takeCount, setTakeCount] = useState(25);
    const [dataCount, setDataCount] = useState(0);

    const req = login.GetAxios();

    const loadData = async (c: typeof columnsState, skip: number, take: number) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            let sortBy = GetSortString(c);

            const resp = await req.get("/api/obfuscations", {
                params: {
                    sortBy,
                    skip,
                    take,
                }
            });

            setListState({
                items: (resp.data.items || []).map((x: any) => {
                    const item: ObfuscationsListStateItem = {
                        id: x.id,
                        name: x.name,
                        createdAt: new Date(x.createdAt * 1000),
                        updatedAt: new Date(x.updatedAt * 1000),
                        rules: x.rules && x.rules.map((item: any) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        })
                    };
                    return item;
                })
            });

            setSkipItems(skip);
            setTakeCount(take);
            setDataCount(resp.data.count || 0);
            setColumnsState(c);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const addOrEditObfuscation = (id?: string) => {
        setEditingObfuscationId(id || "");
        setAddObfuscationDrawer(true);
    };

    const deleteObfuscationDialog = (name: string, id: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the obfuscation "${name}"?`);
        setDeleteDialogObfuscationId(id);
    };

    const deleteObfuscation = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/obfuscations/${deleteDialogObfuscationId}`);
            loadData(columnsState, skipItems, takeCount);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Obfuscation deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete obfuscation",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };


    useEffect(() => {
        loadData(RestoreColumnsState("obfuscations",columnsState), skipItems, takeCount);
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState("obfuscations", columnsState);
    }, [columnsState]);

    return <>
        <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
            <Box display="flex">
                <Typography variant="h1">Obfuscations</Typography>
                <Button
                    id="new-obfuscation-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => addOrEditObfuscation()}
                >
                    Add New Obfuscation
                </Button>
            </Box>
            <DataTable
                onColumnOrderChanged={x => setColumnsState(x)}
                onSortChanged={x => loadData(x, skipItems, takeCount)}
                data={listState.items}
                finishedLoading={finishedLoading}
                columnsDataId={columnsDataId}
                columnsState={columnsState}
                onPaginationStartItemChanged={(x) => {
                    loadData(columnsState, x, takeCount);
                }}
                onPaginationItemCountChanged={(x) => {
                    loadData(columnsState, skipItems, x);
                }}
                paginationStartItem={skipItems}
                paginationItemCount={takeCount}
                dataCount={dataCount}
                columns={{
                    name: {
                        displayName: "Name",
                        sortable: true,
                        render: (x) => {
                            return x.name;
                        },
                    },
                    usedby: {
                        displayName: "Used By Rule(s)",

                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "row",
                                gap: "2px",
                            }}>
                                {(x.rules && x.rules.map(y => <Chip label={y.name.trim()} variant="outlined" size="small" color="primary" />))}
                            </Box>;
                        },
                    },
                    createdat: {
                        displayName: "Created At",
                        sortable: true,
                        render: (x) => {
                            return `${x.createdAt.toLocaleDateString()} ${x.createdAt.toLocaleTimeString()}`;
                        },
                    },
                    updatedat: {
                        displayName: "Updated At",
                        sortable: true,
                        render: (x) => {
                            return `${x.updatedAt.toLocaleDateString()} ${x.updatedAt.toLocaleTimeString()}`;
                        },
                    },

                }}
                rowOptionsMenu={[
                    {
                        icon: <EditIcon className="edit-button" fontSize="small" />,
                        text: "Edit",
                        onClick: (x) => {
                            addOrEditObfuscation(x.id);
                        }
                    },
                    {
                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                        text: "Delete",
                        onClick: (x) => {
                            if (x.rules && x.rules.length > 0) {
                                return notification.Display({
                                    type: "error",
                                    title: "Validation Error",
                                    message: `Cannot delete ${x.name} because it is in use by ${x.rules.length === 1 ? "a Rule" : "some Rules"} (${x.rules.map(i => i.name).join(", ")})`,
                                });
                            }

                            deleteObfuscationDialog(x.name, x.id);
                        }
                    },
                ]}
            />
        </Box>

        <Dialog open={deleteDialog}>
            <DialogTitle>Delete Application</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteObfuscation}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addObfuscationDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <EditObfuscation obfuscationId={editingObfuscationId} onExit={() => setAddObfuscationDrawer(false)} onSave={() => { setAddObfuscationDrawer(false); loadData(columnsState, skipItems, takeCount); }} />
            </Box>
        </Drawer>
    </>;
}