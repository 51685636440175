import { Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import AddUrlFilter, { AddedUrlFilter, FilterTypeList, MimetypeName } from "./EditFilter";
import DataTable from "components/Table";

interface AddNewApplicationProps {
    appId?: string
    onSave?: (app: Application) => void
    onExit?: () => void
    onBackButtonClicked?: () => void
}

interface Application {
    id?: string
    name: string
    description: string
    filters: AddedUrlFilter[]
}

export function AddNewApplication(props: AddNewApplicationProps) {
    const login = useLogin();
    const notification = useNotification();

    const [saving, setSaving] = useState(false);
    const [filterTypeList, setFilterTypeList] = useState({} as FilterTypeList);

    const [ready, setReady] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [filters, setFilters] = useState([] as AddedUrlFilter[]);

    const [filterBeingEdited, setFilterBeingEdited] = useState(undefined as (AddedUrlFilter | undefined));

    const [showBottomButtons, setShowBottomButtons] = useState(true);

    const init = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            const resp = await req.get("/api/filtertypes");

            const filtersTmp: FilterTypeList = {};

            resp.data.forEach((x: any) => {
                filtersTmp[x.value] = x.name;
            });

            setFilterTypeList(filtersTmp);

            //editing
            if (props.appId) {
                const r = await req.get(`/api/apps/${props.appId}`);
                const app = APIPayloadToApplicaiton(r.data);

                setName(app.name);
                setDescription(app.description);
                setFilters(app.filters || []);
            }
            setReady(true);
        } catch (err) {
            console.error(err);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFilterAdded = (data: AddedUrlFilter, reset: () => void) => {
        if (data.index === undefined) {
            setFilters(filters.concat(data));
        } else {
            setFilters(filters.map((x, index) => {
                if (index === data.index) {
                    return data;
                } else {
                    return x;
                }
            }));
        }
        reset();
        setFilterBeingEdited(undefined);
    };

    const deleteFilter = (index: number) => {
        const filtered = filters.filter((_, i) => {
            return i !== index;
        });

        setFilters(filtered);
    };

    const editFilter = (index: number) => {
        const a = { ...filters[index], index };
        setFilterBeingEdited(a);
    };

    const validate = () => {
        if (!name || !name.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Name is required."
            });

            return false;
        }

        if (!filters || !filters.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 definition for an Application."
            });

            return false;
        }

        return true;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (!validate()) {
            return;
        }

        setSaving(true);

        if (props.appId) {
            //update
            try {
                const resp = await req.put(`/api/apps/${props.appId}`,
                    ApplicationToAPIPayload({
                        name: name,
                        description: description,
                        filters: filters
                    })
                );

                const addedApp = APIPayloadToApplicaiton(resp.data);

                props.onSave && props.onSave(addedApp);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save app",
                });
                return;
            }
        } else {
            //create
            try {
                const resp = await req.post("/api/apps",
                    ApplicationToAPIPayload({
                        name: name,
                        description: description,
                        filters: filters
                    })
                );

                const addedApp = APIPayloadToApplicaiton(resp.data);

                props.onSave && props.onSave(addedApp);
            } catch (err) {
                setSaving(false);
                console.error(err);
                notification.Display({
                    type: "error",
                    title: "Request Failed",
                    message: "could not save app",
                });
                return;
            }
        }

        notification.Display({
            type: "success",
            title: "Success",
            message: "Application saved successfully",
        });
    };

    return <Loading finished={ready}>
        <>
            <Stack id="add-application-page" sx={{ flex: 1, height: "100%" }}>
                <Box display="flex" sx={{ alignItems: "start" }}>
                    <Box>
                        {props.onBackButtonClicked ?
                            <Button
                                id="back-button"
                                startIcon={<ArrowBackIosNewIcon sx={{ height: "14px" }} />}
                                onClick={props.onBackButtonClicked}
                            >
                                Back
                            </Button>
                            : undefined
                        }
                        <Typography sx={{ marginTop: "5px" }} variant="h2">{props.appId ? "Edit Application" : "Add New Application"}</Typography>
                    </Box>
                    <IconButton
                        id="exit-button"
                        size="small"
                        sx={{ marginLeft: "auto" }}
                        onClick={props.onExit}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <TextField sx={{ marginTop: "20px" }} id="name-input" name="name" label="Name" variant="outlined"
                    value={name}
                    onChange={x => setName(x.target.value)}
                />

                <TextField sx={{ marginTop: "20px" }} id="description-input" name="description" label="Description" variant="outlined"
                    value={description}
                    onChange={x => setDescription(x.target.value)}
                />

                <Typography sx={{ marginTop: "40px" }} variant="h3">Definitions</Typography>
                <Box>
                    <DataTable
                        noDataText="No definitions to display"
                        data={filters.map((v, idx) => {
                            return { ...v, id: idx.toString() };
                        })}
                        columnsDataId="dataGrid_ApplicationFilterTable_columns"
                        columnsState={[
                            {columnId: "type", width: "25%"},
                            {columnId: "value", width: "25%"},
                            {columnId: "contentType", width: "25%"},
                            {columnId: "behavior", width: "25%"},
                        ]}
                        columns={{
                            type: {
                                displayName: "Type",
                                render: (x) => {
                                    return filterTypeList[x.type];
                                }
                            },
                            value: {
                                displayName: "Value",
                                render: (x) => {
                                    return x.text;
                                }
                            },
                            contentType: {
                                displayName: "Content Type",
                                render: (x) => {
                                    return x.contentType.map(x => MimetypeName[x]).join(", ") || "Any";
                                }
                            },
                            behavior: {
                                displayName: "Behavior",
                                render: (x) => {
                                    return x.behavior;
                                }
                            },
                        }}
                        rowOptionsMenu={[
                            {
                                icon: <EditIcon className="edit-button" fontSize="small" />,
                                text: "Edit",
                                onClick: (x) => {
                                    editFilter(parseInt(x.id));
                                }
                            },
                            {
                                icon: <DeleteIcon className="delete-button" fontSize="small" />,
                                text: "Delete",
                                onClick: (x) => {
                                    deleteFilter(parseInt(x.id));
                                }
                            },
                        ]}
                        finishedLoading
                    />
                </Box>

                <AddUrlFilter
                    initialValue={filterBeingEdited}
                    filterTypeList={filterTypeList}
                    onCancel={() => setFilterBeingEdited(undefined)}
                    onFilterAdded={onFilterAdded}
                    onEdit={(x) => setShowBottomButtons(!x)}
                />

                {showBottomButtons ? <>
                    <Divider sx={{ marginTop: "auto" }} />
                    <Box display="flex" sx={{ marginTop: "10px" }}>
                        <Button
                            id="cancel-button"
                            variant="outlined"
                            size="medium"
                            onClick={props.onExit}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            id="save-button"
                            loading={saving}
                            sx={{ marginLeft: "auto" }}
                            variant="contained"
                            size="medium"
                            onClick={save}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </> : undefined}
            </Stack>

        </>
    </Loading>;
}

function APIPayloadToApplicaiton(body: any) {
    const app: Application = {
        id: body.id,
        name: body.name,
        description: body.description,
        filters: body.urlFilters ? body.urlFilters.map((x: any) => {
            const ret: AddedUrlFilter = {
                type: x.filterType,
                behavior: x.isWhitelist ? "Bypass" : "Match",
                text: x.filter,
                contentType: x.contentType || [],
                detectContentType: x.runContentTypeDetection === true,
                htmlOptions: x.htmlOptions ? {
                    jsonSubmatchRules: x.htmlOptions.jsonSubmatchRules ? {
                        xpath: x.htmlOptions.jsonSubmatchRules.xpath,
                    } : undefined,
                } : undefined
            };
            return ret;
        }) : undefined
    };
    return app;
}

function ApplicationToAPIPayload(app: Application): any {
    return {
        name: app.name,
        description: app.description,
        urlFilters: app.filters.map(x => {
            return {
                filterType: x.type,
                isWhitelist: x.behavior === "Bypass",
                filter: x.text,
                contentType: x.contentType,
                runContentTypeDetection: x.detectContentType,
                htmlOptions: x.htmlOptions ? {
                    jsonSubmatchRules: x.htmlOptions.jsonSubmatchRules ? {
                        xpath: x.htmlOptions.jsonSubmatchRules.xpath,
                    } : undefined,
                } : undefined,
            };
        })
    };
}