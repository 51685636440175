import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Typography,
    OutlinedInput,
    InputAdornment,
    Tooltip,
    IconButton,
    Checkbox,
    FormControlLabel,
    FormGroup
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

interface GenerateApiKeyDialogProps {
    open: boolean
    onExit: () => void
    onSave: () => void
}

function GenerateApiKeyDialog(props: GenerateApiKeyDialogProps) {
    const notification = useNotification();
    const login = useLogin();
    const req = login.GetAxios();
    const navigate = useNavigate();

    const [currentState, setCurrentState] = useState<"inputInfo" | "displayToken">("inputInfo");
    const [apiKeyName, setApiKeyName] = useState("");
    const [daysToExpire, setDaysToExpire] = useState("90");
    const [allowPolicyDefinition, setAllowPolicyDefinition] = useState(true);
    const [allowDataScanning, setAllowDataScanning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addedApiKeyToken, setAddedApiKeyToken] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        setCurrentState("inputInfo");
        setApiKeyName("");
        setDaysToExpire("90");
        setLoading(false);
        setAddedApiKeyToken("");
        setAllowPolicyDefinition(true);
        setAllowDataScanning(false);
        setShowTooltip(false);
    }, [props.open]);


    const createAPIKey = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }
        setLoading(true);

        try {
            const resp = await req.post("/api/auth/apikey", {
                name: apiKeyName,
                daysToExpire: daysToExpire ? Number(daysToExpire) : 0,
                allowPolicyDefinition,
                allowDataScanning
            });
            setAddedApiKeyToken(resp.data.token);
            props.onSave();
            setCurrentState("displayToken");
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
                notification.Display({
                    title: "Error",
                    message: body.message,
                    type: "error",
                });
            } else {
                console.error(err);
                notification.Display({
                    title: "Error",
                    message: "Error creating API key",
                    type: "error",
                });
            }
            setLoading(false);
        }
    };

    return <>
        <Dialog
            open={props.open && currentState === "inputInfo"}
        >
            <DialogTitle>
                {"Generate API Key"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    id="add-api-name"
                    label="API Key Name"
                    variant="outlined"
                    value={apiKeyName}
                    sx={{ marginTop: "15px", marginBottom: "10px", width: "350px" }}
                    onChange={(x) => setApiKeyName(x.target.value)}
                />
                <TextField
                    id="add-api-days"
                    label="Days to expire"
                    variant="outlined"
                    type="number"
                    value={daysToExpire}
                    sx={{ marginTop: "5px", marginBottom: "10px", width: "350px" }}
                    onChange={(x) => setDaysToExpire(x.target.value)}
                />
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={allowPolicyDefinition} onClick={() => setAllowPolicyDefinition(!allowPolicyDefinition)} />} label="Allow Policy Definition" />
                    <FormControlLabel control={<Checkbox checked={allowDataScanning} onClick={() => setAllowDataScanning(!allowDataScanning)} />} label="Allow Data Scanning" />
                    <FormControlLabel control={<Checkbox checked={!allowPolicyDefinition && !allowDataScanning} onClick={() => {
                        if (!allowPolicyDefinition && !allowDataScanning) {
                            setAllowPolicyDefinition(true);
                        } else {
                            setAllowDataScanning(false);
                            setAllowPolicyDefinition(false);
                        }

                    }} />} label="Read Only" />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-api-key-button" onClick={props.onExit}>Cancel</Button>
                <LoadingButton id="confirm-api-key-button" loading={loading} onClick={createAPIKey} variant="contained" autoFocus disabled={apiKeyName.length === 0 || Number(daysToExpire) <= 0}>
                    Generate API Key
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={props.open && currentState === "displayToken"}
        >
            <DialogTitle>
                {"API Key"}
            </DialogTitle>
            <DialogContent>
                <Typography>Use the following bearer token to authenticate with the API:</Typography>
                <OutlinedInput
                    id="added-api-token"
                    label="Token"
                    value={addedApiKeyToken}
                    fullWidth
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                open={showTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied!"
                            >
                                <IconButton
                                    id="copy-api-key-button"
                                    color="primary"
                                    onClick={() => {
                                        navigator.clipboard.writeText(addedApiKeyToken);
                                        setShowTooltip(true);
                                        setTimeout(() => {
                                            setShowTooltip(false);
                                        }, 2000);
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                />

            </DialogContent>
            <DialogActions>
                <Button id="cancel-api-key-dialog" onClick={() => {
                    props.onExit();
                }} variant="outlined" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default GenerateApiKeyDialog;