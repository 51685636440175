import { Collapse, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { MenuDefinition } from "./MenuDefinition";
import RenderMenuItem from "./RenderMenuItem";

export interface MenuProps {
    minimal: boolean
}

export function LinkMenu(props: MenuProps) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Stack sx={{
            marginTop: "30px"
        }}>
            {
                MenuDefinition.itens.map(x => RenderMenuItem({
                    item: x,
                    minimal: props.minimal,
                    path: location.pathname,
                    navigate,
                })[0])
            }
            <Collapse sx={{ flex: 1 }} in={!props.minimal} orientation={"horizontal"} >
                <Box sx={{ width: "220px" }}></Box>
            </Collapse>

        </Stack>
    );
}