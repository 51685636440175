import { createContext, useContext, useEffect, useState } from "react";
import axios, { AxiosInstance } from "axios";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import Cookies from "js-cookie";

export enum LoginErrorEnum {
    LoginUnknownError = 0,
    LoginInvalidCredentials,
}
export interface AuthContextData {
    LoggedIn: boolean
    ConnectionOk: boolean
    DatabaseOk: boolean
    ActivityDatabaseOk: boolean
    ServerMode: ServerModeEnum
    Login: (username: string, password: string) => Promise<void>
    Logout: () => Promise<void>
    //GetAxios returns an axios instance with the correct authentication headers.
    //Will throw if not logged in.
    GetAxios: () => AxiosInstance | undefined
    //GetLoginDetail gets login information. Returns undefined if not logged in
    GetLoginDetail: () => Promise<LoginDetail | undefined>
    FetchVersion: () => Promise<VersionInfo | undefined>
}

export enum ServerModeEnum {
    UNKNOWN = "",
    ALL = "all",
    WEB = "web",
    ALERT = "alert",
    ICAP = "icap"
}

export interface CriticalIssue {
    description: string
}

export interface LoginDetail {
    username: string
    databaseType: string
}

export interface VersionInfo {
    version: string
    buildDate: string
}

const LoginContext = createContext<AuthContextData>({} as AuthContextData);

interface LoginProviderProps {
    children: React.ReactChild | React.ReactChild[]
}

function LoginProvider(props: LoginProviderProps) {
    let token = localStorage.getItem("auth_token") || "";
    if (!token) {
        //if there's no token, check if the server sent it through cookies
        token = Cookies.get("SessionToken") || "";

        if (token !== "") {
            Cookies.remove("SessionToken");
            localStorage.setItem("auth_token", token);
            console.info("got auth token from cookie");
        }
    }

    const [LoggedIn, setLoggedIn] = useState(token ? true : false);
    const [DatabaseOk, setDatabaseOk] = useState(true);
    const [ActivityDatabaseOk, setActivityDatabaseOk] = useState(true);
    const [ConnectionOk, setConnectionOk] = useState(true);
    const [ServerMode, setServerMode] = useState(ServerModeEnum.UNKNOWN);

    const Login = async (username: string, password: string) => {
        const formData = new URLSearchParams();

        formData.append("grant_type", "client_credentials");
        formData.append("client_id", username);
        formData.append("client_secret", password);


        try {
            const resp = await axios.post("/api/auth", formData);
            localStorage.setItem("auth_token", resp.data.access_token);
            setLoggedIn(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                //the request was made and the server responded with a non-200 status code
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorInvalidCredentials) {
                    throw new LoginError(
                        LoginErrorEnum.LoginInvalidCredentials,
                        "Incorrect username or password. Please try again.",
                    );
                } else {
                    console.error(err);
                    throw new LoginError(
                        LoginErrorEnum.LoginUnknownError,
                        "Log-in failed",
                    );
                }
            }
            throw err;
        }


    };

    const GetAxios = () => {
        if (!LoggedIn) {
            return;
        }

        return axios.create({
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
    };

    const Logout = async () => {
        try {
            const ax = await GetAxios();
            if(!ax) {
                console.error("not logged in");
                return;
            }

            ax.post("/api/auth/logout");
            localStorage.removeItem("auth_token");
            setLoggedIn(false);
        } catch(err) {
            throw new LoginError(
                LoginErrorEnum.LoginUnknownError,
                "Log-out failed",
            );
        }
    };

    const GetLoginDetail = async () => {
        try {
            const ax = GetAxios();

            if (!ax) {
                return; //not logged in
            }

            const res = await ax.get("/api/auth");

            return {
                username: res.data.username,
                databaseType: res.data.databaseType,
            };
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    localStorage.removeItem("auth_token");
                    setLoggedIn(false);
                    return;
                }
            }
            console.error(err); //TODO: report error to the user somehow
            return;
        }

    };

    const FetchVersion = async (): Promise<VersionInfo | undefined> => {
        const ax = GetAxios();
        if (!ax) {
            return;
        }

        const respVersion = await ax.get("/api/config/version");

        return {
            version: respVersion.data.version,
            buildDate: respVersion.data.buildDate,
        };
    };

    useEffect(() => {
        const item = {} as { timeout?: NodeJS.Timeout };

        const loop = async () => {
            try {
                const response = await axios.get("/api/serverstatus");

                setDatabaseOk(response.data.databaseStatus === "connected");
                setActivityDatabaseOk(response.data.activityDatabaseStatus === "connected");
                setConnectionOk(true);
                setServerMode(response.data.mode);
            } catch (e: any) {
                console.error(e);
                if (axios.isAxiosError(e) && e.response) {
                    if(e.response) {
                        setDatabaseOk(false);
                        setConnectionOk(true);
                    } else {
                        setDatabaseOk(false);
                        setConnectionOk(false);
                    }
                } else {
                    setDatabaseOk(false);
                    setConnectionOk(false);
                }
            }
            item.timeout = setTimeout(loop, 5000);
        };

        loop();

        return () => {
            if (item.timeout) {
                clearTimeout(item.timeout);
            }
        };

    }, []);

    return (
        <LoginContext.Provider value={{ ServerMode, LoggedIn, DatabaseOk, ActivityDatabaseOk, ConnectionOk, Login, Logout, GetAxios, GetLoginDetail, FetchVersion }}>
            {props.children}
        </LoginContext.Provider>
    );
}

export function useLogin() {
    return useContext(LoginContext);
}

export class LoginError extends Error {
    Type: LoginErrorEnum;
    constructor(e: LoginErrorEnum, message: string) {
        super(message);
        Object.setPrototypeOf(this, LoginError.prototype);
        this.Type = e;
    }
}



export default LoginProvider;
