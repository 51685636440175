import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import "@fontsource-variable/inter";
import SAMLLogout from "routes/App/pages/SAMLLogout";
import Login from "routes/Login";
import LoginProvider from "providers/Login";
import NotificationProvider from "providers/Notification";
import Register from "routes/Login/register";

const mdTheme = createTheme({
    palette: {
        primary: {
            main: "#2158c3",
        },
        text: {
            primary: "#152662",
            secondary: "#4B5C97",
        },
    },
    typography: {
        fontFamily: "Inter Variable",
        h6: {
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
        },
        h5: {
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
        },
        h4: {
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
        },
        h3: {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
        },
        h2: {
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "30px",
        },
        h1: {
            fontWeight: 700,
            fontSize: "30px",
            lineHeight: "40px",
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
        },
        body1: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
        },
        body2: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
        },
        button: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            textTransform: "none"
        },
        caption: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                    borderRadius: "6px"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    boxSizing: "border-box",
                    borderRadius: "6px",
                },
                sizeSmall: {
                    borderRadius: "4px",
                    fontSize: "12px"
                },
                deleteIcon: {
                    fill: "#3A75E8",
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: "0.5px solid #CCD0E0",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    boxShadow: "none",
                    padding: "40px",
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: "#4B5C97",
                    "&:hover": {
                        backgroundColor: "aliceblue",
                    },
                    "&.Mui-selected": {
                        color: "#2158c3",
                        backgroundColor: "#0000",
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "inherit",
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    overflow: "auto",
                    tableLayout: "fixed"
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(even)": {
                        backgroundColor: "rgba(241, 243, 252, 0.5)"
                    },
                    "&:hover > td": {
                        backgroundColor: "#E2F1FF"
                    }
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",

                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: "#DDDDDD solid 1px",
                    "& .column-resizer": {
                        transition: "opacity 0.3s",
                        cursor: "ew-resize",
                        opacity: 0,
                        userSelect: "none",
                        background: "#3A75E8",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        margin: "auto",
                        width: "3px",
                        height: "40px"
                    },
                    "& th:hover": {
                        backgroundColor: "#E2F1FF",
                        "& .column-resizer": {
                            opacity: 1
                        }
                    }
                },
            }
        },
    },

});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={mdTheme}>
            <LoginProvider>
                <NotificationProvider>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/register" element={<Register />}></Route>
                            <Route path="/login" element={<Login />}></Route>
                            <Route path="/icaplogout" element={<SAMLLogout />} />
                            <Route path="/*" element={<App />} />
                        </Routes>
                    </BrowserRouter>
                </NotificationProvider>
            </LoginProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
