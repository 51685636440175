import { Button, Divider, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import EditObfuscation from "../Obfuscations/EditObfuscation";
import { AddNewApplication } from "../Apps/AddApplication";
import { GroupFilter, HeaderConditionType, HeaderFilter, HeaderFilterValue, UsernameFilter } from "./HeaderFilter";
import { ListSelect } from "../../../../components/ListSelect";

interface Rule {
    id?: string
    name: string
    description: string
    order?: number,
    enable: boolean
    apps: string[]
    obfuscations: string[]
    icapInstances: string[]
    filters: (GroupFilter | UsernameFilter)[]
}

function RuleToAPIPayload(o: Rule) {
    let rule = {
        name: o.name,
        description: o.description,
        enable: o.enable,
        ruleOrder: o.order,
        apps: o.apps,
        icapInstances: o.icapInstances.join(","),
        obfuscations: o.obfuscations,
        groupFilters: (o.filters.filter(x => x.filterType === "group") as GroupFilter[]).map(x => {
            return {
                condition: x.conditionType,
                filter: x.filter,
                provider: x.provider,
            };
        }),
        userFilters: (o.filters.filter(x => x.filterType === "username") as UsernameFilter[]).map(x => {
            return {
                condition: x.conditionType,
                filter: x.filter,
            };
        }),
    };

    return rule;
}

function APIPayloadToRule(i: any): Rule {
    let rule: Rule = {
        name: i.name,
        description: i.description,
        enable: i.enable,
        order: i.ruleOrder,
        apps: i.apps || [],
        obfuscations: i.obfuscations || [],
        icapInstances: i.icapInstances ? i.icapInstances.split(",") : [],
        filters:
            i.userFilters.map((x: any) => {
                let ret: UsernameFilter = {
                    filterType: "username",
                    conditionType: x.condition,
                    filter: x.filter,
                };
                return ret;
            }).concat(
                i.groupFilters.map((x: any) => {
                    let ret: GroupFilter = {
                        filterType: "group",
                        conditionType: x.condition,
                        filter: x.filter,
                        provider: x.provider,
                    };
                    return ret;
                }))
    };
    return rule;
}

interface EditRuleProps {
    ruleId?: string
    onSave?: (app: Rule) => void
    onExit?: () => void
}

type IcapInstanceList = { [key: string]: string };
type AppsList = { [key: string]: string };
type ObfuscationsList = { [key: string]: string };

export default function EditRule(props: EditRuleProps) {
    const login = useLogin();
    const notification = useNotification();

    const [currentScreen, setCurrentScreen] = useState("AddRule");
    const [ready, setReady] = useState(false);
    const [saving, setSaving] = useState(false);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ruleOrder, setRuleOrder] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [apps, setApps] = useState([] as string[]);
    const [obfuscations, setObfuscations] = useState([] as string[]);
    const [icapInstances, setIcapInstances] = useState([] as string[]);

    const [filters, setFilters] = useState<(GroupFilter | UsernameFilter)[]>([]);

    const [appsList, setAppsList] = useState({} as AppsList);
    const [icapInstanceList, setIcapInstanceList] = useState({} as IcapInstanceList);
    const [obfuscationsList, setObfuscationsList] = useState({} as ObfuscationsList);

    const loadData = async (loadRule = true) => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        try {
            const appsResp = await req.get("/api/apps", {
                params: {
                    sortBy: "name",
                    sortDirection: "asc"
                }
            });
            const appsTmp: AppsList = {};
            (appsResp.data.items || []).forEach((x: any) => {
                appsTmp[x.id] = x.name;
            });
            setAppsList(appsTmp);

            const obfuscationsResp = await req.get("/api/obfuscations", {
                params: {
                    sortBy: "name",
                    sortDirection: "asc"
                }
            });
            const obfuscationsTmp: ObfuscationsList = {};
            (obfuscationsResp.data.items || []).forEach((x: any) => {
                obfuscationsTmp[x.id] = x.name;
            });
            setObfuscationsList(obfuscationsTmp);

            try {

                const resp = await req.get("/api/instances");

                const rawData = resp.data.instances;

                const icapInstancesTmp: IcapInstanceList = {};
                rawData.forEach((x: any) => {
                    icapInstancesTmp[x.instanceName] = x.instanceName;
                });

                setIcapInstanceList(icapInstancesTmp);
            } catch { }

            if (props.ruleId && loadRule) {
                const ruleReq = await req.get(`/api/rules/${props.ruleId}`);

                const rule = APIPayloadToRule(ruleReq.data);

                setName(rule.name);
                setDescription(rule.description);
                setEnabled(rule.enable);
                setRuleOrder(rule.order!);
                setApps(rule.apps);
                setObfuscations(rule.obfuscations);
                setIcapInstances(rule.icapInstances);
                setFilters(rule.filters);
            }

        } catch (err) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        setReady(true);
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const validate = () => {
        if (!name || !name.trim()) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "Name is required."
            });

            return false;
        }

        if (!apps || !apps.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 Application for a Rule."
            });

            return false;
        }

        if (!obfuscations || !obfuscations.length) {
            notification.Display({
                type: "error",
                title: "Validation Error",
                message: "There should be at least 1 Obfuscation for a Rule."
            });

            return false;
        }

        return true;
    };

    const save = async () => {
        const req = login.GetAxios();

        if (!req) {
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not make request",
            });
            return;
        }

        if (!validate()) {
            return;
        }

        setSaving(true);

        try {
            if (!props.ruleId) {
                //create
                const resp = await req.post("/api/rules",
                    RuleToAPIPayload({
                        name: name,
                        description: description,
                        apps: apps,
                        enable: true,
                        obfuscations: obfuscations,
                        filters: filters,
                        icapInstances
                    })
                );

                const addedApp = APIPayloadToRule(resp.data);

                props.onSave && props.onSave(addedApp);

                notification.Display({
                    type: "success",
                    title: "Success",
                    message: "Rule saved successfully",
                });
            } else {
                //update 
                const resp = await req.put(`/api/rules/${props.ruleId}`,
                    RuleToAPIPayload({
                        name: name,
                        description: description,
                        apps: apps,
                        order: ruleOrder,
                        enable: enabled,
                        obfuscations: obfuscations,
                        filters: filters,
                        icapInstances
                    })
                );

                const addedApp = APIPayloadToRule(resp.data);

                props.onSave && props.onSave(addedApp);

                notification.Display({
                    type: "success",
                    title: "Success",
                    message: "Rule saved successfully",
                });
            }

        } catch (err) {
            setSaving(false);
            notification.Display({
                type: "error",
                title: "Request Failed",
                message: "could not save app",
            });
            return;
        }
    };

    const exit = () => {
        props.onExit && props.onExit();
    };

    const addApplication = (id: string) => {
        if (apps.find(x => x === id)) {
            return;
        }
        setApps(apps.concat(id));
    };
    const deleteApplication = (id: string) => {
        setApps(apps.filter(x => x !== id));
    };

    const addIcapInstance = (name: string) => {
        if (icapInstances.find(x => x === name)) {
            return;
        }

        setIcapInstances(icapInstances.concat(name));
    };
    const deleteIcapInstances = (name: string) => {
        setIcapInstances(icapInstances.filter(x => x !== name));
    };

    const addObfuscation = (id: string) => {
        if (obfuscations.find(x => x === id)) {
            return;
        }
        setObfuscations(obfuscations.concat(id));
    };
    const deleteObfuscation = (id: string) => {
        setObfuscations(obfuscations.filter(x => x !== id));
    };

    const applyHeaderFilterValue = (filter: HeaderFilterValue) => {
        if (filter.groupFilter) {
            setFilters(filters.concat(filter.groupFilter));
        }

        if (filter.usernameFilter) {
            setFilters(filters.concat(filter.usernameFilter));
        }
    };

    const getHeaderFilterText = (filter: GroupFilter | UsernameFilter) => {
        let headerText = filter.filterType === "username" ? "Username" : "Group";
        const conditionText: { [key in HeaderConditionType]: string } = {
            "": "",
            equal: "equals",
            not_equal: "does not equal",
            contains: "contains",
            not_contains: "does not contain",
        };

        return `${headerText} ${conditionText[filter.conditionType]} "${filter.filter}"`;
    };


    const deleteHeaderFilter = (key: number) => {
        setFilters(filters.filter((_, i) => i !== key));
    };

    return <Loading finished={ready}>
        <>
            {
                (currentScreen === "AddRule" &&
                    <Stack id="add-rule-page" sx={{ flex: 1, height: "100%" }}>
                        <input id="focus-utility" type="hidden"></input>
                        <Box display="flex" sx={{ alignItems: "start" }}>
                            <Box>
                                <Typography sx={{ marginTop: "5px" }} variant="h2">{props.ruleId ? "Edit Rule" : "Add New Rule"}</Typography>
                            </Box>
                            <IconButton
                                id="exit-button"
                                size="small"
                                sx={{ marginLeft: "auto" }}
                                onClick={exit}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <TextField sx={{ marginTop: "20px" }} id="name-input" name="name" label="Name" variant="outlined"
                            value={name}
                            onChange={x => setName(x.target.value)}
                        />

                        <TextField sx={{ marginTop: "20px" }} id="description-input" name="description" label="Description" variant="outlined"
                            value={description}
                            onChange={x => setDescription(x.target.value)}
                        />

                        <ListSelect
                            id="application-select"
                            label="Applications"
                            valueList={appsList}
                            value={apps}
                            onAdd={x => addApplication(x)}
                            onDelete={x => deleteApplication(x)}
                            popoverExtra={(handleClose) => {
                                return (<Box sx={{
                                    height: "40px",
                                    backgroundColor: "#e2f2ff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px"
                                }}>
                                    <Typography>Want to add an application?</Typography>
                                    <Link id="add-application-button" component="button" onClick={() => setCurrentScreen("AddApp")}>Click here</Link>
                                </Box>);
                            }}
                        />


                        <ListSelect
                            id="obfuscation-select"
                            label="Obfuscations"
                            valueList={obfuscationsList}
                            value={obfuscations}
                            onAdd={x => addObfuscation(x)}
                            onDelete={x => deleteObfuscation(x)}
                            popoverExtra={(handleClose) => {
                                return (<Box sx={{
                                    height: "40px",
                                    backgroundColor: "#e2f2ff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px"
                                }}>
                                    <Typography>Want to add an obfuscation?</Typography>
                                    <Link id="add-obfuscation-button" component="button" onClick={() => setCurrentScreen("AddObfuscation")}>Click here</Link>
                                </Box>);
                            }}
                        />

                        <ListSelect
                            id="header-filter-select"
                            label="Users and Groups"
                            valueList={filters.map(x => getHeaderFilterText(x)).reduce((prev, current, idx) => {
                                return { ...prev, [idx]: current };
                            }, {})}
                            value={filters.map((_, idx) => idx.toString())}
                            onDelete={x => deleteHeaderFilter(Number(x))}
                            popoverOnly={true}
                            popoverExtra={(handleClose) => {
                                return (<HeaderFilter handleClose={handleClose} onApply={applyHeaderFilterValue} />);
                            }}
                        />

                        {Object.keys(icapInstanceList).length > 0 ? <ListSelect
                            id="icap-instances-select"
                            label="Shield Instances"
                            showUnknownValue={true}
                            valueList={icapInstanceList}
                            value={icapInstances}
                            onAdd={x => addIcapInstance(x)}
                            onDelete={x => deleteIcapInstances(x)}
                        /> : null}


                        <Divider sx={{ marginTop: "auto" }} />
                        <Box display="flex" sx={{ marginTop: "10px" }}>
                            <Button
                                id="back-button"
                                variant="outlined"
                                size="medium"
                                onClick={exit}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                id="save-button"
                                loading={saving}
                                sx={{ marginLeft: "auto" }}
                                variant="contained"
                                size="medium"
                                onClick={save}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Stack>
                ) ||
                (currentScreen === "AddApp" &&
                    <AddNewApplication
                        onBackButtonClicked={() => {
                            setCurrentScreen("AddRule");
                        }}
                        onExit={() => {
                            setCurrentScreen("AddRule");
                        }}
                        onSave={() => {
                            loadData(false);
                            setCurrentScreen("AddRule");
                        }}
                    />
                ) ||
                (currentScreen === "AddObfuscation" &&
                    <EditObfuscation
                        onBackButtonClicked={() => {
                            setCurrentScreen("AddRule");
                        }}
                        onExit={() => {
                            setCurrentScreen("AddRule");
                        }}
                        onSave={() => {
                            loadData(false);
                            setCurrentScreen("AddRule");
                        }}
                    />
                )
            }
        </>
    </Loading>;
}


