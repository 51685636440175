import { Chip } from "@mui/material";
import { useState } from "react";

interface ChipListProps<T> {
    items: T[];
    renderItem: (item: T) => any;
}
export function ChipList<T>(props: ChipListProps<T>) {
    const [showMore, setShowMore] = useState(false);

    if (!props.items) {
        return null;
    }

    const isSmallSet = props.items.length <= 5;
    return <>
        {props.items.map((item, index) => (isSmallSet || showMore || index < 5) && props.renderItem(item))}
        {!isSmallSet && !showMore && <Chip label="Show more..." color="info" size="small" onClick={() => setShowMore(true)}></Chip>}
        {!isSmallSet && showMore && <Chip label="Show less..." color="info" size="small" onClick={() => setShowMore(false)}></Chip>}
    </>;
}
