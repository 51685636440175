import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

interface LoadingProps {
    finished: boolean
    children: React.ReactChild | React.ReactChild[]
}

function Loading(props: LoadingProps) {
    return (<>
        {
            !props.finished ?
                <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </Box>
                : props.children
        }
    </>);
}

export default Loading;