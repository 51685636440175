import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotification } from "providers/Notification";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

interface AddDataTypeRegexProps {
    initialValue?: AddedDataTypeRegex
    onCancelClicked?: () => void
    onRegexAdded: (regex: AddedDataTypeRegex) => void
}

export interface AddedDataTypeRegex {
    index?: number
    regex: string
    valueGroupIndex?: number
    json: string
    html: string
}

export default function AddDataTypeRegex(props: AddDataTypeRegexProps) {
    const notification = useNotification();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [regex, setRegex] = useState(props.initialValue?.regex || "");
    const [valueGroupIndex, setValueGroupIndex] = useState(props.initialValue?.valueGroupIndex?.toString() || "");
    const [json, setJson] = useState(props.initialValue?.json || "");
    const [html, setHtml] = useState(props.initialValue?.html || "");
    const [validationError, setValidationError] = useState(false);
    const [jsonValidationError, setJsonValidationError] = useState(false);
    const [htmlValidationError, setHtmlValidationError] = useState(false);

    const validateJsonFormat = (value: string) => {
        try {
            JSON.parse(value);
            return true;
        } catch {
            return false;
        }
    };

    const save = () => {
        setValidationError(false);
        setHtmlValidationError(false);
        setJsonValidationError(false);

        if ((!regex || !regex.trim()) && (!json || !json.trim()) && (!html || !html.trim())) {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "You need to set at least one definition (regex, json or html)."
            });
            setValidationError(true);
            return;
        }

        if (json && !validateJsonFormat(json)) {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "JSON Definition value must have a valid json format."
            });
            setJsonValidationError(true);
            return;
        }

        if (html) {
            try {
                document.evaluate(html, document, null, XPathResult.ANY_TYPE, null);
            } catch (e) {
                notification.Display({
                    type: "error",
                    title: "Invalid parameters",
                    message: "HTML Definition value must have a valid XPath format."
                });
                setHtmlValidationError(true);
                return;
            }

        }



        props.onRegexAdded({
            ...props.initialValue,
            regex,
            valueGroupIndex: Number(valueGroupIndex) > 0 ? Number(valueGroupIndex) : undefined,
            json,
            html,
        });


        setRegex("");
        setValueGroupIndex("");
        setJson("");
        setHtml("");
    };

    useEffect(() => {
        setRegex(props.initialValue?.regex || "");
        setValueGroupIndex(props.initialValue?.valueGroupIndex?.toString() || "");
        setJson(props.initialValue?.json || "");
        setHtml(props.initialValue?.html || "");

        if (props.initialValue?.json || props.initialValue?.html || props.initialValue?.valueGroupIndex) {
            setShowAdvanced(true);
        }
    }, [props.initialValue]);

    return <>
        <Typography sx={{ marginTop: "40px" }} variant="h3">Definitions</Typography>

        <TextField sx={{ marginTop: "20px" }} id="regex-input" label="Regex" variant="outlined"
            value={regex}
            onChange={x => setRegex(x.target.value)}
            error={validationError}
        />

        <Typography id="advanced-settings-toggle" variant="h2" sx={{ marginTop: "20px", marginBottom: "20px", display: "flex", alignItems: "center", cursor: "pointer", ":hover": { color: "#2158c3" } }}
            onClick={() => setShowAdvanced(!showAdvanced)} >
            {showAdvanced ? <ArrowDropUp /> : <ArrowDropDown />}<Typography component={"span"} sx={{ fontWeight: "bold", fontSize: "16px" }}>Advanced Settings</Typography>
        </Typography>
        {showAdvanced ? <>
            <TextField sx={{ marginTop: "20px" }} id="group-index-input" label="Value Group Index" variant="outlined"
                value={valueGroupIndex}
                type="number"
                onChange={x => setValueGroupIndex(x.target.value)}
                error={validationError}
            />
            <TextField
                sx={{ marginTop: "20px" }}
                fullWidth
                multiline
                minRows={3}
                id="json-input"
                value={json}
                onChange={x => setJson(x.target.value)}
                error={validationError || jsonValidationError}
                label="JSON Definition"
            />

            <TextField
                sx={{ marginTop: "20px" }}
                fullWidth
                multiline
                minRows={3}
                id="html-input"
                value={html}
                onChange={x => setHtml(x.target.value)}
                error={validationError || htmlValidationError}
                label="HTML Definition"
            />
        </> : null}

        {props.initialValue ?
            <Box display="flex">
                <Button sx={{ marginTop: "10px", height: "40px" }} variant="text" id="cancel-definition-button" fullWidth onClick={props.onCancelClicked}>Cancel</Button>
                <Button sx={{ marginTop: "10px", height: "40px" }} variant="outlined" id="save-definition-button" fullWidth onClick={save}>Save</Button>
            </Box>
            :
            <Button sx={{ marginTop: "10px", height: "40px" }} variant="outlined" id="add-definition-button" fullWidth onClick={save}>Add</Button>
        }
    </>;
}