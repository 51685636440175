import {
    Button,
    Card,
    Container,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import abstractShape from "./abstract-shape.svg";
import { useEffect, useState } from "react";
import axios from "axios";

function SAMLLogout() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkIcapSession = async () => {
        try {
            const resp = await axios.get("/api/icapsession/check");

            if (resp.status === 200 && resp.data && resp.data.isAuthenticated) {
                setIsAuthenticated(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        axios.post("/api/icapsession/logout");
        setLoggedOut(true);
    };

    useEffect(() => {
        checkIcapSession();
    }, []);

    return <Container id="login-page" sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${abstractShape})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    }}>
        <Typography sx={{ marginBottom: "44px", fontSize: "40px", lineHeight: "50px" }} variant="h1">ICAP Session</Typography>

        <Box sx={{ width: "480px" }}>
            <Card>
                {isLoading && <Typography variant="h3">Checking your session...</Typography>}
                {isAuthenticated ? <>
                    {loggedOut ?
                        <Typography variant="h3">You Logged out succesfully !</Typography>
                        :
                        <Box textAlign={"center"}>
                            <Typography variant="h3">Are you sure you want to logout ?</Typography>
                            <Button sx={{ marginTop: "10px"}} size="large" variant="contained" color="primary" onClick={logout}>Yes-Logout</Button>
                        </Box>
                    }
                </> :
                    <Typography variant="h4">You're currently not authenticated in our ICAP.</Typography>
                }
            </Card>
            <Typography
                sx={{ marginTop: "50px" }}
                variant="body2"
                color="text.secondary"
                align="center"
            >
                {process.env.REACT_APP_VERSION}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
            >
                Copyright © Nullafi 2024
            </Typography>
        </Box>
    </Container>;
}

export default SAMLLogout;
