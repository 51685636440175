import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useLogin } from "providers/Login";

export enum HeaderConditionType {
    Empty = "",
    Equal = "equal",
    NotEqual = "not_equal",
    Contains = "contains",
    DoesNotContain = "not_contains"
}

export interface HeaderFilterValue {
    usernameFilter?: UsernameFilter
    groupFilter?: GroupFilter
}

export interface UsernameFilter {
    filterType: "username"
    conditionType: HeaderConditionType
    filter: string
}

export interface GroupFilter {
    filterType: "group"
    conditionType: HeaderConditionType
    filter: string
    provider: string
}

interface HeaderFilterProps {
    onApply: (filter: HeaderFilterValue) => void
    handleClose: () => void
}

export function HeaderFilter(props: HeaderFilterProps) {
    const [usernameFilter, setUsernameFilter] = useState<UsernameFilter>();
    const [groupFilter, setGroupFilter] = useState<GroupFilter>();

    const validate = (g?: GroupFilter | UsernameFilter) => {
        if(!g) {
            return false;
        }

        if (g.conditionType === HeaderConditionType.Empty) {
            return false;
        }

        if(g.filter.trim() === "") {
            return false;
        }

        return true;
    };


    return (<>
        <Stack sx={{ flex: 1, height: "100%", marginTop: "10px", marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
            <GroupFilterOption id="group-filter-toggle" setState={setGroupFilter} state={groupFilter} />
            <UserFilterOption id="username-filter-toggle" setState={setUsernameFilter} state={usernameFilter} />
            <Box display="flex" sx={{ marginTop: "40px" }}>
                <Button
                    id="cancel-header-filter-button"
                    variant="outlined"
                    size="medium"
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    id="apply-header-filter-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        props.onApply({
                            groupFilter: validate(groupFilter) ? groupFilter : undefined,
                            usernameFilter: validate(usernameFilter) ? usernameFilter : undefined,
                        });

                        props.handleClose();
                    }}
                >
                    Apply Filters
                </LoadingButton>
            </Box>
        </Stack>
    </>);
}

interface UserFilterOptionsProps {
    id: string
    state?: UsernameFilter
    setState: (u?: UsernameFilter) => void
}

function UserFilterOption(props: UserFilterOptionsProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onChangeValue = (newValue: string) => {
        props.setState({
            ...props.state!,
            filter: newValue,
        });
    };

    return (
        <>
            {
                <FormControl sx={{ marginTop: "20px" }} component="fieldset">
                    <FormLabel id={props.id} component="legend" sx={{ display: "flex", alignItems: "center", cursor: "pointer", ":hover": { color: "#2158c3" } }}
                        onClick={() => setIsOpen(!isOpen)} >
                        {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}<Typography component={"span"} sx={{ fontWeight: "bold" }}>Username</Typography>
                    </FormLabel>
                    {isOpen ? <RadioGroup
                        aria-label="obfuscated"
                        name="radio-obfuscated"
                        sx={{ marginLeft: "25px" }}
                        value={props.state?.conditionType}
                        onChange={(e, value) => {
                            if(value === "") {
                                props.setState(undefined);
                            } else {
                                props.setState({
                                    filterType: "username",
                                    conditionType: value as HeaderConditionType,
                                    filter: "",
                                });
                            }
                        }}
                    >
                        <FormControlLabel value="equal" control={<Radio />} label="is equal" />
                        {props.state?.conditionType === HeaderConditionType.Equal &&  <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} />}
                        <FormControlLabel value="not_equal" control={<Radio />} label="is not equal" />
                        {props.state?.conditionType === HeaderConditionType.NotEqual && <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} />}
                        <FormControlLabel value="contains" control={<Radio />} label="contains" />
                        {props.state?.conditionType === HeaderConditionType.Contains ? <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} /> : null}
                        <FormControlLabel value="not_contains" control={<Radio />} label="does not contain" />
                        {props.state?.conditionType === HeaderConditionType.DoesNotContain ? <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} /> : null}
                    </RadioGroup> : null}
                </FormControl>
            }
        </>);
}

interface GroupFilterOptionsProps {
    id: string
    state?: GroupFilter
    setState: (v?: GroupFilter) => void
}

function GroupFilterOption(props: GroupFilterOptionsProps) {
    const login = useLogin();
    const [isOpen, setIsOpen] = useState<boolean>(true);


    const [provider, setProvider] = useState("");
    const [groupOptions, setGroupOptions] = useState<string[]>([]);


    const init = async () => {
        const req = login.GetAxios();

        if(!req) {
            return;
        }

        try {
            const groups = await req.get("/api/user/groups", {
                params: {
                    "skiphidden": "true"
                }
            });
            
            setProvider(groups.data.currentProvider);
            setGroupOptions(groups.data.groups.map((x: any) => {
                return x.identifier;
            }));
        } catch {
            console.error("could not fetch group filters");
        }
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (newValue: string) => {
        props.setState({
            ...props.state!,
            filter: newValue,
        });
    };

    return (
        <>
            {
                <FormControl sx={{ marginTop:"0px" }} component="fieldset">
                    <FormLabel id={props.id} component="legend" sx={{ display: "flex", alignItems: "center", cursor: "pointer", ":hover": { color: "#2158c3" } }}
                        onClick={() => setIsOpen(!isOpen)} >
                        {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}<Typography component={"span"} sx={{ fontWeight: "bold" }}>Groups</Typography>
                    </FormLabel>
                    {isOpen ? <RadioGroup
                        aria-label="obfuscated"
                        name="radio-obfuscated"
                        sx={{ marginLeft: "25px" }}
                        value={props.state?.conditionType}
                        onChange={(e, value) => {
                            if(value === "") {
                                props.setState(undefined);
                            } else {
                                props.setState({
                                    filterType: "group",
                                    conditionType: value as HeaderConditionType,
                                    filter: "",
                                    provider: provider,
                                });
                            }
                        }}
                    >
                        <FormControlLabel value="equal" control={<Radio />} label="is equal" />
                        {props.state?.conditionType === HeaderConditionType.Equal && <GroupSelect id="select_group" value={props.state.filter} options={groupOptions} onChange={onChangeValue} />}
                        <FormControlLabel value="not_equal" control={<Radio />} label="is not equal" />
                        {props.state?.conditionType === HeaderConditionType.NotEqual && <GroupSelect id="select_group" value={props.state.filter} options={groupOptions} onChange={onChangeValue} />}
                        <FormControlLabel value="contains" control={<Radio />} label="contains" />
                        {props.state?.conditionType === HeaderConditionType.Contains ? <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} /> : null}
                        <FormControlLabel value="not_contains" control={<Radio />} label="does not contain" />
                        {props.state?.conditionType === HeaderConditionType.DoesNotContain ? <TextField size="small" variant="outlined" value={props.state.filter} onChange={(e) => onChangeValue(e.target.value)} /> : null}
                    </RadioGroup> : null}
                </FormControl>
            }
        </>);
}

interface GroupSelectProps {
    id: string
    value: string
    options: string[]
    onChange: (v: string) => void;
}

function GroupSelect(props: GroupSelectProps) {
    useEffect(() => {
        if(!props.options.includes(props.value)) {
            props.onChange("");
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps


    return <FormControl size="small">
        <Select
            id={props.id}
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}
        >
            {props.options.map(x => {
                return <MenuItem value={x}>{x}</MenuItem>;
            })}
        </Select>
    </FormControl>;
}