import { Alert, Button, Chip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import DataTable, { Column, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";
import { AdminPanelSettingsOutlined, ArrowDropDown, Refresh } from "@mui/icons-material";
import countries from "i18n-iso-countries";
import countriesEn from "i18n-iso-countries/langs/en.json";
import "flatpickr/dist/themes/airbnb.css";
import { ChipList } from "../../../../components/ChipList";
import { DateCondition, DateFilter, FilterButton, FilterFreeInput, FilterValueSelector } from "../Activity/filterbar";

countries.registerLocale(countriesEn);

interface AlertLogListState {
    count: number
    items: AlertLogListStateItem[]
}

interface AlertLogListStateItem {
    id: string
    alertId: string
    alertName: string
    activitySearchQuery: string
    notificationTypes: number[]
    emailRecipients: string[]
    slackChannels: string[]
    occurrencesCount: number
    timestamp: Date
    isTest: boolean
}

type columnsType = "timestamp" | "alertName" | "emailRecipients" | "occurrencesCount";


type SortDirection = "asc" | "desc";
type SortField = "timestamp" | "alertName" | "occurrencesCount";

interface FilterBarSortData {
    field: SortField
    order: SortDirection
}

export default function AlertLogs() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);

    const [takeCount, setTakeCount] = useState(25);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [initLoad, setInitLoad] = useState(true);

    const [skipItems, setSkipItems] = useState(0);

    const [lastError, setLastError] = useState("");

    const [showTimestampFilter, setShowTimestampfilter] = useState(false);
    const [showAlertnameFilter, setShowAlertnameFilter] = useState(false);
    const [showSlackChannelsFilter, setShowSlackChannelsFilter] = useState(false);
    const [showEmailRecipientsFilter, setShowEmailRecipientsFilter] = useState(false);
    const [showNotificationTypesFilter, setShowNotificationTypesFilter] = useState(false);

    const [notificationTypesFilter, setNotificationTypesFilter] = useState([] as string[]);
    const [alertNamesFilter, setAlertNamesFilter] = useState([] as string[]);
    const [slackChannelsFilter, setSlackChannelsFilter] = useState([] as string[]);
    const [emailRecipientsFilter, setEmailRecipientsFilter] = useState([] as string[]);
    const [timestampFilter, setTimeStampFilter] = useState(undefined as DateCondition | undefined);



    const columnsDataId = "dataGrid_alertLogTable_columns";

    const defaultColumns: Column<columnsType>[] = [
        { columnId: "timestamp", width: "20%", direction: "desc", sortOrder: 1 },
        { columnId: "alertName", width: "35%" },
        { columnId: "emailRecipients", width: "30%" },
        { columnId: "occurrencesCount", width: "15%" },
    ];

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>(defaultColumns);

    const [listState, setListState] = useState({ items: [], count: 0 } as AlertLogListState);

    const req = login.GetAxios();

    const getFilterSorting = (c: typeof columnsState) => {
        return c.filter(x => x.sortOrder !== undefined).sort((a, b) => a.sortOrder! - b.sortOrder!).map(x => {
            return {
                field: x.columnId,
                order: x.direction!,
            } as FilterBarSortData;
        });
    };

    const getQueryFromSimple = async (sort: typeof columnsState) => {
        const r = login.GetAxios();
        if (!r) {
            return;
        }

        const x = {
            apps: [],
            clientDevices: [],
            clientIpAddresses: [],
            contentTypes: [],
            detected: [],
            detectedDatatypes: [],
            hostnames: [],
            instancesIds: [],
            location: [],
            obfuscated: [],
            obfuscatedDatatypes: [],
            originalValues: [],
            originalValueStored: [],
            rules: [],
            scanned: [],
            types: [],
            userGroups: [],
            usernames: [],
            timestamp: timestampFilter,
            alertNames: alertNamesFilter,
            slackChannels: slackChannelsFilter,
            emailRecipients: emailRecipientsFilter,
            notificationTypes: notificationTypesFilter,
        };

        const conversion = await r.post("/api/activities/convertsearch", {
            simpleToAdvanced: {
                ...x,
                orderBy: getFilterSorting(sort),
            }
        });

        return conversion.data.simpleToAdvanced || "";
    };

    const loadData = async (colState: typeof columnsState, query: string, itensPerPage: number, skip: number) => {
        setFinishedLoading(false);
        setLastUpdated(new Date());
        setLastError("");

        if (!req) {
            return; //not logged in, nothing to do
        }
        try {


            const resp = await req.get("/api/alertlogs", {
                params: {
                    take: itensPerPage,
                    skip: skip,
                    search: query
                }
            });

            setListState({
                count: resp.data.count,
                items: (resp.data.items || []).map((x: any) => {
                    const item: AlertLogListStateItem = {
                        ...x,
                        timestamp: new Date(x.timestamp * 1000),
                    };
                    return item;
                })
            });

            const sortBy = resp.data.orderBy as FilterBarSortData[];
            const newState = colState.map(x => {
                const idx = sortBy.findIndex(y => y.field === x.columnId);
                if (idx === -1) {
                    return {
                        ...x,
                        sortOrder: undefined,
                        direction: undefined,
                    };
                }

                return {
                    ...x,
                    sortOrder: idx + 1,
                    direction: sortBy[idx].order
                };
            });

            //if the column we are sorting by is not present, we must add it
            sortBy.forEach((x, idx) => {
                const found = newState.find(y => y.columnId === x.field);
                if (!found) {
                    newState.push({
                        columnId: x.field,
                        sortOrder: idx + 1,
                        direction: x.order,
                    });
                }
            });


            setColumnsState(newState);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                } else {
                    setLastError(body.message);
                }
            }
            console.error(err);
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
            setListState({
                count: 0,
                items: [],
            });
        }
        setFinishedLoading(true);
    };


    const firstLoad = async () => {
        const col = RestoreColumnsState("alertLog", defaultColumns);
        const query = await getQueryFromSimple(col);
        await loadData(col, query, takeCount, skipItems);
        setInitLoad(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = async () => {
        const query = await getQueryFromSimple(columnsState);
        if (!initLoad) {
            loadData(columnsState, query, takeCount, skipItems);
        }        
    };

    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timestampFilter, alertNamesFilter, slackChannelsFilter, emailRecipientsFilter, notificationTypesFilter]);

    useEffect(() => {
        firstLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        SaveColumnsState("alertLog", columnsState);
    }, [columnsState]);


    return <>
        <Box id="alertLog-page" display="flex" justifyContent="space-between">
            <Box >
                <Typography variant="h1" display="block">Alert Log</Typography>
                <Typography variant="body2" color="text.secondary">
                    Last updated at {lastUpdated.toLocaleDateString()} {lastUpdated.toLocaleTimeString()} <Button size="small" variant="text" onClick={async () => {
                        const query = await getQueryFromSimple(columnsState);
                        loadData(columnsState, query,  takeCount, skipItems);
                    }}><Refresh sx={{ fontSize: "12px", marginRight: "2px" }} />refresh</Button>
                </Typography>
            </Box>
        </Box>
        {
            lastError && <Alert severity="error" sx={{ width: "100%", marginBottom: "10px", marginTop: "10px", whiteSpace: "pre-line" }}>{lastError}</Alert>
        }


        <Stack direction={"row"} gap={1} marginBottom={"10px"}>
            <Stack direction={"row"} gap={2}>
                <FilterButton
                    name={"Timestamp"}
                    disableClickAway={true}
                    open={showTimestampFilter}
                    onClick={() => setShowTimestampfilter(true)}
                    onMenuClose={() => setShowTimestampfilter(false)}
                    active={timestampFilter !== undefined}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <DateFilter state={timestampFilter || {}} stateChanged={async (s) => {
                        setTimeStampFilter(s);
                    }} />
                </FilterButton>
                <FilterButton
                    name={"Alert Name"}
                    open={showAlertnameFilter}
                    onClick={() => {
                        setShowTimestampfilter(false);
                        setShowAlertnameFilter(true);
                    }}
                    onMenuClose={() => setShowAlertnameFilter(false)}
                    active={alertNamesFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={alertNamesFilter}
                        inputPlaceholder="Search Alert Name"
                        itemAdded={(x) => {
                            setAlertNamesFilter(alertNamesFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setAlertNamesFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setAlertNamesFilter(alertNamesFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"Notification Types"}
                    open={showNotificationTypesFilter}
                    onClick={() => {
                        setShowTimestampfilter(false);
                        setShowNotificationTypesFilter(true);
                    }}
                    onMenuClose={() => setShowNotificationTypesFilter(false)}
                    active={notificationTypesFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterValueSelector
                        displayShowDeleted={false}
                        enableClearButton={true}
                        items={[
                            { deleted: false, id: "1", label: "Email" },
                            { deleted: false, id: "2", label: "Slack" },
                            { deleted: false, id: "3", label: "Webhook" },
                        ]}
                        selected={notificationTypesFilter}
                        stateChanged={(x) => {
                            setNotificationTypesFilter(x);
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"Email Recipients"}
                    open={showEmailRecipientsFilter}
                    onClick={() => {
                        setShowTimestampfilter(false);
                        setShowEmailRecipientsFilter(true);
                    }}
                    onMenuClose={() => setShowEmailRecipientsFilter(false)}
                    active={emailRecipientsFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={emailRecipientsFilter}
                        inputPlaceholder="Search Email Recipients"
                        itemAdded={(x) => {
                            setEmailRecipientsFilter(emailRecipientsFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setEmailRecipientsFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setEmailRecipientsFilter(emailRecipientsFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
                <FilterButton
                    name={"Slack Channels"}
                    open={showSlackChannelsFilter}
                    onClick={() => {
                        setShowTimestampfilter(false);
                        setShowSlackChannelsFilter(true);
                    }}
                    onMenuClose={() => setShowSlackChannelsFilter(false)}
                    active={slackChannelsFilter.length > 0}
                    endIcon={<ArrowDropDown fontSize="small" />}
                >
                    <FilterFreeInput
                        items={slackChannelsFilter}
                        inputPlaceholder="Search Notification Value"
                        itemAdded={(x) => {
                            setSlackChannelsFilter(slackChannelsFilter.concat(x));
                            return undefined;
                        }}
                        clearItems={() => {
                            setSlackChannelsFilter([]);
                        }}
                        itemDeleted={(x) => {
                            setSlackChannelsFilter(slackChannelsFilter.filter(y => y !== x));
                        }}
                    />
                </FilterButton>
            </Stack>
            <Stack flexGrow={1} direction={"row"} justifyContent={"end"}>
                <Button variant="outlined" onClick={() => {
                    setSlackChannelsFilter([]);
                    setTimeStampFilter(undefined);
                    setAlertNamesFilter([]);
                    setEmailRecipientsFilter([]);
                    setNotificationTypesFilter([]);
                }}>Clear Filters</Button>
            </Stack>
        </Stack>
        <DataTable
            sx={{
                maxHeight: "90%",
            }}
            size={"medium"}
            dataCount={listState.count}
            paginationStartItem={skipItems}
            paginationItemCount={takeCount}
            onPaginationStartItemChanged={async (x) => {
                setSkipItems(x);
                setTakeCount(takeCount);
                const query = await getQueryFromSimple(columnsState);
                loadData(columnsState, query, takeCount, x);
            }}
            onPaginationItemCountChanged={async (x) => {
                setTakeCount(Number(x));
                const query = await getQueryFromSimple(columnsState);
                loadData(columnsState, query, Number(x), skipItems);
            }}
            onSortChanged={async (c) => {
                const r = login.GetAxios();
                if (!r) {
                    return;
                }

                const query = await getQueryFromSimple(columnsState);
                const conversion = await r.post("/api/activities/convertsearch", {
                    modifyAdvanced: {
                        query: query,
                        orderBy: getFilterSorting(c),
                    }
                });

                const response = conversion.data.modifyAdvanced || "";

                loadData(columnsState, response, takeCount, skipItems);
            }}
            data={listState.items}
            columnsState={columnsState}
            finishedLoading={finishedLoading}
            onColumnOrderChanged={(x) => setColumnsState(x)}
            columnsDataId={columnsDataId}
            columns={{
                timestamp: {
                    displayName: "Timestamp",
                    sortable: true,
                    render: (x) => {
                        return <Typography fontSize="small">{`${x.timestamp.toLocaleDateString()} ${x.timestamp.toLocaleTimeString()}`}</Typography>;
                    },
                },
                alertName: {
                    displayName: "Alert Name",
                    sortable: true,
                    render: (x) => {
                        return x.alertName;
                    }
                },
                emailRecipients: {
                    displayName: "Notifications",
                    render: (x) => {
                        return <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start",
                            flexDirection: "row",
                            gap: "2px"
                        }}>
                            <ChipList items={x.emailRecipients} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="primary" />} />
                            <ChipList items={x.slackChannels} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="success" />} />
                            {x.notificationTypes && x.notificationTypes.includes(3) && <Chip label={"Webhook"} title={"Webhook"} variant="outlined" size="small" color="warning" />}
                        </Box>;

                    },
                },
                occurrencesCount: {
                    sortable: true,
                    displayName: "Occurrences Count",
                    render: (x) => {
                        return x.occurrencesCount;
                    }
                },
            }
            }
            rowOptionsMenu={[
                {
                    icon: <AdminPanelSettingsOutlined className="show-activity-button" fontSize="small" />,
                    text: "See Data Log",
                    onClick: (x) => {
                        window.open("/activities?query=" + encodeURIComponent(x.activitySearchQuery), "_blank");
                    },
                    isVisible: (x) => { return !x.isTest; },
                }
            ]}
        />
    </>;
}