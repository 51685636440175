import { Button, ButtonGroup, Checkbox, Divider, Fade, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputBase, List, ListItem, ListItemButton, ListItemText, MenuItem, MenuList, OutlinedInput, Paper, Popover, Popper, Radio, RadioGroup, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";

import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Flatpickr from "react-flatpickr";
import { Event } from "@mui/icons-material";
import { QueryInput } from "./queryinput";

interface FilterBarProps {
    data: FilterFieldData
    query: string
    loading: boolean
    simpleFilterState: SimpleFilterState
    onSimpleQueryChange?: (s: SimpleFilterState) => void
    onAdvancedQueryChange?: (s: string) => void
    onAdvancedQuerySubmit?: () => void
    onClearFilter: () => void
    onAdvancedToSimpleChanged: () => Promise<boolean>
}

export interface FilterFieldData {
    apps: FilterItem[]
    contentTypes: FilterItem[]
    clientDevice: FilterItem[]
    rules: FilterItem[]
    detectedDataType: FilterItem[]
    obfuscatedDataType: FilterItem[]
    types: FilterItem[]
    originalValueStored: FilterItem[]
    scanned: FilterItem[]
    detected: FilterItem[]
    obfuscated: FilterItem[]
    usernames: FilterItem[]
    userGroups: FilterItem[]
    countries: FilterItem[]
}

interface FilterComponent {
    label: string
    hideable: boolean
    disableClickAway?: boolean
    count: number
    content: React.ReactNode
    active: boolean
    onRemove: () => void
}

export interface SimpleFilterState {
    apps: string[]
    contentTypes: string[]
    clientDevices: string[]
    rules: string[]
    detectedDatatypes: string[]
    obfuscatedDatatypes: string[]
    scanned: string[]
    detected: string[]
    obfuscated: string[]
    types: string[]
    originalValueStored: string[]
    originalValues: string[]
    usernames: string[]
    userGroups: string[]
    hostnames: string[]
    timestamp?: DateCondition
    instancesIds: string[]
    clientIpAddresses: string[]
    location: string[]
}

export interface DateCondition {
    range?: { start?: Date, end?: Date }
    withinLast?: RelativeDateCondition
    before?: RelativeDateCondition
}

interface RelativeDateCondition {
    days?: number
    hours?: number
    minutes?: number
}

export function FilterBar(props: FilterBarProps) {
    const [currentFilter, setCurrentFilter] = useState<string | undefined>("");
    const [filterMode, setFilterMode] = useState<"simple" | "advanced">("simple");

    const currentState = props.simpleFilterState as SimpleFilterState;

    const commonSearch = (label: string, possibleValues: FilterItem[], parameter: keyof SimpleFilterState, hideable: boolean, showDeletedButton: boolean) => {
        const v = currentState[parameter] as string[];
        return {
            label: label,
            count: v.length,
            content: <FilterValueSelector
                displayShowDeleted={showDeletedButton}
                enableClearButton={true}
                items={possibleValues}
                selected={v}
                stateChanged={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, [parameter]: x });
                }}
            />,
            active: v.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, [parameter]: [] });
            },
            hideable: hideable,
        };
    };

    const availableFields: FilterComponent[] = [
        {
            label: "Timestamp",
            disableClickAway: true,
            count: (currentState.timestamp) ? 1 : 0,
            content: <DateFilter state={currentState.timestamp || {}} stateChanged={(s) => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, timestamp: s });
            }} />,
            active: currentState.timestamp !== undefined,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, timestamp: undefined });
            },
            hideable: false,
        },
        commonSearch("Rule", props.data.rules, "rules", true, true),
        {
            label: "Shield Instance",
            count: currentState.instancesIds.length,
            content: <FilterFreeInput
                items={currentState.instancesIds}
                inputPlaceholder="Search Shield Instance"
                itemAdded={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, instancesIds: currentState.instancesIds.concat(x) });
                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, instancesIds: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, instancesIds: currentState.instancesIds.filter(y => y !== x) });
                }}
            />,
            active: currentState.instancesIds.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, instancesIds: [] });
            },
            hideable: true,
        },
        {
            label: "Origin",
            count: currentState.hostnames.length,
            content: <FilterFreeInput
                items={currentState.hostnames}
                inputPlaceholder="Search Origin"
                itemAdded={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, hostnames: currentState.hostnames.concat(x) });
                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, hostnames: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, hostnames: currentState.hostnames.filter(y => y !== x) });
                }}
            />,
            active: currentState.hostnames.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, hostnames: [] });
            },
            hideable: true,
        },
        {
            label: "Data Values",
            count: currentState.originalValues.length,
            content: <FilterFreeInput
                items={currentState.originalValues}
                inputPlaceholder="Search Data Values"
                itemAdded={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, originalValues: currentState.originalValues.concat(x) });
                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, originalValues: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, originalValues: currentState.originalValues.filter(y => y !== x) });
                }}
            />,
            active: currentState.originalValues.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, originalValues: [] });
            },
            hideable: true,
        },
        {
            label: "Content Type",
            count: currentState.contentTypes.length,
            content: <FilterGroupedValueSelector
                enableClearButton={true}
                items={props.data.contentTypes.map(x => {
                    return {
                        id: x.id,
                        group: capitalizeFirstLetter(x.label.split("/")[0]),
                        label: x.label,
                    };
                })}
                selected={currentState.contentTypes}
                stateChanged={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, contentTypes: x });
                }}
            />,
            active: currentState.contentTypes.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, contentTypes: [] });
            },
            hideable: true,
        },
        {
            label: "Client IP Address",
            count: currentState.clientIpAddresses.length,
            content: <FilterFreeInput
                items={currentState.clientIpAddresses}
                inputPlaceholder="Search IP Address"
                itemAdded={(x) => {
                    if (!validateIP(x)) {
                        return "Not a valid ip address";
                    }
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, clientIpAddresses: currentState.clientIpAddresses.concat(x) });

                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, clientIpAddresses: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, clientIpAddresses: currentState.clientIpAddresses.filter(y => y !== x) });
                }}
            />,
            active: currentState.clientIpAddresses.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, clientIpAddresses: [] });
            },
            hideable: true,
        },
        {
            label: "Username",
            count: currentState.usernames.length,
            content: <FilterFreeInput
                items={currentState.usernames}
                inputPlaceholder="Search Username"
                itemAdded={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, usernames: currentState.usernames.concat(x) });
                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, usernames: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, usernames: currentState.usernames.filter(y => y !== x) });
                }}
            />,
            active: currentState.usernames.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, usernames: [] });
            },
            hideable: false,
        },
        commonSearch("Apps", props.data.apps, "apps", false, true),
        {
            label: "User Group",
            count: currentState.userGroups.length,
            content: <FilterFreeInput
                items={currentState.userGroups}
                inputPlaceholder="Search User Group"
                itemAdded={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, userGroups: currentState.userGroups.concat(x) });
                    return undefined;
                }}
                clearItems={() => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, userGroups: [] });
                }}
                itemDeleted={(x) => {
                    props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, userGroups: currentState.userGroups.filter(y => y !== x) });
                }}
            />,
            active: currentState.userGroups.length > 0,
            onRemove: () => {
                props.onSimpleQueryChange && props.onSimpleQueryChange({ ...currentState, userGroups: [] });
            },
            hideable: true,
        },
        commonSearch("Type", props.data.types, "types", true, false),
        commonSearch("Client Device", props.data.clientDevice, "clientDevices", true, false),
        commonSearch("Detected Data Type", props.data.detectedDataType, "detectedDatatypes", false, false),
        commonSearch("Obfuscated Data Type", props.data.obfuscatedDataType, "obfuscatedDatatypes", true, false),
        commonSearch("Values Stored", props.data.originalValueStored, "originalValueStored", true, false),
        commonSearch("Scanned", props.data.scanned, "scanned", true, false),
        commonSearch("Detected", props.data.detected, "detected", true, false),
        commonSearch("Obfuscated", props.data.obfuscated, "obfuscated", true, false),
        commonSearch("Client IP Location", props.data.countries, "location", true, false),
    ];

    const [activeFields, setActiveFields] = useState<string[]>(
        availableFields.map((x, i) => { return { index: i, hideable: x.hideable }; }).filter(x => !x.hideable).map((x) => `${x.index}`)
    );

    useEffect(() => {
        const mustBeActive = availableFields.map((x, i) => { return { index: `${i}`, hideable: x.hideable, active: x.active }; }).filter(x => !x.hideable || x.active);

        let result = activeFields;
        mustBeActive.forEach(x => {
            if (!activeFields.includes(x.index)) {
                result = result.concat(x.index);
            }
        });
        setActiveFields(result);
    }, [props.simpleFilterState]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Grid sx={{ marginRight: "5px" }} container>
        <Grid item sm={10}>
            {filterMode === "simple" &&
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                }}>
                    {activeFields.map((x) => {
                        const current = availableFields[Number(x)];
                        return <FilterButton
                            key={x}
                            name={current.label}
                            disableClickAway={current.disableClickAway}
                            open={currentFilter === x}
                            count={current.count}
                            onClick={() => setCurrentFilter(x)}
                            onMenuClose={() => setCurrentFilter(undefined)}
                            onClose={current.hideable ? () => {
                                setActiveFields(activeFields.filter(y => y !== x));
                                current.onRemove();
                            } : undefined}
                            active={current.active}
                            endIcon={!current.hideable ? <ArrowDropDownIcon fontSize="small" /> : undefined}
                        >
                            {current.content}
                        </FilterButton>;
                    })}

                    <FilterButton
                        name="More"
                        endIcon={<AddIcon fontSize="small" />}
                        onClick={() => setCurrentFilter("-1")}
                        open={currentFilter === "-1"}
                        onMenuClose={() => setCurrentFilter(undefined)}
                    >
                        <FilterValueSelector
                            displayShowDeleted={false}
                            enableClearButton={false}
                            items={availableFields.map((x, id) => { return { id: `${id}`, label: x.label, hideable: x.hideable, deleted: false }; }).filter(x => x.hideable)}
                            selected={activeFields}
                            itemChanged={(item, added) => {
                                if (added) {
                                    setActiveFields(activeFields.concat(item));
                                    setCurrentFilter(item);
                                } else {
                                    setActiveFields(activeFields.filter(k => k !== item));
                                }
                            }}
                        />
                    </FilterButton>
                </Box>
            }
            {filterMode === "advanced" &&
                <Box display="flex">
                    <QueryInput
                        code={props.query}
                        setCode={x => props.onAdvancedQueryChange && props.onAdvancedQueryChange(x)}
                        onKeyUp={(x) => (x.key === "Enter" && props.onAdvancedQuerySubmit && props.onAdvancedQuerySubmit())}
                    />
                    <Button variant="contained" sx={{ marginLeft: "5px", marginRight: "5px" }} onClick={() => props.onAdvancedQuerySubmit && props.onAdvancedQuerySubmit()}>
                        Search
                    </Button>
                </Box>

            }
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="end" alignItems="start">
            <Stack gap={1} direction={"row"}>
                <Button variant="outlined" onClick={() => props.onClearFilter()}>Clear Filters</Button>
                <LoadingButton
                    sx={{
                        marginLeft: "auto",
                        whiteSpace: "nowrap",
                    }}
                    loading={props.loading}
                    variant="outlined"
                    onClick={async () => {
                        if (filterMode === "simple") {
                            setFilterMode("advanced");
                        } else {
                            if (await props.onAdvancedToSimpleChanged()) {
                                setFilterMode("simple");
                            }
                        }
                    }}
                >
                    {filterMode === "advanced" && "Simple Mode"}
                    {filterMode === "simple" && "Advanced Mode"}
                </LoadingButton>
            </Stack>
        </Grid>

    </Grid>;
}

export interface FilterButtonProps {
    active?: boolean
    name: string
    count?: number
    disableClickAway?: boolean
    open: boolean
    children?: React.ReactNode
    endIcon?: React.ReactNode
    onClick: () => void
    onMenuClose: () => void
    onClose?: () => void
}


export function FilterButton(props: FilterButtonProps) {
    const ref = useRef(null);
    const [_, setRefState] = useState<any>(); // eslint-disable-line @typescript-eslint/no-unused-vars


    useEffect(() => {
        setRefState(ref);
    }, [ref]);
    return <>
        <ButtonGroup>
            <Button
                ref={ref}
                disableElevation
                variant={props.active ? "contained" : "outlined"}
                onClick={props.onClick}
                endIcon={props.endIcon}
            >{!props.count ? props.name : `${props.name} (${props.count})`}</Button>
            {(props.onClose || (props.disableClickAway && props.open)) &&
                <Button
                    size="small"
                    variant={props.active ? "contained" : "outlined"}
                    disableElevation
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        } else {
                            props.onMenuClose();
                        }
                    }} 
                >
                    <CloseIcon fontSize="small" />
                </Button>
            }

        </ButtonGroup>

        {props.disableClickAway ?
            <Popper
                sx={{ 
                    zIndex: 1000, 
                    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
                }}
                open={props.open}
                anchorEl={ref.current}
                placement="bottom-start"
                transition 
                onPointerOverCapture={undefined} 
                onPointerMoveCapture={undefined} 
                onResize={undefined} 
                onResizeCapture={undefined}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            {props.children}
                        </Paper>
                    </Fade>
                )}
            </Popper>
            :
            <Popover
                open={props.open}
                anchorEl={ref.current}
                onClose={props.onMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {props.children}
            </Popover>
        }
    </>;
}


interface FilterValueSelectorProps {
    items: FilterItem[]
    enableClearButton: boolean
    selected?: string[]
    displayShowDeleted: boolean
    itemChanged?: (changedItem: string, added: boolean) => void;
    stateChanged?: (selected: string[]) => void
}

export interface FilterItem {
    id: string
    label: string
    deleted: boolean
}

export function FilterValueSelector(props: FilterValueSelectorProps) {
    const [searchQuery, setSearchQuery] = useState("");
    const [hide, setHide] = useState<string[]>([]);
    const [showDeleted, setShowDeleted] = useState(false);

    useEffect(() => {
        const newHide = [];
        const sh = searchQuery.toLocaleLowerCase();
        for (const o of props.items) {
            if (!o.label.toLocaleLowerCase().includes(sh)) {
                newHide.push(o.id);
            }
        }

        setHide(newHide);
    }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleItem = (id: string) => {
        if (props.selected?.includes(id)) {
            const n = props.selected.filter(k => k !== id);
            props.stateChanged && props.stateChanged(n);
            props.itemChanged && props.itemChanged(id, false);
        } else {
            const n = (props.selected || []).concat(id);
            props.stateChanged && props.stateChanged(n);
            props.itemChanged && props.itemChanged(id, true);
        }
    };

    const filteredItems = props.items.filter(x => !hide.includes(x.id)).filter(k => ((showDeleted && k.deleted) || !k.deleted));

    return (
        <Box sx={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px", marginBottom: "10px", minWidth: "300px" }}>
            <Box
                sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
            >
                <InputBase
                    autoFocus
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(x) => setSearchQuery(x.target.value)}
                />
                <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Box>
            <Divider />
            <Box maxHeight="400px" overflow="auto">
                {filteredItems.length > 0
                    ? <MenuList>
                        {filteredItems.map(x =>
                            <MenuItem disableRipple key={x.id}>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{
                                            textDecoration: x.deleted ? "line-through" : undefined,
                                        }}
                                        control={<Checkbox checked={props.selected?.includes(x.id)} />}
                                        label={<Typography color={x.id === "no match" ? "error" : "black"}>{x.label}</Typography>}
                                        onChange={() => {
                                            toggleItem(x.id);
                                        }}
                                    />
                                </FormGroup>
                            </MenuItem>
                        )}
                    </MenuList>
                    : <Typography sx={{ "margin": "20px" }} align="center" >No results to show</Typography>}

            </Box>
            <Stack direction="row" justifyContent="space-between">
                {props.displayShowDeleted &&
                    <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />} label="Show deleted" />
                    </FormGroup>
                }

                {props.enableClearButton &&
                    <Button sx={{ marginTop: "5px" }} onClick={() => {
                        props.stateChanged && props.stateChanged([]);
                    }}>Clear selection</Button>
                }
            </Stack>
        </Box>
    );
}

interface FilterFreeInputOpts {
    items: string[]
    inputPlaceholder?: string
    itemAdded?: (v: string) => (string | undefined)
    itemDeleted?: (v: string) => void
    clearItems?: () => void
}


export function FilterFreeInput(props: FilterFreeInputOpts) {
    const [newItemText, setNewItemText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const addItem = () => {
        if (!props.itemAdded) {
            return;
        }

        if (newItemText === "") {
            return;
        }

        if (props.items.includes(newItemText)) {
            setErrorMessage("Item already on the list");
            return;
        }

        const ret = props.itemAdded(newItemText);
        if (ret) {
            setErrorMessage(ret);
            return;
        }

        setNewItemText("");
    };

    return (
        <Box sx={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
            <Box
                sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
            >
                <InputBase
                    autoFocus
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={props.inputPlaceholder}
                    value={newItemText}
                    onChange={(x) => { setNewItemText(x.target.value); setErrorMessage(""); }}
                    onKeyUp={(x) => (x.key === "Enter" && addItem())}
                />
                <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    onClick={addItem}
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <Divider />
            {errorMessage && <Typography variant="caption" color="error" sx={{ marginLeft: "5px" }}>{errorMessage}</Typography>}

            <Box maxHeight="200px" overflow="auto">
                <List sx={{ width: "100%" }}>
                    {props.items.length > 0
                        ? props.items.map((x, i) => {
                            return <ListItem key={i}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => props.itemDeleted && props.itemDeleted(x)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText disableTypography >
                                        <Typography variant="body1" color="#152662" >{x}</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>;
                        })
                        : <Typography sx={{ "margin": "10px" }} align="center">No itens to show</Typography>
                    }

                </List>
            </Box>


            <Button onClick={() => {
                props.clearItems && props.clearItems();
            }}>Clear items</Button>
        </Box>
    );
}


export interface DateFilterProps {
    state: DateCondition
    stateChanged?: (d?: DateCondition) => void
}

export type DateFilterModes = "range" | "within" | "more_than";
export type DateWithinUnits = "days" | "hours" | "minutes";
export function DateFilter(props: DateFilterProps) {
    const [mode, setMode] = useState<DateFilterModes>("range");

    const [startDateInput, setStartDateInput] = useState<any>(null);
    const [endDateInput, setEndDateInput] = useState<any>(null);

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const [withinInput, setWithinInput] = useState("");
    const [withinUnit, setWithinUnit] = useState<DateWithinUnits>("minutes");

    const [moreThanInput, setMoreThanInput] = useState("");
    const [moreThanUnit, setMoreThanUnit] = useState<DateWithinUnits>("minutes");

    let today = new Date();
    today.setHours(23, 59, 59);

    useEffect(() => {
        if (props.state.range) {
            setStartDate(props.state.range.start);
            setEndDate(props.state.range.end);
            setMode("range");
        } else if (props.state.withinLast) {
            const look: DateWithinUnits[] = ["days", "hours", "minutes"];

            setWithinUnit("minutes");
            setWithinInput("");
            for (const k of look) {
                if (props.state.withinLast[k]) {
                    setWithinUnit(k);
                    setWithinInput(`${props.state.withinLast[k]}`);
                }
            }
            setMode("within");
        } else if (props.state.before) {
            const look: DateWithinUnits[] = ["days", "hours", "minutes"];

            setMoreThanUnit("minutes");
            setMoreThanInput("");
            for (const k of look) {
                if (props.state.before[k]) {
                    setMoreThanUnit(k);
                    setMoreThanInput(`${props.state.before[k]}`);
                }
            }
            setMode("more_than");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Box sx={{ padding: "10px" }}>
        <RadioGroup
            value={mode}
            onChange={(x) => setMode(x.target.value as any)}
        >
            <FormControlLabel value="range" control={<Radio />} label="In the range" />

            <Box display={mode === "range" ? undefined : "none"}>
                <Typography>Between</Typography>
                <Stack direction="row" marginTop="5px" alignItems="center">
                    <Flatpickr
                        ref={useCallback((el: any) => {
                            if (el) {
                                el.node.id = "start-date-input";
                            }
                            setStartDateInput(el);
                        }, [])}
                        value={startDate}
                        options={{
                            appendTo: document.body,
                            position: "below",
                            enableTime: true,
                            dateFormat: "m/d/Y H:i",
                            minDate: new Date(2020, 1, 1),
                            maxDate: (endDate || today)
                        }}
                        render={
                            ({ defaultValue, value, ...props }, ref) => {
                                return <OutlinedInput defaultValue={defaultValue} inputRef={ref}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton onClick={() => {
                                            startDateInput && startDateInput.flatpickr.open();
                                        }}>
                                            <Event />
                                        </IconButton>
                                    </InputAdornment>}
                                    sx={{
                                        maxWidth: "200px",
                                    }}
                                />;
                            }}
                        onChange={([date]) => {
                            const clone = new Date(date);
                            setStartDate(clone);
                        }}
                    />
                    <Typography marginLeft="10px">and</Typography>
                    <Box marginLeft="10px">
                        <Flatpickr
                            ref={useCallback((el: any) => {
                                if (el) {
                                    el.node.id = "end-date-input";
                                }
                                setEndDateInput(el);
                            }, [])}
                            value={endDate}
                            options={{
                                appendTo: document.body,
                                position: "below",
                                enableTime: true,
                                dateFormat: "m/d/Y H:i",
                                minDate: startDate || new Date(2020, 1, 1),
                                maxDate: today
                            }}
                            render={
                                ({ defaultValue, value, ...props }, ref) => {
                                    return <OutlinedInput defaultValue={defaultValue} inputRef={ref}
                                        endAdornment={<InputAdornment position="end">
                                            <IconButton onClick={() => {
                                                endDateInput && endDateInput.flatpickr.open();
                                            }}>
                                                <Event />
                                            </IconButton>
                                        </InputAdornment>}
                                        sx={{
                                            maxWidth: "200px",
                                        }}
                                    />;
                                }}
                            onChange={([date]) => {
                                const clone = new Date(date);
                                setEndDate(clone);
                            }}
                        />
                    </Box>
                </Stack>
            </Box>

            <FormControlLabel value="within" control={<Radio />} label="Within the last" />
            {
                mode === "within" && <Box>
                    <TextField
                        type="number"
                        value={withinInput}
                        onChange={(x) => {
                            setWithinInput(x.target.value);
                        }}
                    />
                    <Select
                        value={withinUnit}
                        onChange={x => setWithinUnit(x.target.value as any)}
                    >
                        <MenuItem value={"minutes"}>minutes</MenuItem>
                        <MenuItem value={"hours"}>hours</MenuItem>
                        <MenuItem value={"days"}>days</MenuItem>
                    </Select>
                </Box>
            }

            <FormControlLabel value="more_than" control={<Radio />} label="More than" />
            {
                mode === "more_than" && <Box>
                    <TextField
                        type="number"
                        value={moreThanInput}
                        onChange={(x) => {
                            setMoreThanInput(x.target.value);
                        }}
                    />
                    <Select
                        value={moreThanUnit}
                        onChange={x => setMoreThanUnit(x.target.value as any)}
                    >
                        <MenuItem value={"minutes"}>minutes ago</MenuItem>
                        <MenuItem value={"hours"}>hours ago</MenuItem>
                        <MenuItem value={"days"}>days ago</MenuItem>
                    </Select>
                </Box>
            }
        </RadioGroup>
        <Stack direction="row" justifyContent="end" marginTop="5px">
            <Button
                sx={{ marginLeft: "auto" }}
                variant="outlined"
                onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);


                    setWithinInput("");
                    setWithinUnit("minutes");

                    setMoreThanInput("");
                    setWithinUnit("minutes");
                }}
            >Clear</Button>
            <Button
                sx={{
                    marginLeft: "5px"
                }}
                variant="contained"
                onClick={() => {
                    if (mode === "range") {
                        if (startDate === undefined && endDate === undefined) {
                            props.stateChanged && props.stateChanged(undefined);
                            return;
                        }
                        props.stateChanged && props.stateChanged({
                            range: {
                                start: startDate,
                                end: endDate,
                            }
                        });
                    } else if (mode === "within") {
                        if (!withinInput || Number(withinInput) <= 0) {
                            props.stateChanged && props.stateChanged(undefined);
                            return;
                        }

                        const item: RelativeDateCondition = {};
                        item[withinUnit] = Number(withinInput);
                        props.stateChanged && props.stateChanged({
                            withinLast: item,
                        });
                    } else if (mode === "more_than") {
                        if (!moreThanInput || Number(moreThanInput) <= 0) {
                            props.stateChanged && props.stateChanged(undefined);
                            return;
                        }

                        const item: RelativeDateCondition = {};
                        item[moreThanUnit] = Number(moreThanInput);
                        props.stateChanged && props.stateChanged({
                            before: item,
                        });
                    }
                }}
            >
                Apply
            </Button>
        </Stack>
    </Box>;
}

function validateIP(s: string): boolean {
    const octets = s.split(".");
    if (octets.length !== 4) {
        return false;
    }

    const numbers = octets.map(x => Number(x)).filter(x => !Number.isNaN(x)).filter(x => x >= 0 && x <= 255);
    if (numbers.length !== 4) {
        return false;
    }

    return true;
}

interface FilterGroupedValueSelectorProps {
    items: FilterItemGrouped[]
    enableClearButton?: boolean
    selected?: string[]
    stateChanged?: (selected: string[]) => void
}

export interface FilterItemGrouped {
    id: string
    label: string
    group: string
}

function FilterGroupedValueSelector(props: FilterGroupedValueSelectorProps) {
    const [searchQuery, setSearchQuery] = useState("");
    const [hide, setHide] = useState<string[]>([]);

    useEffect(() => {
        const newHide = [];
        const sh = searchQuery.toLocaleLowerCase();
        for (const o of props.items) {
            if (!o.label.toLocaleLowerCase().includes(sh)) {
                newHide.push(o.id);
            }
        }

        setHide(newHide);
    }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleItem = (id: string) => {
        if (props.selected?.includes(id)) {
            const n = props.selected.filter(k => k !== id);
            props.stateChanged && props.stateChanged(n);
        } else {
            const n = (props.selected || []).concat(id);
            props.stateChanged && props.stateChanged(n);
        }
    };

    const filteredItems = props.items.filter(x => !hide.includes(x.id));

    const grouped = Array.from(new Set(filteredItems.map(x => x.group))).map(x => {
        return {
            group: x,
            itens: filteredItems.filter(k => k.group === x).sort((a, b) => a.label.localeCompare(b.label)),
        };
    }).sort((a, b) => a.group.localeCompare(b.group));

    return (
        <Box sx={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px", marginBottom: "10px", maxWidth: "600px", minWidth: "500px" }}>
            <Box
                sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
            >
                <InputBase
                    autoFocus
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(x) => setSearchQuery(x.target.value)}
                />
                <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Box>
            <Divider />
            <Box maxHeight="400px" overflow="auto">
                {filteredItems.length > 0
                    ? <MenuList>
                        {
                            grouped.map(item => {
                                const selectedItens = item.itens.map(x => props.selected?.includes(x.id) ? 1 as number : 0 as number).reduce((x, v) => { return x + v; });

                                return [
                                    searchQuery === "" ? <MenuItem disableRipple key={item.group}>
                                        <FormControlLabel
                                            label={item.group}
                                            control={
                                                <Checkbox
                                                    checked={selectedItens === item.itens.length}
                                                    indeterminate={selectedItens > 0 && selectedItens < item.itens.length}
                                                    onChange={() => {
                                                        if (selectedItens === item.itens.length) {
                                                            const v = props.selected?.filter(x => !item.itens.find(j => j.id === x)) || [];
                                                            props.stateChanged && props.stateChanged(v);
                                                        } else {
                                                            const v = props.selected?.concat(item.itens.map(k => k.id).filter(k => !props.selected?.includes(k))) || [];
                                                            props.stateChanged && props.stateChanged(v);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </MenuItem> : undefined,
                                    ...item.itens.map(c => {
                                        return <MenuItem disableRipple key={item.group + c.id}>
                                            <FormControlLabel
                                                sx={{ marginLeft: searchQuery === "" ? 2 : undefined }}
                                                label={c.label}
                                                control={
                                                    <Checkbox checked={props.selected?.includes(c.id)} onChange={() => {
                                                        toggleItem(c.id);
                                                    }} />
                                                }
                                            />
                                        </MenuItem>;
                                    })
                                ];
                            })
                        }
                    </MenuList>
                    : <Typography sx={{ "margin": "10px" }} align="center" >No results to show</Typography>}

            </Box>
            {props.enableClearButton &&
                <Button sx={{ marginTop: "5px" }} onClick={() => {
                    props.stateChanged && props.stateChanged([]);
                }}>Clear selection</Button>
            }

        </Box>
    );
}

function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}