import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import countriesEn from "i18n-iso-countries/langs/en.json";
import { useNotification } from "providers/Notification";
import { ListSelect } from "../../../../components/ListSelect";
import { ListInput } from "components/ListInput";
import clientDevices from "../../../../constant/clientDevices";
import contentTypes from "../../../../constant/contentTypes";

countries.registerLocale(countriesEn);


interface AddConditionProps {
    initialValue?: AlertCondition
    groups: { [key: string]: string }
    rules: { [key: string]: string }
    dataTypes: { [key: string]: string }
    applications: { [key: string]: string }
    activityFields: { [key: string]: string }
    onBackButtonClicked?: () => void
    //onExit is called when the user wants to go back without changing anything
    onCancel?: () => void
    onFilterAdded: (filter: AlertCondition, reset: () => void) => void
}

export interface AlertCondition {
    index?: number
    type: string
    values: string[]
}

export default function AddCondition(props: AddConditionProps) {
    const notification = useNotification();
    const [type, setType] = useState("");
    const [values, setValues] = useState([] as string[]);

    const [validationError, setValidationError] = useState(false);
    const [editing, setEditing] = useState(false);


    const getListFromType = (): { [key: string]: string } => {
        switch (type) {
        case "usergroup":
            return props.groups;
        case "apps":
            return props.applications;
        case "rules":
            return props.rules;
        case "datatypes_detected":
        case "datatypes_obfuscated":
            return props.dataTypes;
        case "client_ip_location":
            return Object.entries(countries.getAlpha2Codes()).reduce((data, [code]) => {
                data[code] = countries.getName(code, "en") || "";
                return data;
            }, {} as { [key: string]: string });
        case "client_device":
            return clientDevices.reduce((data, item) => {
                data[item] = item;
                return data;
            }, {} as { [key: string]: string });
        case "content_type":
            return contentTypes.sort().reduce((data, item) => {
                data[item] = item;
                return data;
            }, {} as { [key: string]: string });
        default:
            return {};
        }
    };

    const reset = () => {
        setType("");
        setValues([]);

        setEditing(false);
    };

    useEffect(() => {
        if (!props.initialValue) {
            reset();
            return;
        }

        setType(props.initialValue.type);
        setValues(props.initialValue.values || []);

        setEditing(true);
    }, [props.initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = () => {
        if (type === "") {
            notification.Display({
                type: "error",
                title: "Invalid parameters",
                message: "Filter type cannot be empty"
            });
            setValidationError(true);
            return;
        }

        props.onFilterAdded({
            index: props.initialValue?.index,
            type: type,
            values: values,
        }, reset);
    };

    return <Stack sx={{ paddingTop: "10px" }}>
        {!editing ? <>
            <Button
                id="add-button"
                variant="outlined"
                size="medium"
                fullWidth
                onClick={() => {
                    reset();
                    setEditing(true);
                }}
            >
                Add
            </Button>
        </> : <>
            <FormControl sx={{ marginTop: "20px" }} variant="outlined">
                <InputLabel id="filter-type-label">Type</InputLabel>
                <Select
                    labelId="filter-type-label"
                    id="filter-type-select"
                    name="type"
                    label="Type"
                    value={type}
                    onChange={x => {
                        setType(x.target.value);
                        setValues([]);
                    }}
                    error={validationError}
                >
                    {Object.entries(props.activityFields).map(([key, value]) => <MenuItem value={key}>{value}</MenuItem>)}
                </Select>
            </FormControl>

            {["client_ip", "data_values", "username"].includes(type) ?
                <ListInput id="text-list-input"
                    label={props.activityFields[type]}
                    placeholder="Press ENTER to confirm"
                    value={values}
                    delimiters={[]}
                    onDelete={x => setValues(values.filter(y => y !== x))}
                    onChange={x => setValues(x)}
                />
                :
                type && <ListSelect
                    id="values-select"
                    filter
                    label={props.activityFields[type]}
                    valueList={getListFromType()}
                    value={values}
                    onAdd={x => setValues(values.concat([x]))}
                    onDelete={x => setValues(values.filter(y => y !== x))}
                />
            }

            <Box display="flex" flexGrow={1} alignItems={"end"} marginBottom="15px" marginTop="20px">
                <Button
                    id="cancel-button"
                    variant="text"
                    size="medium"
                    fullWidth
                    onClick={() => {
                        reset();
                        props.onCancel && props.onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    id="save-button"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onClick={save}
                >
                    Save
                </Button>
            </Box>
        </>}

    </Stack>;
}