import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Card,
    Container,
    FormControl,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { LoginError, useLogin } from "providers/Login";
import abstractShape from "./abstract-shape.svg";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Login() {
    const loginCtx = useLogin();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [samlAvailable, setSamlAvailable] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            if (loginCtx.LoggedIn) {
                navigate("/");
            }

            if(!loginCtx.ConnectionOk) {
                setError("Could not connect to the server");
            } else if (!loginCtx.DatabaseOk) {
                setError("Could not connect to the database");
            } else {
                try {
                    const response = await axios.get("/api/auth/options");
                    if (response.data.adminUserExists === false) {
                        navigate("/register");
                        return;
                    }
                    if(response.data.samlAvailable === true) {
                        setSamlAvailable(true);
                    }
                } catch(e : any) {
                    console.error(e);
                }
            }
        };
        init();
    }, [loginCtx]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        setInProgress(true);

        try {
            await loginCtx.Login(username, password);
            navigate("/");
        } catch (e) {
            if (e instanceof LoginError) {
                setError(e.message);
            } else {
                setError("Log-in error");
            }
            setInProgress(false);
            return;
        }
    };

    const samlLogin = () => {
        window.location.href = "/saml_login";
    };

    return (
        <Container id="login-page" sx={{
            height: "90vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${abstractShape})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        }}>
            <Typography sx={{ marginBottom: "30px", fontSize: "40px", lineHeight: "50px" }} variant="h1">nullafi</Typography>

            <Box sx={{ width: "400px" }}>
                <Alert
                    severity={"error"}
                    sx={{
                        marginBottom: "10px",
                        visibility: error === "" ? "hidden" : "visible",
                    }}
                >
                    {error}
                </Alert>
                <Card>
                    <Stack>
                        {
                            samlAvailable && <>
                                <Button variant="contained" onClick={samlLogin} sx={{ marginTop: "10px", marginBottom: "30px" }} size="large">Log in with single sign-on</Button>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}    
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                    Use local credentials instead
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack>
                                            <FormControl>
                                                <TextField
                                                    id="username-input"
                                                    name="username"
                                                    label="Username"
                                                    variant="outlined"
                                                    value={username}
                                                    error={error !== ""}
                                                    onChange={(x) => setUsername(x.target.value)}
                                                />
                                                <TextField
                                                    sx={{ marginTop: "30px" }}
                                                    id="password-input"
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    type="password"
                                                    error={error !== ""}
                                                    value={password}
                                                    onChange={(x) => setPassword(x.target.value)}
                                                    onKeyUp={(x) => (x.key === "Enter" ? onSubmit() : undefined)}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack  sx={{ marginTop: "40px", marginBottom: "20px" }}>
                                            <LoadingButton id="submit-button" loading={inProgress} variant="outlined" onClick={onSubmit}>Submit</LoadingButton>                            
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        }
                        {!samlAvailable &&
                            <>
                                <Stack>
                                    <FormControl>
                                        <TextField
                                            id="username-input"
                                            name="username"
                                            label="Username"
                                            variant="outlined"
                                            value={username}
                                            error={error !== ""}
                                            onChange={(x) => setUsername(x.target.value)}
                                        />
                                        <TextField
                                            sx={{ marginTop: "30px" }}
                                            id="password-input"
                                            name="password"
                                            label="Password"
                                            variant="outlined"
                                            type="password"
                                            error={error !== ""}
                                            value={password}
                                            onChange={(x) => setPassword(x.target.value)}
                                            onKeyUp={(x) => (x.key === "Enter" ? onSubmit() : undefined)}
                                        />
                                    </FormControl>
                                </Stack>                                
                                <Stack  sx={{ marginTop: "40px" }}>
                                    <LoadingButton id="submit-button" loading={inProgress} variant="contained" onClick={onSubmit}>Submit</LoadingButton>                            
                                </Stack>
                            </>
                        }
                    </Stack>
                </Card>
                <Typography
                    sx={{ marginTop: "50px" }}
                    variant="body2"
                    color="text.secondary"
                    align="center"
                >
                    {process.env.REACT_APP_VERSION}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                >
                    Copyright © Nullafi 2024
                </Typography>
            </Box>
        </Container>
    );
}

export default Login;
